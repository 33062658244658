import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MarkdownComponent } from 'ngx-markdown';
import { AlertModalComponent } from './alert-modal.component';
import { CoreModule } from '../../core/core.module';
import { ModalModule } from '../modal/modal.module';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        MarkdownComponent,
        CoreModule,
        ModalModule
    ],
    exports: [
        AlertModalComponent
    ],
    declarations: [
        AlertModalComponent
    ]
})
export class AlertModalModule {
}
