export enum CommunicationZoneFormEnum {
    default = 'default',
    order = 'order',
    documentCH = 'documentCH',
    documentLS = 'documentLS',
    editProfile = 'editProfile',
    companyChangeRequest = 'companyChangeRequest',
    cooperationBonusRequest = 'cooperationBonusRequest',
    documentESR = 'documentESR',
    documentETR = 'documentETR',
    meaChatContact = 'meaChatContact',
    invoicesContact = 'invoicesContact',
    returns = 'returns',
}

