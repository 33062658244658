// permission.service.ts

import { effect, inject, Injectable, Signal, signal, untracked } from '@angular/core';
import { AuthStorageKeyEnum } from '../enums/authStorageKey.enum';
import { HiddenUserAccessRightsEnum, UserAccessRightsEnum } from '../enums/user-administration.enum';
import { ProfileSettingsVar } from '../store/locals/profileSettings.var';
import { ProfileSettingInterface } from '../interfaces/settings.interface';

type AccessRights = Record<UserAccessRightsEnum|HiddenUserAccessRightsEnum, boolean>;

@Injectable({
    providedIn: 'root',
})
export class PermissionService {
    private profileSettingsVar = inject(ProfileSettingsVar);

    private _hasUserAccessRights = signal<Partial<AccessRights>>({});
    public hasUserAccessRights: Signal<Partial<AccessRights>> = this._hasUserAccessRights.asReadonly();

    profileSettings: Signal<ProfileSettingInterface> = this.profileSettingsVar.profileSettings;

    constructor() {
        effect(
            () => {
                if (this.profileSettings()?.user?.userAccessRights) {
                    const userAccessRights = this.profileSettings().user.userAccessRights;
                    const allGroupsParsed = userAccessRights.map((g) => g.match(new RegExp('(.*)_(.*)_(.*)')));
                    const activePharmacy = localStorage.getItem(AuthStorageKeyEnum.activePharmacy);
                    if (activePharmacy) {
                        const newRights: AccessRights = {
                            [UserAccessRightsEnum.INVOICES]: false,
                            [UserAccessRightsEnum.STATISTICS]: false,
                            [UserAccessRightsEnum.ARCHIVE]: false,
                            [UserAccessRightsEnum.USER_ADMINISTRATION]: false,
                            [UserAccessRightsEnum.SANAVENDI]: false,
                            [UserAccessRightsEnum.PHARMACY_OWNER]: false,
                            [UserAccessRightsEnum.PHARMACY_MEMBER]: false,
                            [HiddenUserAccessRightsEnum.REPRESENTATIVE]: false,
                        };
                        for (const group of allGroupsParsed) {
                            if (Array.isArray(group) && group.length === 4 && group[2] === 's-connect' && group[1] === activePharmacy) {
                                switch (group[3]) {
                                    case UserAccessRightsEnum.PHARMACY_OWNER:
                                        newRights[UserAccessRightsEnum.INVOICES] = true;
                                        newRights[UserAccessRightsEnum.STATISTICS] = true;
                                        newRights[UserAccessRightsEnum.ARCHIVE] = true;
                                        newRights[UserAccessRightsEnum.USER_ADMINISTRATION] = true;
                                        newRights[UserAccessRightsEnum.SANAVENDI] = true;
                                        break;
                                    case UserAccessRightsEnum.INVOICES:
                                        newRights[UserAccessRightsEnum.INVOICES] = true;
                                        break;
                                    case UserAccessRightsEnum.STATISTICS:
                                        newRights[UserAccessRightsEnum.STATISTICS] = true;
                                        break;
                                    case UserAccessRightsEnum.ARCHIVE:
                                        newRights[UserAccessRightsEnum.ARCHIVE] = true;
                                        break;
                                    case UserAccessRightsEnum.SANAVENDI:
                                        newRights[UserAccessRightsEnum.SANAVENDI] = true;
                                        break;
                                    case HiddenUserAccessRightsEnum.REPRESENTATIVE:
                                        newRights[HiddenUserAccessRightsEnum.REPRESENTATIVE] = true;
                                        break;
                                }
                            }
                        }
                        untracked(() => {
                            this._hasUserAccessRights.set(newRights);
                        });
                    } else {
                        throw new Error('No active pharmacy found in local storage.');
                    }
                }
            },
            { allowSignalWrites: true }
        );
    }
}
