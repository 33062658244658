<ng-container *ngTemplateOutlet="additionalTopTemplateRef"></ng-container>
<ion-card mode="md" class="clickable-animated ion-no-margin"
          [class.dynamic-height]="hasDynamicHeight"
          [style.--theme-color]="themeColor?.mainColor"
          [class.disable-shadow]="disableShadow"
          [class.list-layout]="listLayout">
    <ion-img *ngIf="image?.url" src="{{image.url}}" [class.card-image]="!hasDynamicHeight"
             [class.image-is-logo]="image.imageType === ImageTypes.LOGO"
    />

    <ion-card-header class="flex-direction-column">
        <ng-container>
            <ng-container *ngIf="subTitleTemplateRef">
                <ion-card-subtitle class="card-subtitle">
                    <ng-container *ngTemplateOutlet="subTitleTemplateRef"></ng-container>
                </ion-card-subtitle>
            </ng-container>
            <ng-container *ngIf="!subTitleTemplateRef">
                <ion-card-subtitle class="card-subtitle">{{subTitle}}</ion-card-subtitle>
            </ng-container>
        </ng-container>
        <ng-container>
            <ng-container *ngIf="titleTemplateRef">
                <ion-card-title class="card-title">
                    <ng-container *ngTemplateOutlet="titleTemplateRef"></ng-container>
                </ion-card-title>
            </ng-container>
            <ng-container *ngIf="!titleTemplateRef">
                <ion-card-title class="card-title">{{title}}</ion-card-title>
            </ng-container>
        </ng-container>
    </ion-card-header>

    <ion-card-content>
        <ng-container *ngIf="contentTemplateRef">
            <ng-container *ngTemplateOutlet="contentTemplateRef"></ng-container>
        </ng-container>
        <ng-container *ngIf="!contentTemplateRef">
            <p class="card-content">{{content}}</p>
        </ng-container>
    </ion-card-content>

    <div [class.footer-divider]="useDivider"></div>

    <div class="card-footer ion-padding-start" [class.fade-out]="useFooterFadeOut">
        <ng-container *ngTemplateOutlet="footerTemplateRef"></ng-container>
        <ion-button class="footer-button">
            <ion-icon name="arrow-forward"></ion-icon>
        </ion-button>
    </div>
</ion-card>
