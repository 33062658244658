import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-table-old-no-results',
    templateUrl: './table-no-results.component.html',
    styleUrls: ['./table-no-results.component.scss'],
})
export class TableNoResultsComponent {
    @Input() isLoading = { data: true, count: true };
    @Input() count = 0;
}
