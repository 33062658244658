import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { from, map } from 'rxjs';
import { ApiService } from '../../../services/api.service';

export const RequestArchiveMutation = gql`
    mutation RequestRequestArchiveZip($type: ArchiveType!, $fromYearMonth: Int!, $toYearMonth: Int!) {
        RequestArchiveZip(type: $type, fromYearMonth: $fromYearMonth, toYearMonth: $toYearMonth){
            status
            message
        }
    }
`;


export const AllArchiveMutations = [
    RequestArchiveMutation
];

@Injectable()
export class ArchiveMutations {
    constructor(
        private apollo: Apollo,
        private apiService: ApiService,
    ) {}

    requestArchive(type: string, fromYearMonth: number, toYearMonth: number) {
        this.apollo.mutate({
            mutation: RequestArchiveMutation,
            variables: {
                type,
                fromYearMonth,
                toYearMonth
            }
        }).subscribe({
            next: result => {
                from([result])
                    .pipe(map(d => d?.data && d?.data['RequestArchiveZip'] && d?.data['RequestArchiveZip']['status']))
                    .subscribe((status: string) => {
                        if (!status || status === 'ERROR' || !!result['errors']) {
                            void this.apiService.presentErrorToast(
                                result['errors'],
                                'Die Anfrage für das Archiv konnte leider nicht übermittelt werden.'
                            );
                        } else {
                        }
                    });
            },
            error: error => {
                void this.apiService.presentErrorToast(error, 'Die Anfrage für das Archiv konnte leider nicht übermittelt werden.');
            }
        });
    }
}
