/* tslint:disable:max-line-length */
import { getDateFromConfig } from './date-range.config';
import { DateRangeOptionCodes } from '../enums/date-range.enum';
import { formatDateTimeToDate, formatDateTimeToMoment, yesterday } from '../formatting/date.formatting';
import { maxExtensionDays } from '../services/order.service';
const yd = yesterday();

export const TOOLTIPS = {
    not_yet_fully_initialized: 'Bitte beachten Sie, dass sich Ihre Daten noch im Aufbau befinden.',

    invalid_form: 'Bitte überprüfen Sie Ihre Eingaben. Eine Ihrer Angaben ist fehlerhaft.',
    invalid_amount: 'Bitte tragen Sie eine gültige Menge ein (maximal 99 Stück).',
    invalid_date_range: 'Bitte geben Sie eine gültige Zeitangabe ein.',
    invalid_disposition_request: 'Für Ihre Anfrage benötigen wir mindestens "Ansprechpartner", "Menge" und "Produktname" bzw. "PZN", alternativ kann über "weitere Informationen" jeglicher Freitext übermittelt werden.',

    seminar_is_locked: 'Das Seminar ist zur Zeit gesperrt. Sollte dies für längere Zeit auftreten, wenden Sie sich bitte an Ihren Sanacorp Ansprechpartner.',
    seminar_cancellation_locked: 'Das Seminar findet bereits statt oder liegt in der Vergangenheit. Es dürfen keine Stornierungen mehr stattfinden.',
    seminar_registration_locked: 'Das Seminar findet bereits statt oder liegt in der Vergangenheit. Es dürfen keine Anmeldungen mehr stattfinden.',
    seminar_update_locked: 'Das Seminar findet bereits statt oder liegt in der Vergangenheit. Es dürfen keine Änderungen mehr vorgenommen werden.',
    seminar_timeless_filter: 'Zeigt zusätzlich Seminare an, welche Zeitunabhängig stattfinden.',

    communicationZone_invalid: 'Bitte geben Sie Ihre Frage, den Ansprechpartner und eine Beschreibung an.',
    communicationZone_invalid_order: 'Bitte geben Sie den Ansprechpartner und eine Beschreibung an.',
    communicationZone_solved: 'gelöst',
    communicationZone_question_title_prefilled: 'Vorausgefüllt, damit wir Ihnen schnell helfen können.',

    // Meamind Page
    meamind_question_invalid: 'Bitte geben Sie Ihre Frage ein und fügen Sie mindestens ein Tag hinzu.',
    meamind_comment_invalid: 'Bitte geben Sie Ihr Kommentar ein.',
    meamind_solution_invalid: 'Bitte geben Sie Ihre Lösung ein.',
    meamind_question_publish: 'Die Frage wird anonym an Ihre Kollegen gestellt.',
    meamind_question_title: 'Teilen Sie uns Ihre Frage mit.',
    meamind_question_description: 'Bitte fügen Sie eine Beschreibung zu Ihrer Frage hinzu.',
    meamind_question_tags: 'Hier weitere Schlagwörter ergänzen',
    meamind_question_answer_description: 'Bitte tragen Sie Ihre Lösung ein.',
    meamind_question_comment_description: 'Bitte tragen Sie Ihren Kommentar ein.',
    meamind_mark_as_solution_not_allowed: 'Sie können nur für Ihre eigenen Fragen die Antwort als Lösung markieren.',
    meamind_unmark_as_solution_not_allowed: 'Sie können nur für Ihre eigenen Fragen die Markierung einer Antwort als Lösung entfernen.',
    meamind_delete_question_not_allowed: 'Sie können nur Ihre eigenen Fragen löschen.',
    meamind_delete_answer_not_allowed: 'Sie können nur Ihre eigenen Antworten löschen.',
    meamind_delete_comment_not_allowed: 'Sie können nur Ihre eigenen Kommentare löschen.',
    meamind_edit_question_not_allowed: 'Sie können nur Ihre eigenen Fragen bearbeiten.',
    meamind_edit_answer_not_allowed: 'Sie können nur Ihre eigenen Antworten bearbeiten.',
    meamind_edit_comment_not_allowed: 'Sie können nur Ihre eigenen Kommentare bearbeiten.',

    // mea section
    mea_shop_order_table: 'Gelistet werden alle Bestellungen der letzten 30 Tage, die zuletzt durch Sanacorp aktualisiert wurden.',
    mea_chat_goTo_notification_settings: 'Hier können Sie die Benachrichtigungen für den mea Chat einstellen.',
    mea_downloads_hint: 'Hinweise zu den Downloads',
    mea_data_update_info: 'Die hier angezeigten Daten zeigen den Stand vom Vortag. Der Vergleich der Daten bezieht sich auf den letzten Monat.',

    orders_widget_information: 'Gelistet werden alle Aufträge, die zuletzt durch Sanacorp aktualisiert wurden.',
    order_acknowledge_locked: 'Nur für Direktlieferungen darf deren Ankunft bestätigt werden',
    order_acknowledge_button: 'Diesen Dispo-Auftrag als erledigt markieren. Ware ging in der Apotheke ein.',
    order_copy_locked: 'Das Nachbestellen einer Bestellung ist nur für Dispositionsaufträge gestattet',
    order_copy_button: 'Diesen Artikel mit neuer Menge bestellen.',
    order_cancellation_locked: 'Es dürfen nur unbearbeitete Aufträge storniert werden.',
    order_cancellation_producer: 'Um einzelne Positionen oder einen Auftrag zu stornieren, melden Sie diese bitte direkt beim Hersteller an.',
    order_cancellation_dispo: 'Wenden Sie sich bitte direkt an den Kundenservice.',
    order_cancellation_button: 'Diesen Auftrag stoppen und rückgängig machen.',
    order_cancellation_requested:  'Eine Stornierung wurde beauftragt.',
    order_extension_requested:  'Eine Verlängerung wurde beauftragt.',
    order_extension_locked: 'Eine Verlängerung ist nur für offene Nachlieferungen möglich.',
    order_extension_date_range_locked: `Eine Verlängerung ist nur bis maximal ${maxExtensionDays} Tage möglich.`,
    order_extension_button: 'Das Ablaufdatum des Nachlieferauftrags verändern.',
    order_reorder_requested:  'Eine Nachlieferung wurde beauftragt.',
    order_status_pending: 'Die Bestellung ist bei Sanacorp eingegangen und in Bearbeitung.',
    order_status_enroute: 'Der Artikel wird der Apotheke in Kürze zugestellt.',
    order_status_cancelled: 'Die Bestellung wurde storniert.',
    order_status_delegated: 'Die Bestellabwicklung erfolgt vollständig durch den Lieferanten.',
    order_status_completed: 'Der Artikel wurde der Apotheke geliefert.',
    order_status_partially_completed: 'Eine Teilmenge wurde an die Apotheke geliefert.',
    order_support_button: 'Eine Anmerkung zum markierten Auftrag direkt an den Kundenservice senden.',
    order_type_additional: 'Nachlieferung',
    order_type_disposition: 'Dispoauftrag',
    order_type_ue: 'Überweiserauftrag',
    order_type_n_plus: 'Bevorratungsauftrag',
    order_note: 'interne Notiz',
    order_note_button: 'Eine Anmerkung zur Apotheken-internen Information abspeichern.',
    order_price: 'voraussichtlicher Preis für Menge 1. Bei Lieferung und/oder Berechnung direkt vom Lieferanten erfolgt keine Preisinformation.',
    order_extraCosts: 'voraussichtliche Zusatzkosten die der Lieferant veranschlagt für Porto, Bearbeitungsaufwände, Mindermengenaufschlag etc. Bei Lieferung und/oder Berechnung direkt durch den Lieferanten erfolgt keine Information zu den Zusatzkosten.',
    order_estimatedDelivery: 'Die Sanacorp benötigt für die Beschaffung des Artikels im Durchschnitt die angegeben Zeit. Insbesondere bei individueller Bestellung und Beschaffung aus dem Ausland können dies Aufwände stark abweichen. Angabe ohne Gewähr.',
    order_estimatedDeliveryDate: [
        {normal: 'Angabe ohne Gewähr. Es handelt sich um die Information, wann der Hersteller im Falle von Lieferengpässen frühestens wieder ausliefern kann. Die Sanacorp erfragt für Sie '},
        {underline: 'beim Hersteller'},
        {normal: ' regelmäßig die '},
        {underline: 'Lieferbarkeit der Defekt-Artikel'},
        {normal: ' und stellt diese Information unverbindlich zur Verfügung.'},
    ],

    delivery_receipt_subtype_chartier: 'Dokumentation für Tierarzneimittel',
    delivery_receipt_subtype_charblut: 'Dokumentation für Blutzubereitungen, Sera etc.',
    delivery_receipt_subtype_chardoku: 'Dokumentation für Sonderkunden wie Krankenhausapotheken, Firmen, Großhandel',
    delivery_receipt_subtype_charsecu: 'Dokumentation für SecurPharm pflichtige Arzneimittel',
    delivery_receipt_subtype_e_tr: 'E_TR',
    delivery_receipt_subtype_e_sr: 'E_SR',
    delivery_receipt_show: 'Beleg anzeigen',
    delivery_receipt_download: 'Beleg als PDF herunterladen',
    delivery_receipt_print: 'Beleg drucken',
    delivery_not_found: 'Lieferschein konnte nicht abgerufen werden. In Sanacorp Connect sind nur die Lieferscheine der letzten 2 Jahre verfügbar.',
    delivery_support_request: 'Kontaktieren Sie den Kundenservice zu diesem Lieferbeleg.',

    safety_datasheet_not_available: 'Zu der von Ihnen gesuchten PZN steht kein Sicherheitsdatenblatt zur Verfügung.',
    safety_datasheet_error_11: 'Die PZN ist uns nicht bekannt. Bitte überprüfen Sie Ihre Eingabe.',

    archive_error: 'Bei der Archivabfrage ist ein Fehler aufgetreten.',
    archive_empty: 'Das Archiv enthält keine Daten.',

    pdf_viewer_download: 'Datei herunterladen',
    pdf_viewer_not_printable: 'Dieser Dateityp ist nur als Download verfügbar',
    pdf_viewer_print: 'Datei drucken',

    print_list_enabled: 'Liste drucken',
    print_list_disabled: 'Die PDF Generierung kann nur einmal alle 30 Sekunden ausgeführt werden.',
    print_list_loading: 'Aufbereitung der PDF läuft',

    document_cancelled: 'Die Lieferung wurde storniert.',

    invoice_not_found: 'Rechnung konnte nicht abgerufen werden.',
    invoice_type_monthly: 'Monatliche Rechnung',
    invoice_type_partial: 'Teilrechnung',
    invoice_support_request: 'Kontaktieren Sie den Kundenservice zu dieser Rechnung.',

    return_status_goods_returned: 'Leider können wir die Retourenware nicht annehmen und liefern sie Ihnen zurück. Es ergibt sich kein Gutschriftswert.',
    return_status_credited: 'Die Position wurde Ihnen bereits gutgeschrieben.',
    return_status_processing: 'Die Position ist bei uns vorgemerkt und wartet auf Gutschrift zum Monatsende.',
    return_status_without_calculation: 'Die Position ist bei uns vermerkt, aus dem Rückgabegrund ergibt sich jedoch kein Gutschriftswert.',
    return_status_return_missing: 'Die Ware fehlte in der Retouren-Wanne. Sie kann nicht gutgeschrieben werden.',
    return_status_cancelled: 'Die Position wurde von der Apotheke zur Retoure übermittelt, jedoch im Nachgang durch einen Apothekenmitarbeiter storniert. Sie wird von uns nicht bearbeitet. Es ergibt sich kein Gutschriftswert.',
    return_status_destroyed: 'Die Position wird vernichtet. Es ergibt sich kein Gutschriftswert.',

    return_type_recall: 'Position wurde als Chargen-Rückruf bewertet.',
    return_type_stock_defect: 'Position wurde durch die Sanacorp beim Kommissionier-Vorgang als fehlend bewertet.',
    return_type_withdrawal: 'Position wurde als Rücknahme bewertet, da kein Lieferbeleg angegeben oder außerhalb der Retouren-Frist.',
    return_type_return: 'Position wurde als Retoure mit Lieferscheinbezug bewertet.',

    password_information: {
        title:  'Ihr Passwort benötigt',
        list: [
            'mindestens 10 Zeichen',
            'einen Kleinbuchstaben',
            'einen Großbuchstaben',
            'eine Zahl',
            'ein Sonderzeichen'
        ]
    },

    login_button_disabled: 'Bitte überprüfen Sie Ihre E-Mail-Adresse und/oder Passwort.',

    statistics_orders: 'Anzahl Bestellungen Sonderaufträge',
    statistics_sales: 'Umsätze von Aufträgen aller Auftragsarten. Im Umsatz sind Gutschriften NICHT berücksichtigt. Die Linie stellt den Vorjahreswert dar.',
    statistics_packages: 'Packungsanzahl von Aufträgen aller Auftragsarten. In der Anzahl des aktuellen Monats sind Gutschriften nicht berücksichtigt. Die Linie stellt den Vorjahreswert dar.',
    statistics_sales_widget: 'Umsatz aller Aufträge exklusive Hochpreiser. Im Umsatz sind Gutschriften nicht berücksichtigt.',
    statistics_coop: 'Umsatz mit Kooperationsartikel inkl. Differenzanzeige zum vereinbarten Zielumsatz. Die Werte sind auf einzelne Monate heruntergerechnet.',
    statistics_coop_not_reached: 'Sie haben Ihren vereinbarten Jahresumsatz mit Kooperationsartikel noch nicht erreicht. Bleiben Sie dran. Bei Erreichung wird der Kooperationsbonus am Jahresende ausbezahlt.',
    statistics_coop_reached: 'Sie haben Ihren vereinbarten Jahresumsatz mit Kooperationsartikel erreicht. Der Kooperationsbonus wird am Jahresende ausbezahlt.',
    statistics_coop_discount: 'Dieser Rabatt ist Ihnen jetzt schon sicher, sofern Sie mit Ihrem Kooperationsumsatz das Jahresziel erreichen.',
    statistics_returns_widget: 'Sie können Ware im angezeigten Wert retournieren, ohne zusätzliche Bearbeitungsgebühr. Wird kein Wert angezeigt, so ist der freie Anteil aufgebraucht.\nAchtung: Der Wert ist dynamisch, da er von Ihren Bestellungen und bereits retournierter Ware abhängt.',
    statistics_package_values: 'Durchschnittlicher Packungswert RX-Artikel.',
    statistics_beforeLastMonth: 'Vorletzer Monat: ' + formatDateTimeToMoment(getDateFromConfig(DateRangeOptionCodes.beforeLastMonth, true)).format('MMM YYYY'),
    statistics_lastMonth: 'letzter Monat: ' + formatDateTimeToMoment(getDateFromConfig(DateRangeOptionCodes.lastMonth, true)).format('MMM YYYY'),
    statistics_thisMonth: 'lfd. Monat: ' +
        formatDateTimeToMoment(getDateFromConfig(DateRangeOptionCodes.thisMonth, true)).format('DD.MM') +
        ' bis ' +
        formatDateTimeToDate(yd),
    statistics_lastYear: 'letztes Jahr: ' +
        formatDateTimeToDate(getDateFromConfig(DateRangeOptionCodes.lastYear, true))+
        ' bis ' +
        formatDateTimeToDate(formatDateTimeToMoment(yd).subtract(1, 'year')),
    statistics_thisYear: 'lfd. Jahr: ' +
        formatDateTimeToDate(getDateFromConfig(DateRangeOptionCodes.thisYear, true)) +
        ' bis ' +
        formatDateTimeToDate(yd),
    statistics_no_monthlyTargetSales: 'Zur Anzeige der Differenz ergänzen Sie Ihren monatlicher Zielumsatz in Einstellungen/Statistiken.',
    statistics_view_mode_chart: 'Grafik',
    statistics_view_mode_table: 'Liste',
    statistics_download: 'Die Generierung der PDF kann einige Minuten dauern.',
    statistics_download_producer: 'Bitte wählen Sie einen Hersteller aus.',

    // Business Figures / Assortments
    business_figure_subscription_info: 'Wenn Sie die Abo-Funktion für regelmäßige Statistiken nutzen, erhalten Sie am Morgen des 10. eines Monats die Statistiken für den Vormonat automatisch. Wir benachrichtigen Sie auch innerhalb von Sanacorp Connect, sobald Ihre Auswertung zur Verfügung steht. (Vorausgesetzt, Ihre Benachrichtigungen sind aktiviert) Bitte beachten Sie, dass Ihnen der Download ab Erstellung nur 14 Tage zur Verfügung steht. Eine erneute manuelle Abfrage ist jederzeit möglich.',

    settings_statistic_monthlyTargetSales: 'Monatlicher Zielumsatz ohne Hochpreiser (ohne Kommastellen)',
    settings_meamind_notification: 'Sie erhalten keine Benachrichtigungen für selbst gestellte Fragen.',
    settings_for_pharmacy: 'Ihre Einstellungen gelten für die gesamte Apotheke.',
    settings_for_user: 'Die Einstellungen gelten nur für Sie und nicht für die gesamte Apotheke.',
    setting_is_disabled: 'Diese Benachrichtigung lässt sich nicht deaktivieren, da sie wichtige Informationen enthält.',

    // Disposition Request Page
    dispo_request_qty: 'gewünschte Bestellmenge',
    dispo_request_is_narcotic: 'Ist das Produkt ein Betäubungsmittel?',
    dispo_request_package_size: 'z. B. 100 ml, 200 St, ...',
    dispo_request_dosage_form: 'z. B. TAB, DRG, Dilut, Saft, ...',
    dispo_request_producer: 'Wer stellt das Produkt her?',
    dispo_request_additional_information: 'Weitere Informationen zum Produkt oder Bestellablauf.',

    // Return Goods Request Form
    return_request_contact: 'Wen dürfen wir bei Rückfragen kontaktieren?',
    return_request_qty: 'Retourenmenge',
    return_request_expirationDate: 'z. B. 06.2021',
    return_request_reason: 'z. B. beschädigt, Bestellfehler, ...',
    return_request_deliveryNr: 'z. B. 11-1234567',
    return_request_deliveryDate: 'z.B. 01.01.2022',
    return_request_price: 'Diesen Wert schreiben wir Ihnen je Einzelpackung gut.',
    return_request_online_btm: 'Nehmen Sie am Online-Belegverfahren der BfArM (Bundesopiumstelle) teil?',
    return_request_max: 'Sie können maximal 4 Retouren gleichzeitig anmelden.',

    // Communication Zone Page
    communication_zone_title: 'Teilen Sie uns Ihre Frage rund um Aufträge, Lieferung, Kooperation, digitale Produkte etc. mit',
    communication_zone_contact_person: 'Wen dürfen wir bei Rückfragen kontaktieren?',
    communication_zone_description: 'Bitte fügen Sie eine Beschreibung zu Ihrer Frage hinzu.',

    // Appointment Page
    appointment_title: 'Titel des Termins',
    appointment_description: 'Termin, Agenda oder weitere Infos',
    appointment_datetime: 'Von wann bis wann findet der Termin statt',
    appointment_reminder: 'Wollen Sie an den Termin erinnert werden?',

    // Note Page
    note: 'Schreiben Sie eine interne Notiz',

    // News Page (also Mea Info)
    news_view_mode_list: 'Liste',
    news_view_mode_masonry: 'Kacheln',

    // Offer Page
    offers_error_checkout_cart: 'Dieser Artikel ist veraltet oder die Menge größer als 9999. Bitte entfernen Sie den Artikel aus der Bestellung oder korrigieren die Menge.',
    offers_aep: 'Zum Einstellungszeitpunkt bekannter AEP. Kann zum Lieferzeitpunkt abweichen.',
    offers_inactive: 'Dieser Artikel kann derzeit nicht mehr (vor-) bestellt werden.',
    offers_can_not_buy: 'Bestellen Sie über Ihr Warenwirtschaftssystem bei Ihrer Sanacorp Niederlassung.',
    offers_copy_pzn_to_clipboard: 'PZN kopieren',

    // User Administration
    user_administration_create_new_user_button: 'Leider haben Sie nicht die erforderliche Berechtigung, um einen neuen Nutzer anzulegen. Nur der Inhaber kann weitere Nutzer anlegen. Vielen Dank für Ihr Verständnis.',
    user_administration_edit_current_user_forbidden: 'Der eigene Nutzer kann nicht bearbeitet werden.',
    user_administration_edit_owner_forbidden: 'Der Apotheken-Inhaber kann nicht bearbeitet werden.',
    ua_no_access_invoice: 'Für diese Ansicht benötigen Sie eine entsprechende Berechtigung.',
    ua_product_badge_sconnect: 'Sanacorp Connect',
    ua_product_badge_chat: 'mea Chat',
    ua_product_badge_shop: 'mea Shop',
    ua_product_badge_sacademy: 'Sanacorp Akademie',
    ua_product_badge_sanavendi: 'SanaVendi',
    ua_access_rights_badge: 'Der Nutzer hat das Zugriffsrecht auf diese Anwendung.',
    ua_access_rights_badge_no_access: 'Der Nutzer hat kein Zugriffsrecht auf diese Anwendung.',
    ua_access_rights_checked: [
        {normal: 'Der Nutzer hat das Zugriffsrecht auf diese Inhalte.'}
    ],
    ua_access_rights_unchecked: [
        {normal: 'Der Nutzer hat '},
        {underline: 'kein'},
        {normal: ' Zugriffsrecht auf diese Inhalte.'},
    ],
    ua_access_rights_checked_aclEInvoice: [
        {normal: 'Der Nutzer hat das Zugriffsrecht auf diese Inhalte.'},
        {linebreak: 'Wenn die Berechtigung gesetzt ist, kann der Nutzer die Rechnungen in der Warenlogistik ansehen, drucken und herunterladen.'},
    ],
    ua_access_rights_unchecked_aclEInvoice: [
        {normal: 'Der Nutzer hat '},
        {underline: 'kein'},
        {normal: ' Zugriffsrecht auf diese Inhalte.'},
        {linebreak: 'Wenn die Berechtigung gesetzt ist, kann der Nutzer die Rechnungen in der Warenlogistik ansehen, drucken und herunterladen.'},
    ],
    ua_access_rights_checked_aclArchive: [
        {normal: 'Der Nutzer hat das Zugriffsrecht auf diese Inhalte.'},
        {linebreak: 'Wenn die Berechtigung gesetzt ist, kann der Nutzer die mehrere Lieferscheine, Chargenbelege und Rechnungen auf einmal herunterladen.'},
    ],
    ua_access_rights_unchecked_aclArchive: [
        {normal: 'Der Nutzer hat '},
        {underline: 'kein'},
        {normal: ' Zugriffsrecht auf diese Inhalte.'},
        {linebreak: 'Wenn die Berechtigung gesetzt ist, kann der Nutzer die mehrere Lieferscheine, Chargenbelege und Rechnungen auf einmal herunterladen.'},
    ],
    ua_access_rights_checked_aclStatistics: [
        {normal: 'Der Nutzer hat das Zugriffsrecht auf diese Inhalte.'},
        {linebreak: 'Wenn die Berechtigung gesetzt ist, kann der Nutzer die Statistiken in dem Reiter Statistiken ansehen.'},
    ],
    ua_access_rights_unchecked_aclStatistics: [
        {normal: 'Der Nutzer hat '},
        {underline: 'kein'},
        {normal: ' Zugriffsrecht auf diese Inhalte.'},
        {linebreak: 'Wenn die Berechtigung gesetzt ist, kann der Nutzer die Statistiken in dem Reiter Statistiken ansehen.'},
    ],
    ua_access_rights_checked_aclUserAdministration: [
        {normal: 'Der Nutzer hat das Zugriffsrecht auf diese Inhalte.'},
        {linebreak: 'Wenn die Berechtigung gesetzt ist, kann der Nutzer die Nutzerveraltung öffnen und Nutzer anlegen und löschen.'},
        {linebreak: 'Ausschließlich Inhaber haben das Recht Nutzer zu verwalten.'},
    ],
    ua_access_rights_unchecked_aclUserAdministration: [
        {normal: 'Der Nutzer hat '},
        {underline: 'kein'},
        {normal: ' Zugriffsrecht auf diese Inhalte.'},
        {linebreak: 'Wenn die Berechtigung gesetzt ist, kann der Nutzer die Nutzerveraltung öffnen und Nutzer anlegen und löschen.'},
        {linebreak: 'Ausschließlich Inhaber haben das Recht Nutzer zu verwalten.'},
    ],
    ua_access_rights_userAdministration: 'Ausschließlich Inhaber haben das Recht Nutzer zu verwalten.',
    ua_type_badge_owner: 'Inhaber der Apotheke. Zugriffsrechte werden über die Sanacorp vergeben.',
    ua_type_badge_user: 'Nebennutzer. Daten und Zugriffsrechte werden durch den Inhaber gepflegt.',
    ua_sacademy_deactivated_hint: 'Ein Nutzer darf für maximal eine Apotheke das Recht "Sanacorp Akademie" bekommen.',

    // general terms and conditions
    terms_accept_button_deactivated: 'Bitte warten Sie 15 Minuten und versuchen es erneut.',

    // toolbar
    toolbar_mea_chat_offline: 'Sie sind derzeit nicht mit dem mea Chat verbunden. Klicken Sie hier, um sich anzumelden.',
    already_sent: 'Das Formular wurde bereits abgesendet.',
    required_field: 'Bitte füllen Sie alle Pflichtfelder aus',
    bulk_item_error: 'Bitte entfernen Sie die markierten Aufträge, da diese Fehler enthalten.',
    bulk_selection: 'Mehrfachauswahl',
    bulk_extend_alert: 'Für diesen Auftrag kann keine Verlängerung vorgenommen werden. Entfernen Sie ihn aus der Liste.',
    bulk_cancel_alert: 'Für diesen Auftrag kann keine Stornierung vorgenommen werden. Entfernen Sie ihn aus der Liste.',
    bulk_dispo_acknowledge_alert: 'Die Ware aus diesem Auftrag kann nicht als angekommen markiert werden. Entfernen Sie den Auftrag aus der Liste.',
    bulk_disabled: 'Diese Optionen stehen nicht zur Verfügung, da sie sich in der Mehrfachauswahl befinden.',
};
