import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import * as moment from 'moment';

import * as d3S from 'd3-selection';
import * as d3Scale from 'd3-scale';
import * as d3Shape from 'd3-shape';
import * as d3Array from 'd3-array';
import 'd3-transition';
import { BarChartBaseComponent } from '../bar-chart-base-component/bar-chart-base.component';
import { unsubscribe } from '../../util/subscriptions.util';
import { StatisticsSizeEnum } from '../../enums/statistics.enum';
import { UtilService } from '../../services/util.service';

@Component({
    selector: 'app-statistics-bar-chart',
    templateUrl: './statistics-bar-chart.component.html',
    styleUrls: ['../bar-chart-base-component/bar-chart-base.component.scss', './statistics-bar-chart.component.scss'],
})
export class StatisticsBarChartComponent extends BarChartBaseComponent implements OnInit, OnChanges {

    constructor(private utilService: UtilService) {
        super();
        this.generateUniqueId();
        this.offset = 25;

        // Add debounce to prevent rendering issues (especially at initialization)
        this.initChart = this.utilService.debounce(this.initChart, 500);
        this.rerenderChart = this.utilService.debounce(this.rerenderChart, 500);
    }
    @Input() showLegend = false;
    @Input() showDetails = false;
    @Input() rerender = new EventEmitter();
    @ViewChild('barChart') barChart;


    barColors: any;
    textColors: any;
    borderColors: any;
    renderSubscription: Subscription;

    @HostListener('window:resize')
    onResize() {
        super.onResize();
    }

    ngOnInit() {
        unsubscribe(this.renderSubscription);
        this.renderSubscription = this.rerender.subscribe(() => {
            this.rerenderChart();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.onChanges(changes);
    }

    rerenderChart() {
        if(this.barChart && this.barChart.nativeElement
            && this.barChart.nativeElement.offsetHeight !== undefined) {
            // Remove svg first to make sure we receive the right sizes
            d3S.select('#' + this.uniqueID + ' svg').remove();
            d3S.select('#' + this.uniqueID + ' .tooltip').remove();

            this.calculateWidthHeight();
            this.initSVG();
            this.drawChart();
        }
    }

    calculateWidthHeight() {
        super.calculateWidthHeight();
        this.height = this.barChart.nativeElement.offsetHeight - this.offset;
        this.height = this.height < this.offset ? this.offset : this.height;
        this.width = this.barChart.nativeElement.offsetWidth;
    }

    /**
     * Generate a unique id to prevent duplicate id's in html
     */
    generateUniqueId() {
        super.generateUniqueId();
    }

    /**
     * Check if it is possible to create the chart, initialize it if it is possible
     */
    initChart() {
        super.initChart();
        if(this.barChart && this.barChart.nativeElement
            && this.barChart.nativeElement.offsetHeight && !this.isChartInitialized && this.data) {
            this.calculateWidthHeight();
            this.initSVG();
            this.drawChart();
            this.isChartInitialized = true;
        } else if(this.tries < 1000 && !this.isChartInitialized) {
            this.tries++;
            setTimeout(() => {
                this.initChart();
            }, 250);
        }
    }

    /**
     * Initialize the chart svg
     */
    initSVG() {
        super.initSVG();

        this.barColors = d3Scale.scaleOrdinal()
            .range(['var(--ion-color-light)', 'var(--ion-color-medium-tint)', 'var(--statistics-bar-chart-positive-light)']);
        this.textColors = d3Scale.scaleOrdinal()
            .range(['var(--ion-color-medium)', 'var(--ion-color-dark)', 'var(--statistics-bar-chart-positive)']);
        this.borderColors = d3Scale.scaleOrdinal()
            .range(['var(--ion-color-light)', 'var(--ion-color-dark)', 'var(--statistics-bar-chart-positive)']);

    }

    calculateYMaxMin() {
        super.calculateYMaxMin();
        const yMaxPrevious = d3Array.max(this.data.previous, item => item.value);
        const yMaxCurrent = d3Array.max(this.data.current, item => item.value);
        this.yMax = Math.max(Number(yMaxCurrent), Number(yMaxPrevious));
        this.yMax = this.yMax > 0 ? this.yMax : 100;
    }

    /**
     * Draw the chart depending on the values
     */
    drawChart() {
        super.drawChart();
        if (!this.chart) {
            return;
        }
        const update = this.chart.selectAll('.bar')
            .data(this.data.current);
        update.remove().exit();

        this.drawLabels();
        this.drawLine();
        if (this.showYLine) {
            this.drawCrosshair();
        }

        update.enter()
            .append('rect')
            .style('cursor', this.isBarClickable ? 'pointer' : null)
            .on('mouseover', (event, item) => this.handleMouseOver(event, item))
            .on('mouseout', (event, item) => this.handleMouseOut(event, item))
            .on('click', (event, item) => this.barClicked.emit(item))
            .attr('class', item => this.getBarClass(item.month))
            .attr('x', item => this.xScale(item.month))
            .attr('width', this.xScale.bandwidth())
            .style('fill', item => this.getBarColor(item.month))
            .attr('y', item => this.yScale(item.value))
            .attr('height', item => (this.v2 ? this.height - this.offset : this.height) - this.yScale(item.value));

        update.enter()
            .append('rect')
            .attr('class', item => this.getBarClass(item.month, 'border'))
            .attr('x', item => this.xScale(item.month))
            .attr('y', item => this.yScale(item.value) + (this.v2 ? -2 : 0))
            .attr('width', this.xScale.bandwidth())
            .attr('height', 1)
            .style('fill', (item) => this.v2 ? 'var(--ion-color-medium-tint)' : this.getBorderColor(item.month));

    }

    drawCrosshair() {
        super.drawCrosshair();
    }

    /**
     * User hovers a bar
     *
     * @param event - The mouse event
     * @param item - The item that was hovered
     */
    handleMouseOver(event, item) {
        super.handleMouseOver(event, item);

        if (event.target.classList.contains('bar')) {
            d3S.select(event.target)
                .style('fill', this.barColors(3));
        }
    }

    /**
     * User stops to hover the bar
     *
     * @param event - The mouse event
     * @param item - The item that was hovered
     */
    handleMouseOut(event, item) {
        super.handleMouseOut();

        if (event.target.classList.contains('bar')) {
            d3S.select(event.target)
                .style('fill', this.getBarColor(item.month));
        }
    }

    /**
     * User hovers the chart
     */
    handleRootMouseOver() {
        super.handleRootMouseOver();
    }

    /**
     * User stops to hover the chart
     *
     * @param event - The mouse event
     */
    handleRootMouseOut(event) {
        super.handleRootMouseOut(event);
    }

    handleRootMouseMove(event) {
        super.handleRootMouseMove(event);
    }


    handleClick(item) {
        super.handleClick(item);
    }

    /**
     * Returns the class depending on the delivery
     *
     * @param date - Date of the item
     * @param classes - Additional classes for styling
     */
    getBarClass(date, classes = '') {
        return super.getBarClass(date, classes);
    }

    /**
     * Returns the class depending on the delivery
     *
     * @param date - Date of the item
     * @param classes - Additional classes for styling
     */
    getTextClass(date, classes = '') {
        return super.getTextClass(date, classes);
    }

    /**
     * Format any date to day and Month
     *
     * @param date - Date to format
     */
    formatMonthAndYear(date) {
        return super.formatMonthAndYear(date);
    }

    /**
     * track by
     *
     * @param index - the index of the item
     * @param item - the item to use for track
     */
    trackBy (index, item) {
        return super.trackBy(index, item);
    }

    /**
     * convert date or month to month
     *
     * @param dateOrMonth - the date or month to convert
     */
    convertToMonth(dateOrMonth) {
        return super.convertToMonth(dateOrMonth);
    }

    /**
     * Draw month labels
     */
    private drawLabels() {
        const barWidth = Math.floor(this.width / this.data.current.length) - 1;
        const update = this.monthLabels.selectAll('.month')
            .data(this.data.current);
        update.remove().exit();

        update.enter().append('g')
            .on('mouseover', (event, item) => this.handleMouseOver(event, item))
            .on('mouseout', (event, item) => this.handleMouseOut(event, item))
            .attr('class', 'month')
            .attr('transform', item => 'translate(' + (this.xScale(item.month)  + (barWidth / 2) ) + ',0)')
            .append('text')
            .attr('class', item => this.getTextClass(item.month, 'month-label'))
            .attr('y', this.height - (this.size === StatisticsSizeEnum.SMALL ?  10 : 4))
            .attr('text-anchor', 'middle')
            .text(item => moment(item.month, 'MM').format('MMM'))
            .style('font-size', () => this.size === StatisticsSizeEnum.SMALL ?  'var(--ion-font-size-small-text)' : 'var(--ion-font-size-text)')
            .style('font-weight', () => 'var(--ion-font-weight-subheadline)')
            .style('fill',item => this.getTextColor(item.month));

        if(this.v2) {
            const valueLabels = this.monthLabels.selectAll('.monthValues')
                .data(this.data.current);

            valueLabels.remove().exit();

            valueLabels.enter().append('g')
                .attr('class', 'monthValues')
                .attr('transform', item => 'translate(' + (this.xScale(item.month)  + (barWidth / 2) ) + ',0)')
                .append('text')
                .attr('class', item => this.getTextClass(item.month))
                .attr('y', item => {
                    // Prevent to show the label in or below the bottom line
                    if((this.height - (this.yScale(item.value) + 16)) < 30) {
                        return this.height - 30;
                    } else {
                        return this.yScale(item.value) + 16;
                    }
                })
                .attr('text-anchor', 'middle')
                .text(item => item.value === 0 ? '' : (item.value >= 1000 ? (Math.round(item.value / 1000 * 10) / 10 + 'k') : item.value))
                .style('font-size', () => this.size === StatisticsSizeEnum.SMALL ?  'var(--ion-font-size-small-text)' : 'var(--ion-font-size-text)')
                .style('font-weight', () => 'var(--ion-font-weight-subheadline)')
                .style('fill', 'var(--ion-color-dark)')
                .style('pointer-events', 'none');
        }
    }

    /**
     * Draw a line chart above the bar chart
     */
    private drawLine() {
        if(!this.data.previous || this.data.previous.length === 0) {
            return;
        }
        const lines = this.lines.selectAll('path.line')
            .data(this.data.current);
        lines.remove().exit();
        const barWidth = Math.floor(this.width / this.data.previous.length) - 1;
        const line = d3Shape.line()
            .x( d => this.xScale(d['month']) + (barWidth / 2))
            .y( d => this.yScale(d['value']));

        this.lines
            .append('path')
            .datum(this.data.previous)
            .attr('class', 'line')
            .attr('fill', 'none')
            .attr('stroke', 'var(--ion-color-light-shade)')
            .attr('d', line);
    }

    /**
     * Returns the color depending on the delivery
     *
     * @param date - Date of the item
     */
    private getBarColor(date) {
        const isCurrentMonth = this.convertToMonth(date) === this.currentMonth;
        return this.barColors((isCurrentMonth ? 2 : 1));
    }

    /**
     * Returns the color depending on the delivery
     *
     * @param date - Date of the item
     */
    private getTextColor(date) {
        const isCurrentMonth = this.convertToMonth(date) === this.currentMonth;
        return this.textColors((isCurrentMonth ? 2 : 1));
    }

    /**
     * Returns the color depending on the delivery
     *
     * @param date - Date of the item
     */
    private getBorderColor(date) {
        const isCurrentMonth = this.convertToMonth(date) === this.currentMonth;
        return this.borderColors((isCurrentMonth ? 2 : 1));
    }
}
