import { Pipe, PipeTransform } from '@angular/core';
import { DocumentSubType, DocumentType } from '../enums/documents.enum';
import { DeliveryReceiptTypeAllConfig, DeliveryReceiptTypeConfig } from '../config/delivery-receipt.config';
import { DocumentInterface } from '../interfaces/document.interface';
import { InvoicesTypeFilterConfig } from '../config/invoices.config';
import { getMonthFromDate } from '../formatting/date.formatting';
import { DateEnum } from '../enums/date.enum';

/**
 * This pipe returns the label of a document receipt type by its id
 */
@Pipe({
    name: 'documentTypeFilterLabel'
})
export class DocumentTypeFilterLabelPipe implements PipeTransform {
    transform(val: DocumentType): string {
        let documentTypes;
        documentTypes = DeliveryReceiptTypeConfig.find(
            type => type.id.toUpperCase() === val.toUpperCase()
        );
        return documentTypes ? documentTypes.title : DeliveryReceiptTypeAllConfig.title;
}
}

/**
 * This pipe returns the label of a delivery receipt subtype by its id
 */
@Pipe({
    name: 'documentSubTypeFilterLabel'
})
export class DocumentSubTypeFilterLabelPipe implements PipeTransform {
    transform(val: DocumentSubType): string {
        let documentSubType;
        documentSubType = InvoicesTypeFilterConfig.find(
            type => type.id.toUpperCase() === val.toUpperCase()
        );
        return documentSubType ? documentSubType.title : documentSubType[0].title;
    }
}

/**
 * This pipe returns the document number formatted of a delivery receipt
 */
@Pipe({
    name: 'documentNumberFormatted'
})
export class DocumentNumberFormattedPipe implements PipeTransform {
    transform(documentNumber: string): string {
        return documentNumber && documentNumber.length > 2 ? documentNumber.substring(0,2) + '-' + documentNumber.substring(2) : '';
    }
}

/**
 * This pipe returns the number of a specified document type
 */
@Pipe({
    name: 'documentTypeNumberOfDocuments'
})
export class DocumentTypeNumberOfDocumentsPipe implements PipeTransform {
    static formatDate(date) {
        return getMonthFromDate(date, DateEnum.yearMonthDay, DateEnum.yearMonth);
    }
    transform(type: DocumentType | DocumentSubType, documents: DocumentInterface[] = [], date: string = null): string {
        let documentTypes = documents;
        let numberOfDocuments = 0;
        let isDeliveryReceipt = true;

        if(!documentTypes) {
            return '';
        }

        if(date && (type === DocumentType.LS || type === DocumentType.CH)) {
            documentTypes = documentTypes.filter(doc => doc && doc?.recDate === date);
        } else if (date && (type === DocumentSubType.InvoiceMonthly || type === DocumentSubType.InvoicePartial || type === DocumentSubType.SB)) {
            // filter for all invoices of the same yearMonth
            documentTypes = documentTypes.filter(doc => doc &&
              DocumentTypeNumberOfDocumentsPipe.formatDate(doc?.recDate) === DocumentTypeNumberOfDocumentsPipe.formatDate(date)
            );
        }
        switch (type) {
            case DocumentType.CH:
                isDeliveryReceipt = true;
                documentTypes.forEach((doc) => {
                    numberOfDocuments += doc?.documentChildren?.length || 0;
                });
                break;
            case DocumentType.LS:
                isDeliveryReceipt = true;
                documentTypes = documentTypes.filter(
                    doc => doc?.documentType?.toUpperCase() === type.toUpperCase()
                );
                numberOfDocuments = documentTypes.length ? documentTypes.length : 0;
                break;
            case DocumentSubType.InvoiceMonthly:
            case DocumentSubType.InvoicePartial:
            case DocumentSubType.SB:
                isDeliveryReceipt = false;
                documentTypes = documentTypes.filter(
                    item => type.toUpperCase().split(',').includes(item?.documentSubType?.toUpperCase())
                );
                numberOfDocuments = documentTypes.length ? documentTypes.length : 0;
                break;
        }

        let deliveryTypeConfig;
        if(isDeliveryReceipt) {
            deliveryTypeConfig = DeliveryReceiptTypeConfig.find((dType) => dType.id.toUpperCase() === type.toUpperCase());
        } else {
            deliveryTypeConfig = InvoicesTypeFilterConfig.find((dType) => dType.id.toUpperCase() === type.toUpperCase());
        }
        return numberOfDocuments === 1 ?
            numberOfDocuments + ' ' + deliveryTypeConfig.title :
            numberOfDocuments + ' ' + deliveryTypeConfig.titlePlural;
    }
}

/**
 * Returns the year month of a given date as string
 */
@Pipe({
    name: 'documentGetYearMonth'
})
export class DocumentGetYearMonthPipe implements PipeTransform {
    transform(recDate: string): string {
        return getMonthFromDate(recDate, DateEnum.yearMonthDay, DateEnum.yearMonth);
    }
}
