import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CalendarModule } from 'primeng/calendar';

import { CoreModule } from '../../core/core.module';
import { DocumentListComponent } from './document-list.component';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        CalendarModule,
        CoreModule
    ],
    exports: [
        DocumentListComponent
    ],
    declarations: [
        DocumentListComponent
    ]
})
export class DocumentListModule {
}
