import { Injectable, computed, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { PharmacyStoreInterface } from '../../interfaces/customer.interface';
import { AuthStorageKeyEnum } from '../../enums/authStorageKey.enum';

@Injectable({
    providedIn: 'root',
})
export class PharmacyStoreStateVar {
    // Has to be undefined, otherwise the app will
    // redirect to the dashboard after reload.
    // private defaultValue = undefined;
    private _pharmacyStoresState = signal<PharmacyStoreInterface[]>(null);
    public pharmacyStoresState = this._pharmacyStoresState.asReadonly();
    public pharmacyStoresState$ = toObservable(this.pharmacyStoresState);

    public activePharmacyStoreState = computed(() => {
        const activePharmacyId = localStorage.getItem(AuthStorageKeyEnum.activePharmacy);
        return this.pharmacyStoresState()?.find((p) => p.apiUser.toString() === activePharmacyId);
    });
    public activePharmacyStoreState$ = toObservable(this.activePharmacyStoreState);
    public isSanavendiAvailable = computed(() => this.activePharmacyStoreState()?.sanavendiAvailable || false);
    public pharmacyBranch = computed(() => this.activePharmacyStoreState()?.sanacorpLocation);
    public apiUser = computed(() => this.activePharmacyStoreState()?.apiUser);

    public isMea = computed(() => this.activePharmacyStoreState()?.isMea || false);
    public hasShop = computed(() => this.activePharmacyStoreState()?.shopAvailable || false);
    public hasChat = computed(() => this.activePharmacyStoreState()?.chatAvailable || false);
    public firstInitialized = computed(() => this.activePharmacyStoreState()?.firstInitialized);

    set(pharmacyStoreState: PharmacyStoreInterface[]) {
        this._pharmacyStoresState.set(pharmacyStoreState);
    }
}
