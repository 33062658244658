import { Component, Input, TemplateRef } from '@angular/core';
import { CardImageInterface } from '../../interfaces/card.interface';
import { ColorInterface } from '../../interfaces/theme-color.interface';
import { ImageTypesEnum } from '../../enums/image-types.enum';

@Component({
    selector: 'app-card',
    templateUrl: 'card.component.html',
    styleUrls: ['card.component.scss'],
})
export class CardComponent {
    @Input() additionalTopTemplateRef?: TemplateRef<any>;
    @Input() imageTemplateRef?: TemplateRef<any>;
    @Input() image?: CardImageInterface;
    @Input() subTitleTemplateRef?: TemplateRef<any>;
    @Input() subTitle?: string;
    @Input() titleTemplateRef?: TemplateRef<any>;
    @Input() title?: string;
    @Input() contentTemplateRef?: TemplateRef<any>;
    @Input() content?: string;
    @Input() footerTemplateRef?: TemplateRef<any>;
    @Input() useFooterFadeOut? = false;
    @Input() useDivider? = false;
    @Input() hasDynamicHeight? = false;
    @Input() themeColor?: ColorInterface;
    @Input() disableShadow = false;
    @Input() listLayout = false;

    constructor() {
    }

    protected readonly ImageTypes = ImageTypesEnum;
}
