export const GraphQLLimits = {
    appointment: 100,
    appointmentReminder: 100,
    banner: 1,
    bannerMedia: 3,
    businessFigure: 500,
    communicationZone: 100,
    customerService: 1,
    defaultLimit: 100,
    documents: 100,
    educations: 100,
    infoModals: 1,
    inputValidator: 30,
    meaShopNotifications: 100,
    notification: 100,
    notificationEnabledTypes: 100,
    chatNotification: 50,
    offerProducts: 100,
    offerNews: 20,
    offerFilters: 100,
    offerGraduatedPrice: 20,
    offers: 100,
    order: 100,
    orderAuditLog: 100,
    newsFilter: 100,
    note: 100,
    reOrder:100,
    safetyDataSheets: 100,
    seminarRegistration: 100,
    seminarRegistrationParticipants: 500,
    seminarRegistrationReminder: 100,
    surveyAnswers: 100,
    newsPosts: 100,
    returns: 100,
    releaseNotes: 100,
    releaseNotesConnection: 100,
    releaseNoteDetails: 100,
    releaseNoteDetailsConnection: 100,
    sanacorpAppointments: 100,
    sanacorpAppointmentsConnection: 100,
    sanacorpAppointmentTimespan: 100,
    seminars: 100,
    seminarsConnection: 100,
};
