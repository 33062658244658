import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { gql } from '@apollo/client/core';

import { FetchPolicyKeys as FPK } from '../../../enums/fetch-policy-keys.enum';
import { QueryWrapper } from '../query.wrapper';
import { ArchiveInterface } from '../../../interfaces/archive.interface';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';
import { DataChangedKeys as DCK } from '../../../enums/data-changed-keys.enum';
import { QueryFetchPolicy } from '../../../enums/api.enum';

export const GetArchives = (queryName) => gql`
    query ${queryName} {
        archive(order_by: {created_at: desc}, limit: 100) {
            id
            created_at
            type
            fromYearMonth
            toYearMonth
            filename
            hasError
            isEmpty
            isCanceled
            isFinished
            additionalInformation
        }
    }
`;



export const AllArchiveQueries = [
    GetArchives('test'),
];
@Injectable()
export class ArchiveQueries extends QueryWrapper {
    fetchPolicies = {
        [FPK.getArchives]: QueryFetchPolicy.NETWORK_ONLY
    };

    constructor(
        private apollo: Apollo,
        private dataChangedVar: DataChangedStateVar
    ) {
        super(apollo, dataChangedVar, {
            [DCK.archiveChanged]: [
                FPK.getArchives,
            ]
        });
    }

    public getArchives(): Observable<ArchiveInterface[]> {
        const fetchPolicyKey = FPK.getArchives;

        return this.apollo.watchQuery({
            query: GetArchives(fetchPolicyKey),
            variables: {},
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
        })
            .valueChanges
            .pipe(
                map(d => d?.data && d.data['archive']),
                map(d => d?.map((archive: ArchiveInterface) => ({...archive, createdAt: archive['created_at']}))),
                map(d =>
                    // only show the last 7 days (deletion after 7 days)
                    (d as ArchiveInterface[]).filter(
                        bF => new Date(bF.createdAt) >= new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7)
                    )
                ),
                map(d => {
                        if (d) this.updateFetchPolicy(fetchPolicyKey);
                        return d;
                    }
                ));
    }
}
