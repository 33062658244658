import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { inject, Injectable } from '@angular/core';
import * as moment from 'moment';
import { NavigationRoutesEnum } from '../navigation/navigation.routes';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class GameGuard {
    private router = inject(Router);

    constructor() {}

    /**
     * Activates the route only for Christmas
     */
    canActivateChristmas(): boolean {
        const currentDate = moment(new Date());
        const hasAccess = currentDate.isBetween(
            moment(environment.fun.santaGame.fromDate, 'DD.MM.YYYY', true),
            moment(environment.fun.santaGame.toDate, 'DD.MM.YYYY', true)
        );
        if (!hasAccess) {
            void this.router.navigateByUrl(NavigationRoutesEnum.dashboard);
        }
        return hasAccess;
    }

    /**
     * Activates the route only for Easter
     */
    canActivateEaster(): boolean {
        const currentDate = moment(new Date());
        const hasAccess = currentDate.isBetween(
            moment(environment.fun.easterGame.fromDate, 'DD.MM.YYYY', true),
            moment(environment.fun.easterGame.toDate, 'DD.MM.YYYY', true)
        );
        if (!hasAccess) {
            void this.router.navigateByUrl(NavigationRoutesEnum.dashboard);
        }
        return hasAccess;
    }

    /**
     * Activates the route only for Halloween
     */
    canActivateHalloween(): boolean {
        const currentDate = moment(new Date());
        const hasAccess = currentDate.isBetween(
            moment(environment.fun.halloweenGame.fromDate, 'DD.MM.YYYY', true),
            moment(environment.fun.halloweenGame.toDate, 'DD.MM.YYYY', true)
        );
        if (!hasAccess) {
            void this.router.navigateByUrl(NavigationRoutesEnum.dashboard);
        }
        return hasAccess;
    }

    /**
     * Activates the route for the specific event
     */
    canActivate(route: ActivatedRouteSnapshot): boolean {
        if (route.data?.event === 'easter') {
            return this.canActivateEaster();
        }

        if (route.data?.event === 'halloween') {
            return this.canActivateHalloween();
        }
        return this.canActivateChristmas();
    }
}
