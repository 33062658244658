import { CommunicationZoneFormEnum } from '../enums/communication-zone-form.enum';
import { OrderDetailInterface, OrderInterface } from '../interfaces/order.interface';
import { OrderTypesTranslationConfig } from './order-types.config';
import { formatDateTimeToDate, formatDateTimeToMoment, formatDateTimeToTime } from '../formatting/date.formatting';
import { DocumentInterface } from '../interfaces/document.interface';
import { DocumentNumberFormattedPipe } from '../pipes/document-type.pipe';
import { DocumentType } from '../enums/documents.enum';
import { ReturnsDetailInterface } from '../interfaces/returns.interface';

export const CommunicationZoneFormsSubstituteConfig = {
    customerEmail: '{{CUSTOMER_EMAIL}}',
    documentNumber: '{{DOCUMENT_NUMBER}}',
    documentRecDate: '{{DOCUMENT_REC_DATE}}',
    orderType: '{{ORDER_TYPE}}',
    orderIdOrg: '{{ORDER_ID_ORG}}',
    quantity: '{{QUANTITY}}',
    productName: '{{PRODUCT_NAME}}',
    dosageForm: '{{DOSAGE_FORM}}',
    packageSize: '{{PACKAGE_SIZE}}',
    producer: '{{PRODUCER}}',
    pzn: '{{PZN}}',
    recTime: '{{REC_TIME}}',
    returnsTitle: '{{RETURNS_TITLE}}',
    deliveryNoteNumber: '{{DELIVERY_NOTE_NUMBER}}',
    deliveryDate: '{{DELIVERY_DATE}}',
};


export const CommunicationZoneFormsConfig = [
    {
        formId: CommunicationZoneFormEnum.documentCH,
        values: {
            title: 'Rückfrage zu Chargenbeleg Nr. ' + CommunicationZoneFormsSubstituteConfig.documentNumber,
            content: 'Chargenbeleg Nr. ' + CommunicationZoneFormsSubstituteConfig.documentNumber + ' vom ' +
                CommunicationZoneFormsSubstituteConfig.documentRecDate +'\n\n',
            contactPerson: ''
        }
    },
    {
        formId: CommunicationZoneFormEnum.documentLS,
        values: {
            title: 'Rückfrage zu Lieferschein Nr. ' + CommunicationZoneFormsSubstituteConfig.documentNumber,
            content: 'Lieferschein Nr. ' + CommunicationZoneFormsSubstituteConfig.documentNumber + ' vom ' +
                CommunicationZoneFormsSubstituteConfig.documentRecDate +'\n\n',
            contactPerson: ''
        }
    },
    {
        formId: CommunicationZoneFormEnum.editProfile,
        values: {
            title: 'Änderung Nutzerprofil',
            content: 'Bitte ändern Sie folgende Daten des Nutzerprofils ' + CommunicationZoneFormsSubstituteConfig.customerEmail + ' \n\n[Ihre Änderungen]\n\n\n\n\nVielen Dank, Ihr Apothekenteam',
            contactPerson: ''
        }
    },
    {
        formId: CommunicationZoneFormEnum.companyChangeRequest,
        values: {
            title: 'Änderung Apothekenstammdaten',
            content: 'Bitte ändern Sie folgende Daten meiner Apothekenadresse:\n\n[Ihre Änderungen]\n\n\nBitte übernehmen Sie die Änderung ebenfalls in meine Digitalprodukte.\n\nVielen Dank Ihr Apothekenteam',
            contactPerson: ''
        }
    },
    {
        formId: CommunicationZoneFormEnum.cooperationBonusRequest,
        values: {
            title: 'Interesse an mea Kooperation',
            content: 'Ich habe Interesse an der mea Kooperation und damit verbundenen Einkaufsvorteilen. Bitte kontaktieren Sie mich.\n\n',
            contactPerson: ''
        }
    },
    {
        formId: CommunicationZoneFormEnum.documentESR,
        values: {
            title: 'Rückfrage zur Monatsrechnung Nr. ' + CommunicationZoneFormsSubstituteConfig.documentNumber,
            content: 'Monatsrechnung Nr. ' + CommunicationZoneFormsSubstituteConfig.documentNumber + ' vom ' +
                CommunicationZoneFormsSubstituteConfig.documentRecDate +'\n\n',
            contactPerson: ''
        }
    },
    {
        formId: CommunicationZoneFormEnum.documentETR,
        values: {
            title: 'Rückfrage zur Teilrechnung Nr. ' + CommunicationZoneFormsSubstituteConfig.documentNumber,
            content: 'Teilrechnung Nr. ' + CommunicationZoneFormsSubstituteConfig.documentNumber + ' vom ' +
                CommunicationZoneFormsSubstituteConfig.documentRecDate +'\n\n',
            contactPerson: ''
        }
    },
    {
        formId: CommunicationZoneFormEnum.meaChatContact,
        values: {
            title: 'Interesse an mea Chat',
            content: 'Guten Tag,\n\nWir möchten mea Chat genauer kennenlernen und wünschen uns weitere Informationen.\n\nBitte kontaktieren Sie uns.',
            contactPerson: ''
        }
    },
    {
        formId: CommunicationZoneFormEnum.invoicesContact,
        values: {
            title: 'Umstellung auf e-Rechnung',
            content: 'Guten Tag,\n\nwir möchten zukünftig e-Rechnungen erhalten und wünschen uns weitere Informationen zum Ablauf.\n\nBitte kontaktieren Sie uns.',
            contactPerson: ''
        }
    },
    {
        formId: CommunicationZoneFormEnum.order,
        values: {
            title: `Rückfrage zu ${CommunicationZoneFormsSubstituteConfig.orderType} Nr. ${CommunicationZoneFormsSubstituteConfig.orderIdOrg}`,
            content: `${CommunicationZoneFormsSubstituteConfig.orderType} Nr. ${CommunicationZoneFormsSubstituteConfig.orderIdOrg}, ${CommunicationZoneFormsSubstituteConfig.quantity} * ${CommunicationZoneFormsSubstituteConfig.productName}, ${CommunicationZoneFormsSubstituteConfig.dosageForm}, ${CommunicationZoneFormsSubstituteConfig.packageSize}, ${CommunicationZoneFormsSubstituteConfig.producer}, PZN ${CommunicationZoneFormsSubstituteConfig.pzn}, Bestelldatum: ${CommunicationZoneFormsSubstituteConfig.recTime} \n\n`,
            contactPerson: ''
        }
    },
    {
        formId: CommunicationZoneFormEnum.returns,
        values: {
            title: `${CommunicationZoneFormsSubstituteConfig.returnsTitle}`,
            content: `Datum: ${CommunicationZoneFormsSubstituteConfig.recTime}, ${CommunicationZoneFormsSubstituteConfig.quantity} * ${CommunicationZoneFormsSubstituteConfig.productName}, ${CommunicationZoneFormsSubstituteConfig.dosageForm}, ${CommunicationZoneFormsSubstituteConfig.packageSize}, ${CommunicationZoneFormsSubstituteConfig.producer}, PZN ${CommunicationZoneFormsSubstituteConfig.pzn}, Lieferscheinnummer: ${CommunicationZoneFormsSubstituteConfig.deliveryNoteNumber} , Lieferscheindatum: ${CommunicationZoneFormsSubstituteConfig.deliveryDate} \n\n`,
            contactPerson: ''
        }
    },
];

export const getDocumentReplacementArray = (document: DocumentInterface) => {
    const documentNumberFormatted = document?.documentType === DocumentType.RE ?
        document?.documentNumber :
        new DocumentNumberFormattedPipe().transform(document?.documentNumber);
   return [
       {
           key: CommunicationZoneFormsSubstituteConfig.documentNumber,
           value: documentNumberFormatted || ''
       },
       {
           key: CommunicationZoneFormsSubstituteConfig.documentRecDate,
           value: document?.recDate ? formatDateTimeToMoment(document?.recDate).format('DD.MM.YYYY') : ''
       }
   ];
};


export const getReturnsReplacementArray = (statusChange = true, returns: ReturnsDetailInterface) => {
    const title = statusChange ?
        'Retoure im Status "'+ returns.status +'" ' + returns.productName :
        'Rückfrage zur RETOURE ' + returns.productName;
    const recTime = returns.recTime ? formatDateTimeToDate(returns.recTime, true) : 'unbekannt';
    const deliveryDate = returns.deliveryNoteDate ? formatDateTimeToDate(returns.deliveryNoteDate, false) : 'unbekannt';

   return [
       {
           key: CommunicationZoneFormsSubstituteConfig.returnsTitle,
           value: title || ''
       },
       {
           key: CommunicationZoneFormsSubstituteConfig.recTime,
           value: recTime
       },
       {
           key: CommunicationZoneFormsSubstituteConfig.quantity,
           value: returns?.quantity || ''
       },
       {
           key: CommunicationZoneFormsSubstituteConfig.productName,
           value: returns?.productName || ''
       },
       {
           key: CommunicationZoneFormsSubstituteConfig.dosageForm,
           value: returns?.dosageForm || ''
       },
       {
           key: CommunicationZoneFormsSubstituteConfig.packageSize,
           value: returns?.packageSize || ''
       },
       {
           key: CommunicationZoneFormsSubstituteConfig.producer,
           value: returns?.producer || ''
       },
       {
           key: CommunicationZoneFormsSubstituteConfig.pzn,
           value: returns?.pzn?.padStart(8, '0') || ''
       },
       {
           key: CommunicationZoneFormsSubstituteConfig.deliveryNoteNumber,
           value: returns?.deliveryNoteNumber || ''
       },
       {
           key: CommunicationZoneFormsSubstituteConfig.deliveryDate,
           value: deliveryDate || ''
       },
   ];
};

export const getOrderReplacementArray = (order: OrderDetailInterface) => {
    const orderType = OrderTypesTranslationConfig[order?.type] ? OrderTypesTranslationConfig[order?.type] : '';

    return  [
        {
            key: CommunicationZoneFormsSubstituteConfig.orderType,
            value: orderType
        },
        {
            key: CommunicationZoneFormsSubstituteConfig.orderIdOrg,
            value: order?.orderIdOrg ?? ''
        },
        {
            key: CommunicationZoneFormsSubstituteConfig.quantity,
            value: order?.quantity ?? ''
        },
        {
            key: CommunicationZoneFormsSubstituteConfig.productName,
            value: order?.productName ?? ''
        },
        {
            key: CommunicationZoneFormsSubstituteConfig.dosageForm,
            value: order?.dosageForm ?? ''
        },
        {
            key: CommunicationZoneFormsSubstituteConfig.packageSize,
            value: order?.packageSize ?? ''
        },
        {
            key: CommunicationZoneFormsSubstituteConfig.producer,
            value: order?.producer ?? ''
        },
        {
            key: CommunicationZoneFormsSubstituteConfig.pzn,
            value: order?.pzn ? order.pzn.padStart(8, '0') : ''
        },
        {
            key: CommunicationZoneFormsSubstituteConfig.recTime,
            value: order?.recTime ?
                formatDateTimeToDate(order?.recTime, true) + ' ' +
                formatDateTimeToTime(order?.recTime, true) : ''
        }
    ];
};
