import { Component, inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Calendar } from 'primeng/calendar';

import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';

import { DateRangeOptionCodes } from '../../enums/date-range.enum';
import { getDateRangesWithoutDates, getDateFromConfig } from '../../config/date-range.config';
import { DateRangeInterface } from '../../interfaces/date-range.interface';

import { AppointmentTimeValidator } from '../../core.validators';
import {
    compareDates,
    defaultDateFormat,
    formatDateTimeToDate,
    formatDateToDefaultDateFormat,
    getDefaultCalendarOptions,
    TIME_SEPARATOR,
} from '../../core.formatting';
import { ToastService } from '../../services/toast.service';
import { ModalService } from '../../services/modal.service';
import { CompareDateEnum } from '../../enums/date.enum';
import { ModalClassEnum } from '../../enums/modal-class.enum';
import { UtilService } from '../../services/util.service';

@Component({
    selector: 'app-date-range-picker',
    templateUrl: './date-range-picker.component.html',
    styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent implements OnInit, OnChanges {
    // Adds class to modal
    static modalClass = ModalClassEnum.autoHeight;

    @Input() title: string;
    @Input() dateRangeOptions: Array<DateRangeInterface>;
    @Input() selectedDateRangeId: DateRangeOptionCodes;
    @Input() minDate: Date;
    @Input() maxDate: Date;
    @Input() fromDate: string;
    @Input() toDate: string;
    // times must always be in local time
    @Input() fromTime = '00:00';
    @Input() toTime = '23:59';
    @Input() displayTime = false;
    @Input() displayOnlyFutureDates = false;
    @Input() displayOnlyPastDates = false;
    @Input() displayIncludingToday = true;

    @ViewChild('inlineDateRangePicker') inlineDateRangePicker: Calendar;

    private utilService = inject(UtilService);
    public formBuilder = inject(FormBuilder);
    private modalService = inject(ModalService);
    private toastService = inject(ToastService);

    displayIndividualCalendar = true;
    hasError = false;
    individualOption = DateRangeOptionCodes.individual;
    allOption = DateRangeOptionCodes.all;

    type: 'string'; // 'string' | 'js-date' | 'moment' | 'time' | 'object'
    calendarOptions: any;

    dateRange: Array<Date> = null;

    dateFormGroup: FormGroup;
    timeFormGroup: FormGroup;

    selectText = '';
    toastToBeforeFromDate : HTMLIonToastElement;
    toastFromBeforeMinDate : HTMLIonToastElement;

    static formatTime(hour, minute) {
        return ('0' + hour).slice(-2) + TIME_SEPARATOR + ('0' + minute).slice(-2);
    }

    constructor() {
        this.onInputFieldChange = this.utilService.debouncePromise(this.onInputFieldChange, 300);
    }

    ngOnInit() {
        // Update selected date by delivered input
        if(this.fromDate && this.toDate) {
            this.dateRange = [dayjs(this.fromDate).toDate(), dayjs(this.toDate).toDate()];
        }

        // sets time form group
        this.dateFormGroup = this.formBuilder.group({
            fromDate: [(this.dateRange && this.dateRange[0]) || dayjs().format(defaultDateFormat)],
            toDate: [(this.dateRange && this.dateRange[1]) || dayjs().format(defaultDateFormat)],
        });

        this.dateFormGroup.controls.fromDate.patchValue(
            (this.dateRange && this.dateRange[0] && dayjs(this.dateRange[0]).format(defaultDateFormat))
            || dayjs().format(defaultDateFormat)
        );
        this.dateFormGroup.controls.toDate.patchValue(
            (this.dateRange && this.dateRange[1] && dayjs(this.dateRange[1]).format(defaultDateFormat))
            || dayjs().format(defaultDateFormat)
        );

        // sets time form group
        this.timeFormGroup = this.formBuilder.group({
            fromTime: [this.fromTime, AppointmentTimeValidator()],
            toTime: [this.toTime, AppointmentTimeValidator()],
        });

        // sets the calendar options for both calendars
        this.calendarOptions = getDefaultCalendarOptions(
            this.displayOnlyFutureDates,
            this.displayOnlyPastDates,
            this.displayIncludingToday
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.title || changes.dateRangeOptions || changes.selectedDateRangeId || changes.fromDate || changes.toDate) {
            this.ngOnInit();
        }
    }

    /**
     * User has changed the start and end date
     */
    async onCalendarRangeChange() {
        const isValid = this.setIndividualDateRange();
        if (isValid) {
            await this.checkDates();
        }
    }

    /**
     * called if the date range selection is changed
     *
     * @param selectedDateRangeId - id of the selected date range
     */
    onDateRangeChange(selectedDateRangeId: DateRangeOptionCodes) {
        this.selectedDateRangeId = selectedDateRangeId;

        // finds the data range option for the given id
        const selectedDateRange = this.dateRangeOptions.find(dateRange => dateRange.id === this.selectedDateRangeId);
        if (selectedDateRange && selectedDateRange.id !== DateRangeOptionCodes.individual) {
            if (!getDateRangesWithoutDates(selectedDateRange.id)) {
                const fromDate = getDateFromConfig(selectedDateRange.id, true);
                const toDate = getDateFromConfig(selectedDateRange.id, false);

                const index = this.dateRangeOptions.findIndex(dateRange => dateRange.id === DateRangeOptionCodes.individual);
                this.dateRangeOptions[index].title =
                    `Von ${formatDateTimeToDate(fromDate, false)} bis ${formatDateTimeToDate(toDate, false)}`;

                // Reset date picker view to default (if user is in month or year selection mode)
                this.inlineDateRangePicker.setCurrentView('date');
                this.dateRange = [dayjs(fromDate).toDate(), dayjs(toDate).toDate()];
                this.selectText = this.dateRangeOptions[index].title;
                this.dateFormGroup.controls.fromDate.patchValue(fromDate);
                this.dateFormGroup.controls.toDate.patchValue(toDate);

            } else {
                this.dateRange = null;
                this.inlineDateRangePicker.clear();
            }
        }

        if (!this.displayTime) {
            if (this.selectedDateRangeId !== DateRangeOptionCodes.individual) {
                // closes the modal - the fromDate and toDate will be set in the parent component
                // return this.close();
            }
        }
    }

    /**
     * Date or time is changed using the form inputs.
     */
    async onInputFieldChange(isFormField = false, isToField = false) {
        await this.checkDates(isFormField, isToField);
        this.setIndividualDateRange();
        return new Promise<unknown>(resolve => resolve(true));
    }

    /**
     * checks if dates and times are valid
     *
     * @param isFormField - if the check is called from a form field
     * @param isToField - if the check is called from the "to" field
     */
    async checkDates(isFormField = false, isToField = false) {
        if(isFormField && this.dateFormGroup.value.fromDate) {
            if(this.dateFormGroup.value.toDate) {
                this.dateRange = [dayjs(this.dateFormGroup.value.fromDate).toDate(), dayjs(this.dateFormGroup.value.toDate).toDate()];
            } else {
                this.dateRange = [dayjs(this.dateFormGroup.value.fromDate).toDate(), this.dateRange[1]];
            }
        }

        if(!this.dateRange || !this.dateRange[0] || !this.dateRange[1]) {
            this.hasError = false;
            return true;
        }

        // Update from fields if update is from select of a date range
        if(!isFormField) {
            this.dateFormGroup.controls.fromDate.patchValue(dayjs(this.dateRange[0]).format(defaultDateFormat));
            this.dateFormGroup.controls.toDate.patchValue(dayjs(this.dateRange[1]).format(defaultDateFormat));
        } else if(!isToField) {
            // Check if from date is before to date
            if (this.dateFormGroup.value.fromDate && this.dateFormGroup.value.toDate
            && !dayjs(this.dateFormGroup.value.fromDate).isBefore(dayjs(this.dateFormGroup.value.toDate))) {
                this.dateFormGroup.controls.toDate.patchValue(this.dateFormGroup.value.fromDate);
                this.dateRange = [dayjs(this.dateFormGroup.value.fromDate).toDate(), dayjs(this.dateFormGroup.value.toDate).toDate()];
            }
        }

        if(this.displayTime) {
            this.fromTime = this.timeFormGroup.value.fromTime;
            this.toTime = this.timeFormGroup.value.toTime;
        }

        // Check if time is defined
        if(this.fromTime === undefined || this.fromTime === '') {
            this.fromTime = '00:00';
        }
        if(this.toTime === undefined || this.toTime === '') {
            this.toTime = '23:59';
        }

        const defaultFromDateTime = formatDateToDefaultDateFormat(this.dateRange[0]) + 'T' + this.fromTime;
        const defaultToDateTime = formatDateToDefaultDateFormat(this.dateRange[1]) + 'T' + this.toTime;
        const defaultMinDateTime = formatDateToDefaultDateFormat(this.minDate) + 'T' + '00:00';

        this.hasError = false;
        await this.toastToBeforeFromDate?.dismiss();
        await this.toastFromBeforeMinDate?.dismiss();

        if (!compareDates(defaultFromDateTime, defaultToDateTime, CompareDateEnum.isSameOrBefore)) {
            this.hasError = true;
            this.toastToBeforeFromDate = await this.toastService.presentError(
                'Das Von-Datum muss vor dem Bis-Datum liegen.',
                900000
            );
        }

        if (this.minDate && !compareDates(defaultMinDateTime, defaultFromDateTime, CompareDateEnum.isSameOrBefore)){
            this.hasError = true;
            await this.toastFromBeforeMinDate?.dismiss();
            this.toastFromBeforeMinDate = await this.toastService.presentError(
                'Das Von-Datum muss nach dem ' + formatDateToDefaultDateFormat(this.minDate) + ' liegen.',
                900000
            );
        }

        return !this.hasError;
    }


    /**
     * closes the modal and returns the fromDate, toDate and the selected date range
     */
    async close() {

        // Set single date as selected
        if(this.dateRange && this.dateRange[0] && !this.dateRange[1]){
            this.dateRange[1] = dayjs(this.dateRange[0]).set('hour', 23).set('minute', 59).toDate();
            this.selectedDateRangeId = DateRangeOptionCodes.individual;
        }

        if (await this.checkDates()) {
            if (this.selectedDateRangeId === DateRangeOptionCodes.individual && !this.dateRange[0]) {
                return this.toastService.presentError(
                    'Bitte tragen Sie sowohl Von- als auch Bis-Datum ein oder wählen Sie einen anderen Schnellfilter aus.'
                );
            }


            await this.modalService.dismiss(
                {
                    fromDate: this.dateRange && dayjs(this.dateRange[0]).format(defaultDateFormat) || null,
                    toDate: this.dateRange && dayjs(this.dateRange[1]).format(defaultDateFormat) || null,
                    fromTime: this.fromTime,
                    toTime: this.toTime,
                    selectedDateRangeId: this.selectedDateRangeId
                }
            );
        }
    }


    selectContent(event: Event) {
        (event.target as HTMLInputElement).select();
    }

    /**
     * Sets individual date range and returns true if start and end date are set
     * @private
     */
    private setIndividualDateRange(): boolean {
        const [startDate, endDate] = this.dateRange || [null, null];
        if (startDate !== null && endDate !== null) {
            dayjs.extend(utc);
            // startDate and endDate contains the time in our current timezone - it has to be converted to utc before formatting
            const index = this.dateRangeOptions.findIndex(dateRange => dateRange.id === DateRangeOptionCodes.individual);
            this.dateRangeOptions[index].title = `Von ${formatDateTimeToDate(startDate)} bis ${formatDateTimeToDate(endDate)}`;
            this.selectedDateRangeId = DateRangeOptionCodes.individual;
            this.selectText = this.dateRangeOptions[index].title;
            return true;
        }
        return false;
    }

    /**
     * track by
     *
     * @param index - Index of the item in the array
     * @param item - The item to track
     */
    trackBy(index, item) {
        return item.id;
    }
}
