import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { setUser, startInactiveSpan } from '@sentry/angular';
import { SpanStatus } from '@sentry/types';
import { firstValueFrom } from 'rxjs';
import { mergeMap, switchMap, tap } from 'rxjs/operators';
import { BackendMessage } from '../../../../../../essentials/types/src/backendMessage';
import { RemoveCommonChatDuettFields } from '../../../../../../essentials/types/src/common-chat-duett-fields';
import ConversationType from '../../../../../../essentials/types/src/conversationType';
import { AddAttachmentToMessage } from '../../../../../../essentials/types/src/duett-schema/de.meineapotheke.chat/add_attachment/jsonschema/1-0-0';
import { AddProductToMessage } from '../../../../../../essentials/types/src/duett-schema/de.meineapotheke.chat/add_product/jsonschema/1-0-0';
import { AddVideoInvitationToMessage } from '../../../../../../essentials/types/src/duett-schema/de.meineapotheke.chat/invite_video/jsonschema/1-0-0';
import { MeadirektChatMessageSent2 } from '../../../../../../essentials/types/src/duett-schema/de.meineapotheke.chat/new_message/jsonschema/1-1-1';
import { CONFIG } from '../../../../../../essentials/types/src/mea-config';
import { isNotNullOrUndefined } from '../../../../../../essentials/util/src/rxjs/isNotNullOrUndefined';
import { newLocalMessage } from '../../../../../../store/src/common-store/chat-store/actions/chat-message.actions';
import { selectConversationForSegment } from '../../../../../../store/src/common-store/chat-store/selectors/chat.selectors';
import { CommonState } from '../../../../../../store/src/common-store/common.state';
import {
  analyticsCompletedResetKeyPair,
  analyticsDuettEvent,
  analyticsLoginUser,
  analyticsStartResetKeyPair,
  analyticsUserLoginComplete,
} from '../../../../../../store/src/common-store/other/actions/common-analytics.actions';
import { setUserOnLogin } from '../../../../../../store/src/common-store/user-store/actions/user.actions';
import { selectCognitoId } from '../../../../../../store/src/common-store/user-store/selectors/user.selectors';
import {
  finishAppointmentBooking,
  openAppointmentBookingModal,
} from '../../../../../../store/src/web/appointments/appointment.actions';
import { DuettAnalyticsService } from '../../../services/duett-analytics.service';
import { GoogleAnalyticsService } from '../../../services/google-analytics.service';

@Injectable()
export class CommonAnalyticsEffects {
  private actions$ = inject(Actions);
  private duettAnalyticsService = inject(DuettAnalyticsService);
  private googleAnalyticsService = inject(GoogleAnalyticsService);
  private store: Store<CommonState> = inject(Store);
  private config = inject(CONFIG);

  isPharmacy = !this.config.clientApp;

  userLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(analyticsLoginUser),
        switchMap(() => {
          const name = `${this.isPharmacy ? 'Apotheken' : 'Endkunden'} Anmeldung`;
          this.googleAnalyticsService.sendEvent('User Interaktion', name);
          const transaction = startInactiveSpan({ name });
          return this.actions$.pipe(
            ofType(analyticsUserLoginComplete),
            tap(() => transaction?.end())
          );
        })
      ),
    { dispatch: false }
  );

  resetKeyPair$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(analyticsStartResetKeyPair),
        switchMap(() => {
          const name = `${this.isPharmacy ? 'Apotheken' : 'Endkunden'} Reset Key Pair`;
          const transaction = startInactiveSpan({ name });
          return this.actions$.pipe(
            ofType(analyticsCompletedResetKeyPair),
            tap(() => {
              transaction?.end();
            })
          );
        })
      ),
    { dispatch: false }
  );

  setUserInSentry$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setUserOnLogin),
        tap(({ user }) => setUser({ id: user.cognitoId, username: user.cognitoUsername || user.chatname }))
      ),
    { dispatch: false }
  );

  sendDuettEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(analyticsDuettEvent),
        mergeMap(async ({ event }) => {
          const cognitoId = await firstValueFrom(this.store.select(selectCognitoId).pipe(isNotNullOrUndefined()));
          const baseEvent = this.duettAnalyticsService.getBaseEventData();
          const fullEvent = {
            ...event,
            ...baseEvent,
            cognito_id: cognitoId,
          };
          await this.duettAnalyticsService.trackEvent(fullEvent);
        })
      ),
    { dispatch: false }
  );

  openAppointmentsModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openAppointmentBookingModal),
        switchMap(async () => {
          const transaction = startInactiveSpan({ name: 'Analytics: Termin Buchung' });
          const { bookingSuccess } = await firstValueFrom(this.actions$.pipe(ofType(finishAppointmentBooking)));
          const status: SpanStatus = bookingSuccess ? { code: 1, message: 'ok' } : { code: 2, message: 'cancelled' };
          transaction?.setStatus(status);
          transaction?.end();
        })
      ),
    { dispatch: false }
  );

  newMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(newLocalMessage),
      mergeMap(async ({ message }: { message: BackendMessage }) => {
        const conversation = await firstValueFrom(
          this.store.select(selectConversationForSegment(message.conversationId)).pipe(isNotNullOrUndefined())
        );
        const event: RemoveCommonChatDuettFields<MeadirektChatMessageSent2> = {
          event_type: 'new_message',
          message_id: message.id,
          created_at: message.createdAt,
          type: message.type === ConversationType.GOOGLE_BUSINESS ? 'google_business' : 'meadirekt_chat',
          frontend_host: location.hostname,
          frontend_conversation_id: conversation.id,
          sender_id: message.senderId,
          recipient_id: message.recipientId,
          read_by_recipient: message.readByRecipient,
          automatic: message.automatic,
          display_as_pharmacy_message: message.displayAsPharmacyMessage,
          media_type: message.media?.[0]?.mediaType || null,
        };
        if (message.media && message.media[0]) {
          const mediaType = message.media[0].mediaType;
          if (mediaType === 'FILE' || mediaType === 'IMAGE') {
            this.trackAddAttachmentToMessageEvent(message.id, mediaType);
          } else if (mediaType === 'VIDEO_CALL') {
            this.trackAddVideoInvitationToMessageEvent(message);
          } else if (mediaType === 'SHOP_PRODUCT') {
            this.trackAddProductToMessageEvent(message);
          }
        }
        return analyticsDuettEvent({ event });
      })
    )
  );

  private trackAddAttachmentToMessageEvent(messageId: string, mediaType: 'FILE' | 'IMAGE') {
    const event: RemoveCommonChatDuettFields<AddAttachmentToMessage> = {
      event_type: 'add_attachment',
      message_id: messageId,
      attachment_type: mediaType,
    };
    this.store.dispatch(analyticsDuettEvent({ event }));
  }

  private trackAddVideoInvitationToMessageEvent(message: BackendMessage) {
    const event: RemoveCommonChatDuettFields<AddVideoInvitationToMessage> = {
      event_type: 'invite_video',
      message_id: message.id,
    };
    this.store.dispatch(analyticsDuettEvent({ event }));
  }

  private trackAddProductToMessageEvent(message: BackendMessage) {
    const event: RemoveCommonChatDuettFields<AddProductToMessage> = {
      event_type: 'add_product',
      message_id: message.id,
    };
    this.store.dispatch(analyticsDuettEvent({ event }));
  }
}
