import { ChangeDetectorRef, Component, effect, Input, Signal } from '@angular/core';

// Components
import { OrderCopyComponent } from '../order-copy/order-copy.component';
import { CommunicationZoneFormComponent } from '../../../../../communications/pages/communication-zone/widgets/communication-zone-form/communication-zone-form.component';

// Core Files
import {
    OrderAuditLogInterface,
    OrderDetailInterface,
    OrderAuditLogGroupInterface,
    TableSidebarInterface, NotesInterface
} from '../../../../../../core/core.interfaces';
import { ModalService, OrderService, PopoverService } from '../../../../../../core/core.services';
import { NoteWidgetComponent } from '../../../../../../widgets/note-widget/note-widget.component';
import {
    ButtonTypes,
    OrderStatus,
    OrderType,
    ModalClassEnum,
    NotesTypeEnum,
    TooltipColorSchemeEnum,
    CommunicationZoneFormEnum
} from '../../../../../../core/core.enums';
import { DocumentActionsComponent } from '../../../widgets/logistics.widgets';
import { environment } from '../../../../../../../environments/environment';
import { OrderTypesTranslationConfig } from '../../../../../../core/config/order-types.config';
import { GetAuditLogGroupTitle } from '../../../../../../core/config/audit-log.config';
import { formatDateTimeToDate, formatDateTimeToTime } from '../../../../../../core/formatting/date.formatting';
import {
    CommunicationZoneFormsSubstituteConfig,
    getOrderReplacementArray
} from '../../../../../../core/config/communication-zone-forms.config';


@Component({
    selector: 'app-order-view',
    templateUrl: './order-view.component.html',
    styleUrls: ['./order-view.component.scss'],
})
export class OrderViewComponent {
    // Adds class to modal
    static modalClass = ModalClassEnum.large;

    @Input({required: true}) details: Signal<OrderDetailInterface>;
    @Input({required: true}) sidebar: Signal<TableSidebarInterface>;
    @Input({required: true}) notesById: Signal<NotesInterface>;

    @Input() highlightChanges = false;

    // TODO - Remove after release of the functionality
    isProduction = environment.name === 'production';

    titleOrderType: string;
    buttonTypes = ButtonTypes;
    auditLogs: OrderAuditLogGroupInterface[];
    deliveryStatus = OrderStatus;
    isAcknowledgeAvailable = false;
    availableActions: {
        [ButtonTypes.ACCEPT]: boolean;
        [ButtonTypes.CANCEL]: boolean;
        [ButtonTypes.COPY]: boolean;
        [ButtonTypes.EXTEND]: boolean
        [ButtonTypes.SUPPORT]: boolean
    };
    tooltips: {
        [ButtonTypes.ACCEPT]: string;
        [ButtonTypes.CANCEL]: string;
        [ButtonTypes.COPY]: string;
        [ButtonTypes.EXTEND]: string
        [ButtonTypes.SUPPORT]: string
    };
    tooltipColorScheme: TooltipColorSchemeEnum;
    highlightedAuditLog: OrderAuditLogInterface;

    constructor(
        private popoverService: PopoverService,
        private orderService: OrderService,
        private modalService: ModalService,
        private cdr: ChangeDetectorRef
    ) {
        effect(() => {
            if(this.details()) {
                this.initOrderDetails();
            }
        });
    }

    initOrderDetails() {
        if (this.details()) {
            this.availableActions = {
                [ButtonTypes.ACCEPT]: this.orderService.isAcknowledgeEnabled(this.details()),
                [ButtonTypes.CANCEL]: this.orderService.isCancellationEnabled(this.details()),
                [ButtonTypes.COPY]: this.orderService.isCopyEnabled(this.details()),
                [ButtonTypes.EXTEND]: this.orderService.isExtensionEnabled(this.details()),
                [ButtonTypes.SUPPORT]: true
            };
            this.tooltips = {
                [ButtonTypes.ACCEPT]: this.orderService.acknowledgeButtonTooltip(this.details()),
                [ButtonTypes.CANCEL]: this.orderService.cancellationButtonTooltip(this.details()),
                [ButtonTypes.COPY]: this.orderService.copyButtonTooltip(this.details()),
                [ButtonTypes.EXTEND]: this.orderService.extensionButtonTooltip(this.details()),
                [ButtonTypes.SUPPORT]: 'order_support_button'
            };
            this.tooltipColorScheme = this.orderService.getTooltipColorScheme(this.details());
            this.isAcknowledgeAvailable = this.orderService.isAcknowledgeEnabled(this.details());
            this.titleOrderType = OrderTypesTranslationConfig[this.details()?.type] ? OrderTypesTranslationConfig[this.details().type] : '';
        }

        if (this.highlightChanges  && this.details()?.orderAuditLogs) {
            this.highlightedAuditLog = [
                this.orderService.getLastCancellationRequest(this.details()),
                this.orderService.getLastExtensionRequest(this.details()),
                this.orderService.getLastReorderRequest(this.details())
            ].sort((a, b) => (b ? b.id : 0) - (a ? a.id : 0))[0];
            if (!this.highlightedAuditLog) {
                this.highlightedAuditLog = this.details().orderAuditLogs[this.details().orderAuditLogs.length - 1];
            }
        } else {
            this.highlightedAuditLog = null;
        }

        this.groupAuditLog();
        this.cdr.detectChanges();
    }

    /**
     * Group audit log by groupTimestamp and sort them
     */
    groupAuditLog() {
       this.auditLogs = [...this.details().orderAuditLogs]
            .sort((a,b) => a.created_at > b.created_at ? -1 : 1)
            .reduce((result, currentObj) => {
                const payload = typeof currentObj.payload === 'string' ? JSON.parse(currentObj.payload) : currentObj.payload;
                const groupTimestamp = payload.groupTimestamp;
                const existingGroup = result.find(group => group ? group.groupTimestamp === groupTimestamp : false);
                if (existingGroup) {
                    existingGroup.items.push(currentObj);
                } else {
                    result.push({
                        groupTimestamp,
                        title: GetAuditLogGroupTitle(currentObj.text),
                        items: [currentObj]
                    });
                }

                return result;
            }, [])
            .sort((a,b) => a.groupTimestamp > b.groupTimestamp ? -1 : 1);
    }

    /**
     * Cancels the order
     */
    async onCancelButtonClick() {
        await this.orderService.presentCancelAlert(this.details());
    }

    /**
     * opens the copy modal
     */
    async onCopyButtonClick() {
        await this.modalService.dismiss();

        const modal = await this.modalService.create(OrderCopyComponent, {orderId: this.details().id});
        await this.modalService.present(modal);
    }

    /**
     * opens the copy modal
     */
    async onSupportButtonClick() {
        await this.modalService.dismiss();

        const modal = await this.modalService.create(
            CommunicationZoneFormComponent,
            {
                id: this.details().id,
                type: CommunicationZoneFormEnum.order,
                isReadOnly: true,
                replaceArray: getOrderReplacementArray(this.details())
            });
        await this.modalService.present(modal);
    }

    /**
     * Fires if extension button is clicked
     */
    async onExtensionButtonClick() {
        await this.orderService.presentExtensionAlert(this.details());
    }

    /**
     * Fires if acknowledge button is clicked
     */
    async onAcknowledgeDispoClick() {
        await this.orderService.presentAcknowledgeAlert(this.details());
    }

    /**
     * Opens modal for create/editing a note
     */
    async onRecordNoteClick() {
        const modal = await this.modalService.create(
            NoteWidgetComponent,
            {
                sourceId: this.details().id,
                noteType: NotesTypeEnum.order
            }
        );

        await this.modalService.present(modal);
    }

    /**
     * CLose modal when user clicks on PZN or Producer Company in sidebar
     */
    async onFilterLinkCLick() {
        await this.modalService.dismiss();
    }


    /**
     * User has pressed the more icon for deliveries. Show options for the selected delivery
     *
     * @param event - Event of the click
     * @param deliveryId - Id of the delivery
     */
    async presentDeliveryPopover(event: any, deliveryId: number) {
        if(!this.isProduction) {
            // TODO DON'T USE THIS FUNCTION LIKE THIS  - invoices and deliveries may have the same number
            const deliveryPopover = await this.popoverService.create({
                component: DocumentActionsComponent,
                componentProps: {
                    origDocumentId: deliveryId
                },
                event,
                translucent: true
            });
            return await this.popoverService.present(deliveryPopover);
        }
    }

    /**
     * track by
     *
     * @param index - Index of the item in the array
     * @param item - The item to track
     */
    trackBy(index, item) {
        return item.id;
    }
}

