import { gql } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import {
    AuthStorageKeyEnum,
    DateRangeOptionCodes,
    FetchPolicyKeys as FPK,
    DataChangedKeys as DCK,
    QueryFetchPolicy,
    SortDirectionEnum,
    TableFieldsEnum
} from '../../../core.enums';
import {
    NotesInterface,
    OrderDetailInterface,
    OrderFiltersInterface,
    OrderInterface,
    OrderSortInterface
} from '../../../core.interfaces';
import { OrderStatusFilterConfig } from '../../../config/order-status.config';
import { OrderTypesAllConfig } from '../../../config/order-types.config';
import { convertFromLocalTimeToUTC, endOf } from '../../../formatting/date.formatting';
import { checkForMinDate } from '../../../config/logistics.config';
import { getDateFromConfig, getDateRangesWithoutDates } from '../../../config/date-range.config';
import { GraphQLLimits } from '../../../config/graphql-limits.config';
import { defaultOrderSorting } from '../../../config/sorting.config';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';
import { QueryWrapper } from '../query.wrapper';

export const GetOrders = (queryName) => gql`
    query ${queryName}($apiUser: String!, $offset: Int, $limit: Int, $order_by: [order_order_by!], $where: order_bool_exp) {
        pharmacyStore: pharmacyStoreOrders(where: {apiUser: {_eq: $apiUser}}, limit: 1) {
            orders(offset: $offset, limit: $limit, order_by: $order_by, where: $where)  {
                id
                orderId
                productName
                packageSize
                producer
                pzn
                quantity
                status
                type
                expectedDelivery
                expiryDate
                cancellationReason
                cancellationRequest
                extendRequest
                reorderRequest
                dosageForm
                isNarcotic
                recTime
                created_at
                updated_at
                estimatedDeliveryDate
                estimatedDelivery
                price
                extraCosts
            }
        }
    }
`;

export const GetOrderById = (queryName) => gql`
    query ${queryName}($id: Int!) {
        order_by_pk(id: $id) {
            id
            orderIdOrg: orderId
            productName
            packageSize
            producer
            pzn
            quantity
            status
            type
            expectedDelivery
            expiryDate
            cancellationReason
            cancellationRequest
            extendRequest
            reorderRequest
            dosageForm
            isNarcotic
            recTime
            created_at
            updated_at
            estimatedDeliveryDate
            estimatedDelivery
            price
            extraCosts
            orderAuditLogs {
                id
                created_at
                text
                payload
            }
            orderDeliveries {
                deliveryId
                quantity
                expectedDelivery
                status
                deliveryNoteNumber
            }
            notes {
                note
            }
        }
    }
`;

export const GetPZNCollection = (queryName) => gql`
    query ${queryName}($apiUser: String!, $limit: Int, $search: [order_bool_exp!]) {
        pharmacyStore: pharmacyStoreOrders(where: {apiUser: {_eq: $apiUser}}, limit: 1) {
            orders(limit: $limit, distinct_on:[pzn], order_by: [{pzn: asc}],
                where: {_and:[{pzn: {_is_null: false}}, {pzn: {_neq: "null"}}, {_or: $search}]}) {
                pzn
            }
        }
    }
`;

export const GetOrdersCount = (queryName) => gql`
    query ${queryName}($where: order_bool_exp) {
        order_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;

export const GetProducerCollection = (queryName) => gql`
    query ${queryName}($apiUser: String!, $limit: Int, $search: String) {
        pharmacyStore: pharmacyStoreOrders(where: {apiUser: {_eq: $apiUser}}, limit: 1) {
            orders(limit: $limit, distinct_on:[producer], order_by: [{producer: asc}],
                where: {_and:[{producer: {_is_null: false}}, {producer: {_ilike: $search}}]}) {
                producer
            }
        }
    }
`;


export const GetOrderNotes = (queryName) => gql`
    query ${queryName}($orderId: Int, $limit: Int) {
        note(limit: $limit, where: {orderId: {_eq: $orderId}}) {
            id
            note
            created_at
            updated_at
        }
    }
`;

const GetOrderNotesByOrderId = (queryName) => gql`
    query ${queryName}($ids: [Int!], $limit: Int) {
        note(limit: $limit, where: {orderId: {_in: $ids}}) {
            id
            orderIdOrg: orderId
            note
            created_at
            updated_at
        }
    }`;

export const AllOrderQueries = [
    GetOrders('test'),
    GetOrderById('test'),
    GetPZNCollection('test'),
    GetOrdersCount('test'),
    GetProducerCollection('test'),
    GetOrderNotes('test'),
    GetOrderNotesByOrderId('test')
];

@Injectable()
export class OrderQueries extends QueryWrapper {

    fetchPolicies = {
        [FPK.getOrders]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getOrders2]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getOrdersCount]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getOrderById]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getPZNCollection]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getProducerCollection]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getOrderNotesByOrderIds]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getSorting]: QueryFetchPolicy.NETWORK_ONLY,
    };

    constructor(
        private apollo: Apollo,
        private dataChangedVar: DataChangedStateVar) {
        super(apollo, dataChangedVar, {
            [DCK.ordersChanged]: [
                FPK.getOrders,
                FPK.getOrders2,
                FPK.getOrdersCount,
                FPK.getOrderById,
                FPK.getPZNCollection,
                FPK.getProducerCollection
            ],
            [DCK.noteChanged]: [
                FPK.getOrderById,
                FPK.getOrderNotesByOrderIds
            ],
            [DCK.sortingChanged]: [
                FPK.getSorting,
            ],
        });
    }

    /**
     * Get list of orders depending on filters, offset and limit.
     * Results can be ordered.
     *
     * @param offset - Offset for pagination
     * @param limit - Limit for pagination
     * @param filters - Filters for orders
     * @param orderBy - Order by field and direction
     * @param isWidget
     */
    public getOrders(
        filters: OrderFiltersInterface,
        offset= 0,
        limit= 0,
        orderBy: OrderSortInterface = {
            field: defaultOrderSorting.fieldName,
            direction: defaultOrderSorting.sortDirection
        },
        isWidget = false
    ): Observable<OrderInterface[]> {

        const variables = {};
        variables['apiUser'] = localStorage.getItem(AuthStorageKeyEnum.activePharmacy);
        const orderByObj = {};
        if (!Object.values(TableFieldsEnum).includes(orderBy['field'])) {
            orderBy.field = defaultOrderSorting.fieldName;
            orderBy.direction = defaultOrderSorting.sortDirection;
        }
        switch (orderBy['field']) {
            case TableFieldsEnum.notes:
                orderByObj['notes_aggregate'] = {max: {id:
                    orderBy.direction === SortDirectionEnum.asc ? SortDirectionEnum.descNullsLast : SortDirectionEnum.ascNullsFirst
                }};
                break;
            default:
                orderByObj[orderBy['field']] = orderBy.direction;
                break;
        }
        variables['order_by'] = [orderByObj, {id: SortDirectionEnum.asc}];

        if(limit > GraphQLLimits.order) {
            limit = GraphQLLimits.order;
            console.error('Limit above maximum!');
        }

        if (limit > 0) {
            variables['limit'] = limit;
        }

        if (offset > 0) {
            variables['offset'] = offset;
        }

        variables['where'] = this.createFiltersWhere(filters);

        const fetchPolicyKey = isWidget ? FPK.getOrders : FPK.getOrders2;
        return this.apollo.watchQuery({
            query: GetOrders(fetchPolicyKey),
            variables,
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['pharmacyStore'] && d?.data['pharmacyStore'][0] &&
                d?.data['pharmacyStore'][0]['orders']))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<OrderInterface[]>;
    }


    /**
     * Return amount of available orders
     */
    public getOrdersCount(
        filters: OrderFiltersInterface,
        fetchPolicy: QueryFetchPolicy = null
    ): Observable<number> {
        const variables = {};

        variables['where'] = this.createFiltersWhere(filters);

        const fetchPolicyKey = FPK.getOrdersCount;
        return this.apollo.watchQuery({
                query: GetOrdersCount(fetchPolicyKey),
                variables,
                fetchPolicy: fetchPolicy ? fetchPolicy : this.getFetchPolicy(fetchPolicyKey)
            })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['order_aggregate']
              && d?.data['order_aggregate']['aggregate']
              && d?.data['order_aggregate']['aggregate']['count']))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<number>;
    }


    /**
     * Return a single order by id
     *
     * @param id - Order id
     */
    public getOrderById(id: number): Observable<OrderDetailInterface> {
        const fetchPolicyKey = FPK.getOrderById;
        return this.apollo.watchQuery({
                query: GetOrderById(fetchPolicyKey),
                variables: {id},
                fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
            })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['order_by_pk']))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<OrderDetailInterface>;
    }

    /**
     * Return an array of pzn
     */
    public getPZNCollection(searchString: string = ''): Observable<string[]> {
        // Fill the string with underscores and replace the 0s with empty string
        // for correct pzn search
        const searchQuery = [];
        if (searchString?.length > 0) {
            const searchStringFormatted = searchString.trim().replace(/^0+/g, '');
            searchQuery.push({pzn: {_ilike: searchStringFormatted + '%'}});
        } else {
            searchQuery.push({pzn: {_ilike:'%'}});
        }
        const fetchPolicyKey = FPK.getPZNCollection;
        return this.apollo.watchQuery({
                query: GetPZNCollection(fetchPolicyKey),
                variables: {limit: GraphQLLimits.order, search: searchQuery, apiUser: localStorage.getItem(AuthStorageKeyEnum.activePharmacy)},
                fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
            })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['pharmacyStore'] && d?.data['pharmacyStore'][0] &&
                d?.data['pharmacyStore'][0]['orders']))
            .pipe(map((orders: [{pzn: string;}]) =>
                (!orders ? [] : orders.map(order => order.pzn.padStart(8, '0')))
            ))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<string[]>;
    }

    /**
     * Return an array of producers
     */
    public getProducerCollection(searchString: string = ''): Observable<string[]> {
        const fetchPolicyKey = FPK.getProducerCollection;
        const producers : Observable<[{producer: string;}]> = this.apollo.watchQuery({
                query: GetProducerCollection(fetchPolicyKey),
                variables: {
                    limit: GraphQLLimits.order, search: `%${searchString ? searchString.trim() : ''}%`,
                    apiUser: localStorage.getItem(AuthStorageKeyEnum.activePharmacy)
                },
                fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
            })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['pharmacyStore'] && d?.data['pharmacyStore'][0] &&
                d?.data['pharmacyStore'][0]['orders']))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<[{producer: string;}]>;
        return producers.pipe(map((orders: [{producer: string;}]) => (
            !orders ? [] : orders.map(order => order.producer)))
        ) as Observable<string[]>;
    }

    /**
     * Return a note of the given order id
     */
    public getOrderNotesByOrderIds(ids: number[]): Observable<NotesInterface[]> {
        const fetchPolicyKey = FPK.getOrderNotesByOrderIds;
        return this.apollo.watchQuery({
            query: GetOrderNotesByOrderId(fetchPolicyKey),
            variables: {ids, limit: GraphQLLimits.note},
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
        })
        .valueChanges
        .pipe(map(d => d?.data && d?.data['note']))
        .pipe(map(d => d?.map(note => ({...note, orderId: note.orderIdOrg || note.orderId}))))
        .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;}))  as Observable<NotesInterface[]>;
    }

    /**
     * Create a where from the given order filters
     *
     * @param filters - Order filters
     */
    private createFiltersWhere(
        filters: OrderFiltersInterface
    ) {
        const where = {_and: []};

        const queryFilters = [];

        if (filters.search) {
            const searchNumber = parseInt(filters.search, 10);

            where['_and'].push({
                _or: [
                    {productName: {_ilike: `%${filters.search}%`}},
                    {producer: {_ilike: `%${filters.search}%`}},
                ]
            });

            if (!isNaN(searchNumber)) {
                where['_and'][0]['_or'].push(
                    {orderId: {_ilike: `%${searchNumber}%`}},
                    {pzn: {_ilike: `%${searchNumber}%`}}
                );
            }
        }
        if (filters.status && filters.status !== OrderStatusFilterConfig[0].id) {
            let filtersStatus = filters.status.split(',');
            const filterGroup = OrderStatusFilterConfig.find(filter => filters.status === filter.id);
            if (filterGroup) {
                filtersStatus = filterGroup.statusIds;
            }
            queryFilters.push({status: {_in: filtersStatus}});
        }

        if (filters.type && filters.type !== OrderTypesAllConfig.id) {
            queryFilters.push({type: {_eq: filters.type}});
        }

        if (filters.pzn) {
            queryFilters.push({pzn: {_ilike: parseInt(filters.pzn, 10).toString()}});
        }
        if (filters.producer) {
            queryFilters.push({producer: {_eq: filters.producer}});
        }
        /*
        TODO expectedDelivery is not date field!

        if (filters.deliveryDateFrom && filters.deliveryDateTo) {
            queryFilters.push({expectedDelivery: {_gte: filters.deliveryDateFrom}});
            queryFilters.push({expectedDelivery: {_lte: filters.deliveryDateTo}});
        }*/

        if (filters.expiryDateOption) {
            if (!getDateRangesWithoutDates(filters.expiryDateOption)
                && filters.expiryDateFrom && filters.expiryDateTo) {
                // NOT necessary to convert date to utc cause expiryDate is a date field
                queryFilters.push({expiryDate: {_gte: filters.expiryDateFrom}});
                queryFilters.push({expiryDate: {_lte: filters.expiryDateTo}});
            } else if (filters.expiryDateOption === DateRangeOptionCodes.unknown) {
                queryFilters.push({expiryDate: {_is_null: true}});
            } else if (!getDateRangesWithoutDates(filters.expiryDateOption)) {
                const fromDate = getDateFromConfig(filters.expiryDateOption, true);
                const toDate = getDateFromConfig(filters.expiryDateOption, false);
                if(fromDate && toDate) {
                    queryFilters.push({expiryDate: {_gte: fromDate}});
                    queryFilters.push({expiryDate: {_lte: toDate}});
                }
            }
        }

        if (filters.recTimeOption) {
            if (!getDateRangesWithoutDates(filters.recTimeOption)
                && filters.recTimeFrom && filters.recTimeTo) {
                // necessary to convert date to utc cause recTime is a datetime field
                queryFilters.push({recTime: {_gte: convertFromLocalTimeToUTC(checkForMinDate(filters.recTimeFrom))}});
                queryFilters.push({recTime: {_lte: convertFromLocalTimeToUTC(endOf('day', true, filters.recTimeTo))}});
            } else {
                const fromDate = convertFromLocalTimeToUTC(getDateFromConfig(filters.recTimeOption, true, true));
                if(fromDate) {
                    queryFilters.push({recTime: {_gte: fromDate}});
                }
            }
        }

        if (queryFilters.length > 0) {
            where['_and'].push({
                _and: queryFilters
            });
        }
        return where;
    }
}

