export enum DynamicPageContentTypeEnum {
    content = 'ComponentDynamicContentContentModule',
    news = 'ComponentDynamicContentNewsSlider',
    offers = 'ComponentDynamicContentOfferSlider',
    staticContent = 'ComponentDynamicContentStaticContent',
    headlineModule = 'ComponentDynamicContentHeadlineModule',
    downloads = 'ComponentDynamicContentDownloadContent'
}

export enum DynamicPageColorTheme {
    mea = 'mea',
    cooperationPartner = 'Kooperationspartner',
    cooperativeSociety = 'Genossenschaft',
    representativeMeeting = 'Vertreterversammlung',
    representativeInformation = 'Informationen',
    representativeFaq = 'Fragen und Antworten',
}

export enum DynamicContentColorTheme {
    mea = 'mea',
    sanacorp = 'Sanacorp',
}

export enum DynamicPageContentImagePositionEnum {
    left = 'links',
    right = 'rechts',
}

export enum DynamicPageContentTextAlignmentEnum {
    leftAligned = 'linksbündig',
    centered = 'zentriert',
}

export enum DynamicPageContentImageTypeEnum {
    fullscreen = 'Vollbild',
    imageWithTile = 'Bild_mit_Kachel',
}

export enum DynamicPageStaticContentTypeEnum {
    sanacorpFooter = 'sanacorpFooter',
}

export enum DynamicPageHeadlineBackgroundColorEnum {
    lilac = 'Flieder',
    pink = 'Rosa',
}
