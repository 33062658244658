import { Component, EventEmitter, Input, OnChanges, OnInit, Output, Signal, SimpleChanges } from '@angular/core';
import { Moment } from 'moment';
import { DateRangePickerComponent } from '../date-range-picker/date-range-picker.component';
import { formatDateTimeToDate, formatDateTimeToMoment, formatMomentDateToDefaultDateFormat, today } from '../../core.formatting';
import {
    DateEventInterface,
    DateRangeInterface,
    DateSelectionEventInterface,
    DateSelectionFilterInterface
} from '../../interfaces/date-range.interface';
import { DateRangeOptionCodes } from '../../enums/date-range.enum';
import { getDateFromConfig } from '../../config/date-range.config';
import { ModalService } from '../../services/modal.service';


@Component({
    selector: 'app-date-selection-widget',
    templateUrl: './date-selection-widget.component.html',
    styleUrls: ['./date-selection-widget.component.scss']
})
export class DateSelectionWidgetComponent implements OnInit, OnChanges {
    @Input() title: string;
    @Input() filterValue: DateSelectionFilterInterface;
    @Input() dateFilter: Signal<DateSelectionFilterInterface>;
    @Input() dateRangeOptions: Array<DateRangeInterface> = [];
    @Input() selectedDateRangeId: DateRangeOptionCodes;
    @Input() minDate: Date;
    @Input() maxDate: Date;
    @Input() defaultFromDate: Moment;
    @Input() defaultToDate: Moment;
    @Input() defaultFromTime: string;
    @Input() defaultToTime: string;
    @Input() displayDatesInsteadOfDateRange = false;
    @Input() displayButtonInsteadOfDateRange = false;
    @Input() displayTime = false;
    @Input() displayOnlyFutureDates = false;
    @Input() displayOnlyPastDates = false;
    @Input() displayIncludingToday = true;
    @Input() readonly = false;
    @Input() useReactiveFilters = false;
    @Output() datesEvent: EventEmitter<DateEventInterface> = new EventEmitter();
    /**
     * This event is used for the tables that use the declarative filters with rxjs.
     */
    @Output() datesEventNew: EventEmitter<DateSelectionEventInterface> = new EventEmitter();

    selectedDateRange: DateRangeInterface;

    fromDate = '';
    toDate = '';
    fromTime = '00:00';
    toTime = '23:59';
    individualDateRange = DateRangeOptionCodes.individual;
    fromDateEqualsToDate = false;


    constructor(
        private modalService: ModalService
    ) {}

    ngOnInit() {
        this.init();

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.title ||
            changes.dateRangeOptions ||
            changes.selectedDateRange ||
            changes.selectedDateRangeId ||
            changes.defaultFromDate ||
            changes.defaultToDate ||
            changes.defaultFromTime ||
            changes.defaultToTime ||
            changes.readonly
        ) {
            if (!this.useReactiveFilters) {
                this.init();
            }
        }
    }

    init() {
        if (!this.useReactiveFilters) {
            // finds the data range option for the given id
            this.selectedDateRange = this.dateRangeOptions.find(dateRange => dateRange.id === this.selectedDateRangeId);

            if (this.selectedDateRange) {
                if (this.selectedDateRange.id !== this.individualDateRange) {
                    // gets the dates by the selected date range
                    this.fromDate = getDateFromConfig(this.selectedDateRange.id, true);
                    this.toDate = getDateFromConfig(this.selectedDateRange.id, false);
                } else {
                    this.fromDate = this.defaultFromDate ? formatMomentDateToDefaultDateFormat(this.defaultFromDate) : today();
                    this.toDate = this.defaultToDate ? formatMomentDateToDefaultDateFormat(this.defaultToDate) : today();
                    this.fromTime = this.defaultFromTime;
                    this.toTime = this.defaultToTime;
                }
            }
            this.fromDateEqualsToDate = false;
            if (this.fromDate && this.toDate) {
                this.fromDateEqualsToDate = formatDateTimeToDate(this.fromDate) === formatDateTimeToDate(this.toDate);
            }
        }
    }

    /**
     * opens the date range picker modal window
     */
    async openDatePickerRange() {
        if (!this.readonly) {
            const dateRangePicker = await this.modalService.create(
                DateRangePickerComponent,
                {
                    title: this.title,
                    dateRangeOptions: this.dateRangeOptions,
                    selectedDateRangeId: this.selectedDateRangeId,
                    minDate: this.minDate,
                    maxDate: this.maxDate,
                    fromDate: this.fromDate,
                    toDate: this.toDate,
                    fromTime: this.fromTime,
                    toTime: this.toTime,
                    displayTime: this.displayTime,
                    displayOnlyFutureDates: this.displayOnlyFutureDates,
                    displayOnlyPastDates: this.displayOnlyPastDates,
                    displayOnlyToday: this.displayIncludingToday
                }
            );

            await this.modalService.present(dateRangePicker);

            // after closing the modal, the return values are processed
            dateRangePicker.onDidDismiss().then((result) => {
                if (result['data']) {
                    this.selectedDateRangeId = result['data'].selectedDateRangeId;
                    this.defaultFromDate = formatDateTimeToMoment(result['data'].fromDate, false);
                    this.defaultToDate = formatDateTimeToMoment(result['data'].toDate, false);
                    this.defaultFromTime = result['data'].fromTime;
                    this.defaultToTime = result['data'].toTime;
                    this.datesEvent.emit({
                        fromDate: formatDateTimeToMoment(result['data'].fromDate, false),
                        toDate: formatDateTimeToMoment(result['data'].toDate, false),
                        fromTime: result['data'].fromTime,
                        toTime: result['data'].toTime,
                        dateRangeId: this.selectedDateRangeId
                    });
                    this.datesEventNew.emit({
                        dateOption: this.selectedDateRangeId,
                        dateFrom: result['data'].fromDate,
                        dateTo: result['data'].toDate,
                        dateRangeOptions: this.dateRangeOptions,
                    });
                    this.init();
                }
            });
        }
    }

    /**
     * Use this function to open the date range picker, when the reactive filters are used.
     * @param dateFilter DateSelectionFilterInterface
     */
    async openDateRangePicker(dateFilter: DateSelectionFilterInterface) {
        if(this.readonly) {
            return; // do nothing
        }
        const dateRangePicker = await this.modalService.create(
            DateRangePickerComponent,
            {
                title:  this.title ?? dateFilter.label,
                dateRangeOptions: dateFilter.selectedValues.dateRangeOptions,
                selectedDateRangeId: dateFilter.selectedValues.dateOption,
                minDate: this.minDate,
                maxDate: this.maxDate,
                fromDate: dateFilter.selectedValues.dateFrom,
                toDate: dateFilter.selectedValues.dateTo
            }
        );

        await this.modalService.present(dateRangePicker);

        dateRangePicker.onDidDismiss().then((result) => {
            if (result['data']) {
                this.datesEventNew.emit({
                    dateOption: result['data'].selectedDateRangeId,
                    dateRangeOptions: dateFilter.selectedValues.dateRangeOptions,
                    dateFrom: result['data'].fromDate,
                    dateTo: result['data'].toDate,
                });
            }
        });
    }
}
