export enum matomoIdsEnum {
    logout= 'matomo_user_logout',                                      // logout button
    toolbarNotification = 'matomo_toolbar_notification',               // Benachrichtigungsbutton in Toobar
    orderCancelCssClass = 'matomo_css_order_cancel',                   // Orders Stornieren Ok Button
    orderCopy = 'matomo_order_copy',                                   // Orders Nachbestellen Absenden Button
    orderExtensionCssClass = 'matomo_css_order_extension',             // Orders Verlängern Ok Button
    orderAcceptCssClass = 'matomo_css_order_accept',                   // Orders Ware angekommen Ok Button
    contactSupport = 'matomo_contact_support',                         // Kundenservice kontaktieren Button
    ordersNote = 'matomo_orders_note',                                 // Orders interne Notiz Speichern Button
    ordersDispoRequest = 'matomo_dispo_request',                       // Orders Dispositionsanfrage Anfrage senden Button
    seminarRegistration = 'matomo_seminar_registration',               // Seminar Registrierung bestätigen Button
    seminarWeb = 'matomo_seminar_web',                                 // Seminar im Web öffnen Button
    calendarAppointment = 'matomo_calendar_appointment',               // Kalender Termin speichern Button
    meamindAskQuestion = 'matomo_meamind_ask_question',                // Meamind Experten Fragen, Frage veröffentlichen Button
    logisticsActionDelivery = 'matomo_logistics_action_delivery',      // Logistik -> Lieferung -> Drucken, Download
    logisticsActionBatch = 'matomo_logistics_action_batch',            // Logistik -> Chargenbeleg -> Drucken, Download
    logisticsActionInvoice = 'matomo_logistics_action_invoice',        // Logistik -> Rechung -> Drucken, Download
    meaDownload = 'matomo_mea_download',                               // Mea -> Download -> Drucken, Download
    returnsRequest = 'matomo_returns_action_request',                  // Toolbar -> Retoure anmelden -> Senden
    statisticsDownload = 'matomo_statistics_download',                 // Statistik -> Download -> Drucken, Download
    termsAndConditions = 'matomo_terms_and_conditions',                // AGB -> Bestätigen
    santaGame = 'matomo_santa_game',                                   // Santa Game in Toolbar
    easterGame = 'matomo_easter_game',                                 // Easter Game in Toolbar
    halloweenGame = 'matomo_halloween_game'                           // Halloween Game in Toolbar
}
