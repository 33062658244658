<ng-container *ngIf="news">
    <div class="details-container">
        <div class="scroll-container">
            <div class="ion-margin-bottom ion-text-center"
                 *ngIf="news.image && ((news.image.url | getImageUrl) || (news.image.toString()| getImageUrl)) ">
                <img src="{{news.image.url}}" [alt]="news.subtitle"
                     class="image-max-height"
                     [class.img-is-logo]="news.imageType === ImageTypes.LOGO" />
            </div>

            <ng-container *ngIf="news.date">
                <ion-text class="label ion-margin-top" i18n>Datum</ion-text>
                <ion-text class="value" i18n [innerHTML]="news.date | formatDateTimeRange:news.date:1"></ion-text>
                <ion-text *ngIf="news.subtitle" class="h2 ion-margin-bottom display-block">{{news.subtitle}}</ion-text>

                <ng-container *ngIf="news.attachments && news.attachments.length > 0">
                    <ion-text class="label" i18n>Downloads</ion-text>
                    <a  *ngFor="let attachment of news.attachments"
                        [href]="attachment.url | getImageUrl"
                        class="display-flex ion-margin-bottom"
                        target="_blank">{{attachment.name}}</a>
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-container>
