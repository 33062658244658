import { NavigationEnum } from '../enums/navigation.enum';

/**
 * get the navigation label by NavigationEnum
 */
export const NavigationLabel = {
    [NavigationEnum.dashboard]: 'Startseite',
    [NavigationEnum.meaChat]: 'mea Chat', // TODO remove with feature flag
    [NavigationEnum.mea]: 'mea',
    [NavigationEnum.logistics]: 'Warenlogistik',
    [NavigationEnum.offers]: 'Angebote',
    [NavigationEnum.seminars]: 'Seminare',
    [NavigationEnum.calendar]: 'Kalender',
    [NavigationEnum.statistics]: 'Statistiken',
    [NavigationEnum.representatives]: 'Vertreter',
    [NavigationEnum.knowledge]: 'Pharma Wissen',
    [NavigationEnum.settings]: 'Einstellungen',
    [NavigationEnum.releaseNotes]: 'Was gibt\'s Neues?',
    [NavigationEnum.releaseHistory]: 'Versionshistorie',
    [NavigationEnum.information]: 'Hilfe',
    [NavigationEnum.news]: 'Neuigkeiten',
    [NavigationEnum.sanavendi]: 'SanaVendi',
    [NavigationEnum.communications]: 'Kommunikation',
};
