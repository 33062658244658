import { DocumentSubTypeInterface } from '../interfaces/document.interface';
import { DocumentSubType } from '../enums/documents.enum';

export const InvoicesTypeFilterConfig: DocumentSubTypeInterface[] = [
    {
        id: DocumentSubType.ALL,
        color: '',
        title: 'Alle',
        titlePlural: 'Alle',
        shortcode: 'Alle',
        sortOrder: 1
    },
    {
        id: DocumentSubType.InvoiceMonthly,
        color: 'invoices-type-collective',
        title: 'Monatsrechnung',
        titlePlural: 'Monatsrechnungen',
        shortcode: 'Monatsrechnung',
        sortOrder: 2
    },
    {
        id: DocumentSubType.InvoicePartial,
        color: 'invoices-type-partial',
        title: 'Teilrechnung',
        titlePlural: 'Teilrechnungen',
        shortcode: 'Teilrechnung',
        sortOrder: 3
    },
    {
        id: DocumentSubType.SB,
        color: 'invoices-type-confirmationOfBalance',
        title: 'Saldenbestätigung',
        titlePlural: 'Saldenbestätigungen',
        shortcode: 'Saldenbestätigung',
        sortOrder: 4
    }
];

export const InvoiceTypesConfig: DocumentSubTypeInterface[] = [
  {
      id: DocumentSubType.E_SR,
      color: 'invoices-type-collective',
      title: 'Monatsrechnung',
      titlePlural: 'Monatsrechnungen',
      shortcode: 'Monatsrechnung',
      sortOrder: 2
  },
  {
      id: DocumentSubType.E_TR,
      color: 'invoices-type-partial',
      title: 'Teilrechnung',
      titlePlural: 'Teilrechnungen',
      shortcode: 'Teilrechnung',
      sortOrder: 3
  },
  {
      id: DocumentSubType.DRTR,
      color: 'invoices-type-partial',
      title: 'Teilrechnung',
      titlePlural: 'Teilrechnungen',
      shortcode: 'Teilrechnung',
      sortOrder: 4
  },
  {
      id: DocumentSubType.NOKA,
      color: 'invoices-type-collective',
      title: 'Monatsrechnung',
      titlePlural: 'Monatsrechnungen',
      shortcode: 'Monatsrechnung',
      sortOrder: 5
  },
  {
      id: DocumentSubType.SB,
      color: 'invoices-type-confirmationOfBalance',
      title: 'Saldenbestätigung',
      titlePlural: 'Saldenbestätigungen',
      shortcode: 'Saldenbestätigung',
      sortOrder: 6
  }
];
