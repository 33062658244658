import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import update from 'immutability-helper';
import { BehaviorSubject, firstValueFrom, map, Observable } from 'rxjs';
import { Dictionary } from 'ts-essentials';
import { CONFIG, MeaConfig } from '../../../../../essentials/types/src/mea-config';
import { PharmacyImageType } from '../../../../../essentials/types/src/pharmacyImageType';
import { Logger } from '../../../../../essentials/util/src/logger';
import { CommonState } from '../../../../../store/src/common-store/common.state';
import { selectCognitoId } from '../../../../../store/src/common-store/user-store/selectors/user.selectors';
import deletePharmacyImage from '../../graphql/mutations/deletePharmacyImage';
import setPharmacyImage from '../../graphql/mutations/setPharmacyImage';
import getPharmacyImage from '../../graphql/queries/getPharmacyImage';
import { StorageService } from '../storage.service';
import { AppsyncService } from './appsync.service';

const logger = new Logger('AppsyncPharmacyPhotoService');

const LOGOS_STORAGE_KEY = 'storedPharmacyLogos';
const STREET_VIEWS_STORAGE_KEY = 'storedPharmacyStreetViews';

interface PharmacyLogoData {
  logo: string;
  fileId?: string;
}

interface PharmacyStreetViewData {
  streetView?: string;
  fileId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppsyncPharmacyPhotoService {
  private cachedPharmacyLogos$ = new BehaviorSubject<Dictionary<PharmacyLogoData>>({});
  private cachedPharmacyStreetViews$ = new BehaviorSubject<Dictionary<PharmacyStreetViewData>>({});
  private readonly imageBaseUrl: string;
  private readonly defaultLogoIcon: string;

  constructor(
    @Inject(CONFIG) private config: MeaConfig,
    private appsync: AppsyncService,
    private storage: StorageService,
    private store: Store<CommonState>
  ) {
    this.imageBaseUrl = config.bucket.aws_image_base_url ?? '';
    this.defaultLogoIcon = 'assets/imgs/logo-icon.png';
  }

  getPharmacyBannerUrl(pharmacyId: string): string {
    return `${this.imageBaseUrl}/${pharmacyId}_titelbild.jpg`;
  }

  getLoadedPharmacyLogo(cognitoId: string): Observable<PharmacyLogoData | undefined> {
    return this.cachedPharmacyLogos$.pipe(map((cachedPharmacyLogos) => cachedPharmacyLogos[cognitoId]));
  }

  getLoadedPharmacyStreetView(cognitoId: string): Observable<PharmacyStreetViewData | undefined> {
    return this.cachedPharmacyStreetViews$.pipe(
      map((cachedPharmacyStreetViews) => cachedPharmacyStreetViews[cognitoId])
    );
  }

  async getStoredPharmacyLogo(cognitoId: string): Promise<PharmacyLogoData | undefined> {
    return (await this.storage.get(LOGOS_STORAGE_KEY))?.[cognitoId];
  }

  async getStoredPharmacyStreetView(cognitoId: string): Promise<PharmacyStreetViewData | undefined> {
    return (await this.storage.get(STREET_VIEWS_STORAGE_KEY))?.[cognitoId];
  }

  // ************* Queries *************

  async loadPharmacyLogo(cognitoId: string, sanacorpCustomerId?: string): Promise<void> {
    let pharmacyLogo: PharmacyLogoData | undefined;
    const client = await this.appsync.getClient();
    try {
      const { data } = await client.query({
        query: getPharmacyImage,
        variables: { cognitoId, imageType: PharmacyImageType.Logo, sanacorpCustomerId },
      });
      if (data.getPharmacyImage) {
        pharmacyLogo = { logo: data.getPharmacyImage.image, fileId: data.getPharmacyImage.fileId };
      }
    } catch (e) {
      logger.error('error retrieving pharmacy logo', e);
    }
    if (!pharmacyLogo?.logo) {
      pharmacyLogo = { logo: this.defaultLogoIcon };
    }
    await this.updateCachedLogo(cognitoId, pharmacyLogo);
  }

  async loadPharmacyStreetView(cognitoId: string, sanacorpCustomerId?: string): Promise<void> {
    let pharmacyStreetView: PharmacyStreetViewData | undefined;
    const client = await this.appsync.getClient();
    try {
      const { data } = await client.query({
        query: getPharmacyImage,
        variables: { cognitoId, imageType: PharmacyImageType.StreetView, sanacorpCustomerId },
      });
      if (data.getPharmacyImage) {
        pharmacyStreetView = { streetView: data.getPharmacyImage.image, fileId: data.getPharmacyImage.fileId };
      }
    } catch (e) {
      logger.error('error retrieving pharmacy street view', e);
    }
    if (!pharmacyStreetView?.streetView) {
      pharmacyStreetView = {};
    }
    await this.updateCachedStreetView(cognitoId, pharmacyStreetView);
  }

  // ************* Mutations *************

  async setPharmacyLogo(base64content: string, cognitoId: string): Promise<boolean> {
    const oldFileId = await firstValueFrom(
      this.cachedPharmacyLogos$.pipe(map((cachedPharmacyLogos) => cachedPharmacyLogos[cognitoId]?.fileId))
    );
    const client = await this.appsync.getClient();
    try {
      const { data } = await client.mutate({
        mutation: setPharmacyImage,
        variables: { imageType: PharmacyImageType.Logo, base64content, oldFileId },
      });
      const response = data?.setPharmacyImage as
        | {
            fileId: string;
            image: string;
          }
        | undefined;
      if (response) {
        await this.updateCachedLogo(cognitoId, { logo: response.image, fileId: response.fileId });
        return true;
      }
    } catch (e) {
      logger.error('error setting pharmacy logo', e);
    }
    return false;
  }

  async setPharmacyStreetView(base64content: string, cognitoId: string): Promise<boolean> {
    const oldFileId = await firstValueFrom(
      this.cachedPharmacyStreetViews$.pipe(
        map((cachedPharmacyStreetViews) => cachedPharmacyStreetViews[cognitoId]?.fileId)
      )
    );
    const client = await this.appsync.getClient();
    try {
      const { data } = await client.mutate({
        mutation: setPharmacyImage,
        variables: { imageType: PharmacyImageType.StreetView, base64content, oldFileId },
      });
      const response = data?.setPharmacyImage as
        | {
            fileId: string;
            image: string;
          }
        | undefined;
      if (response) {
        await this.updateCachedStreetView(cognitoId, { streetView: response.image, fileId: response.fileId });
        return true;
      }
    } catch (e) {
      logger.error('error setting pharmacy street view', e);
    }
    return false;
  }

  async deletePharmacyLogo(): Promise<boolean> {
    const client = await this.appsync.getClient();
    try {
      await client.mutate({ mutation: deletePharmacyImage, variables: { imageType: PharmacyImageType.Logo } });
      const cognitoId = await firstValueFrom(this.store.select(selectCognitoId));
      if (!cognitoId) {
        return false;
      }
      await this.updateCachedLogo(cognitoId, { logo: this.defaultLogoIcon });
      return true;
    } catch (e) {
      logger.error('error deleting pharmacy logo', e);
      return false;
    }
  }

  async deletePharmacyStreetView(): Promise<boolean> {
    const client = await this.appsync.getClient();
    try {
      await client.mutate({ mutation: deletePharmacyImage, variables: { imageType: PharmacyImageType.StreetView } });
      const cognitoId = await firstValueFrom(this.store.select(selectCognitoId));
      if (!cognitoId) {
        return false;
      }
      await this.updateCachedStreetView(cognitoId, {});
      return true;
    } catch (e) {
      logger.error('error deleting pharmacy street view', e);
      return false;
    }
  }

  // ************* Utility *************

  private async updateCachedLogo(cognitoId: string, updatedLogo: PharmacyLogoData): Promise<void> {
    const currentlyCachedLogos = await firstValueFrom(this.cachedPharmacyLogos$);
    const updatedCachedLogos = update(currentlyCachedLogos, {
      [cognitoId]: { $set: updatedLogo },
    });
    this.cachedPharmacyLogos$.next(updatedCachedLogos);
    await this.storage.set(LOGOS_STORAGE_KEY, updatedCachedLogos);
  }

  private async updateCachedStreetView(cognitoId: string, updatedStreetView: PharmacyStreetViewData): Promise<void> {
    const currentlyCachedStreetViews = await firstValueFrom(this.cachedPharmacyStreetViews$);
    const updatedCachedStreetViews = update(currentlyCachedStreetViews, {
      [cognitoId]: { $set: updatedStreetView },
    });
    this.cachedPharmacyStreetViews$.next(updatedCachedStreetViews);
    await this.storage.set(STREET_VIEWS_STORAGE_KEY, updatedCachedStreetViews);
  }
}
