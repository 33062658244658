import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { SafetyDatasheetQueries } from '../store/graphql/queries/safety-datasheet.graphql';
import { SafetyDatasheetMutations } from '../store/graphql/mutations/safety-datasheet.graphql';

@Injectable({
    providedIn: 'root',
})
export class SafetyDatasheetsService {
    private safetyDatasheetsQueries = inject(SafetyDatasheetQueries);
    private safetyDatasheetsMutations = inject(SafetyDatasheetMutations);


    private _safetyDataSheets$ = this.safetyDatasheetsQueries.getSafetyDataSheets();
    safetyDataSheets = toSignal(this._safetyDataSheets$);

    /**
     * Update the search string for the PZN list
     *
     * @param search - The search string
     */
    executePZNSearch(search: string) {
        this.safetyDatasheetsMutations.requestSafetyDataSheetPdf(search);
    }
}
