import { computed, effect, inject, Injectable, signal, untracked } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AlertController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import dayjs from 'dayjs';
import { MeaChatSconnectService } from 'mea-chat-sconnect-libs';
import { MeaChatConversationInterface, SconnectConversation } from '../interfaces/mea-chat.interface';
import { LoginStatus, MeaChatType } from '../enums/mea.enum';
import { PharmacyStoreStateVar } from '../store/locals/pharmacyStoreState.var';
import { AuthStorageKeyEnum } from '../enums/authStorageKey.enum';
import { ToastService } from './toast.service';
import { unsubscribe } from '../util/subscriptions.util';
import { environment as meaChatEnvironment } from '../../../environments/mea-chat-sconnect/environments/environment';
import { ProfileSettingsVar } from '../store/locals/profileSettings.var';
import { UserAccessRightsEnum } from '../enums/user-administration.enum';

/**
 * get the chat customer id from apiUser
 *
 * @param apiUser
 */
export const getChatCustomerId = (apiUser: string) => {
    return apiUser === '70188' ? 'demo-app' : apiUser;
};

/**
 * check if customer has chat role
 * @param roles
 * @param apiUser
 */
export const hasChatRole = (roles: string[], apiUser: string) => {
    return roles?.includes(
        getChatCustomerId(apiUser) + '_chat_' + UserAccessRightsEnum.PHARMACY_OWNER
    ) ||
    roles?.includes(
        getChatCustomerId(apiUser) + '_chat_' + UserAccessRightsEnum.PHARMACY_MEMBER
    );
};

@Injectable({
    providedIn: 'root'
})
export class MeaChatService {
    private pharmacyStoreStateVar = inject(PharmacyStoreStateVar);
    private profileSettingsVar = inject(ProfileSettingsVar);
    private meaChatSconnectService = inject(MeaChatSconnectService);
    private toastService = inject(ToastService);
    private alertController = inject(AlertController);

    private _profileSettings = this.profileSettingsVar.profileSettings;
    public hasChat = computed<boolean>(() => {
        return this.pharmacyStoreStateVar.hasChat()
            && hasChatRole(this._profileSettings()?.user?.chatRoles, this.pharmacyStoreStateVar.apiUser());
    });

    private _isPromptOpen = signal(false);
    /**
     * user is set in store after successful login to mea chat, triggers loading of conversations
     * @private
     */
    public isUserLoggedIn = toSignal(this.meaChatSconnectService.userIsSet$);

    private _isLogInLoading = signal(true);
    public isLogInLoading = this._isLogInLoading.asReadonly();
    private _isConversationLoading = signal(true);
    public isConversationLoading = this._isConversationLoading.asReadonly();

    private _endUserSubscription: Subscription;
    private _endUserConversations = signal<MeaChatConversationInterface[]>([]);
    private _pharmacyConversations = signal<MeaChatConversationInterface[]>([]);
    private _conversations = signal<MeaChatConversationInterface[]>([]);
    public conversations = this._conversations.asReadonly();

    private _unreadConversations = signal<MeaChatConversationInterface[]>([]);
    public unreadConversations = this._unreadConversations.asReadonly();
    private _unreadMessageCount = signal<number>(null);
    public unreadMessageCount = this._unreadMessageCount.asReadonly();

    private _conversationType = signal<MeaChatType>(MeaChatType.ENDUSER);

    /**
     * ID (apiUser) of the active pharmacy
     * @private
     */
    private _customerId = signal<string>(null);

    public meaChatUrl = `${meaChatEnvironment.meaChatUrl}/home/uebersicht`

    constructor() {
        effect(() => {
            const conversationType = this._conversationType();
            untracked(() => {
               this.loadConversations();
            });
        });

        effect(() => {
            const endUserConversations = this._endUserConversations();
            const pharmacyConversations = this._pharmacyConversations();

            untracked(() => {
                if (this._conversationType() === MeaChatType.ENDUSER) {
                    this._conversations.set(endUserConversations);
                } else if (this._conversationType() === MeaChatType.PHARMACY) {
                    this._conversations.set(pharmacyConversations);
                }
            });
        });

        /**
         * Filter for conversations that contain unread messages
         */
        effect(() => {
            const conversations = this._endUserConversations();
            const unreadConversations = conversations.filter(conversation => conversation.unreadMessagesCount > 0);
            untracked(() => {
                this._unreadConversations.set(unreadConversations);
                let count = 0;
                this._unreadConversations().forEach(conversation => {
                    count += conversation.unreadMessagesCount;
                });
                this._unreadMessageCount.set(count);
            });
        });
    }

    public async loginToMeaChat(apiUser: string = null) {
        this._isLogInLoading.set(true);
        if (apiUser) {
            this._customerId.set(getChatCustomerId(apiUser));
        }
        if (!this._customerId()) {
            return;
        }
        const customerId = this._customerId();
        const token = `${localStorage.getItem(AuthStorageKeyEnum.accessToken)}`;
        const loginStatus = await this.meaChatSconnectService.loginToMeaChat(customerId, token);
        await this.handleLoginStatus(loginStatus);
    }

    public setConversationType(type: MeaChatType) {
        this._conversationType.set(type);
    }

    public redirectToMeaChatConversation(conversationId: string) {
        const url = this.meaChatSconnectService.getMeaChatRedirectUrl(this._customerId(), conversationId);
        window.open(url, '_blank');
    }

    public async promptRestorePassword(header: string, message: string) {
        if (this._isPromptOpen() === true) {
            return;
        }
        this._isPromptOpen.set(true);
        const alert = await this.alertController.create({
            header,
            message,
            backdropDismiss: false,
            inputs: [
                {
                    name: 'password',
                    type: 'password',
                    placeholder: 'Sicherheitsschlüssel',
                }
            ],
            buttons: [
                {
                    text: 'Abbrechen',
                    role: 'cancel'
                },
                {
                    text: 'Ok',
                    handler: async data => {
                        if (data?.password?.length) {
                            const restoreStatus = await this.meaChatSconnectService.restorePrivateKey(data.password);
                            this._isPromptOpen.set(false);
                            void this.handleLoginStatus(restoreStatus);
                            return true;
                        }
                        return false;
                    }
                }
            ]
        });

        alert.onDidDismiss().then(() => {
            this._isLogInLoading.set(false);
            this._isPromptOpen.set(false);
        });

        await alert.present();
    }

    public logoutFromMeaChat() {
        void this.meaChatSconnectService.logoutFromMeaChat();
    }

    private async handleLoginStatus(loginStatus: LoginStatus) {
        switch (loginStatus) {
            case LoginStatus.Success:
                this._isLogInLoading.set(false);
                this.loadConversations();
                break;
            case LoginStatus.LoginError:
                void this.toastService.presentError('Während des Login beim mea Chat ist leider ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.', null);
                break;
            case LoginStatus.BackupPrivateKey:
                // private key needs to be created in chat FE
                await this.showRedirectAlert();
                break;
            case LoginStatus.RestorePrivateKey:
                // private key needs to be sent to chat FE
                await this.promptRestorePassword('Sicherheitsschlüssel für Ihren mea Chat erforderlich', 'Ab heute steht Ihnen eine neue ' +
                    'Integration des mea Chats zur Verfügung.<br />Aus Sicherheitsgründen geben Sie bitte einmalig Ihren mea Chat Sicherheitsschlüssel ein, ' +
                    'um Sanacorp Connect mit Ihrem Chat zu verknüpfen.');
                break;
            case LoginStatus.RestorePrivateKeyError:
                // wrong restored private key
                await this.showRedirectAlert();
                break;
            case LoginStatus.RestorePrivateKeyPasswordError:
                void this.toastService.presentError('Der eingegebene Sicherheitsschlüssel ist leider falsch. Bitte versuchen Sie es erneut.', 16000);
                await this.promptRestorePassword('Falscher Sicherheitsschlüssel', 'Der eingegebene Sicherheitsschlüssel ist leider falsch. Bitte versuchen Sie es erneut.');
                break;
        }
    }

    private async showRedirectAlert() {
        const alert = await this.alertController.create({
            header: 'Fehlerhafter Sicherheitsschlüssel',
            message: 'Der von Ihnen verwendete Sicherheitsschlüssel ist inkorrekt. Bitte überprüfen Sie diesen in Ihrem mea Chat Account.',
            backdropDismiss: false,
            buttons: [
                {
                    text: 'mea Chat öffnen',
                    handler: () => {
                        window.open(this.meaChatUrl, '_blank');
                    },
                    role: 'destructive'
                }, {
                    text: 'Abbrechen',
                    role: 'cancel',
                }
            ]
        });

        alert.onDidDismiss().then(() => {
            this._isLogInLoading.set(false);
            this._isPromptOpen.set(false);
        });

        await alert.present();
    }

    private loadConversations() {
        switch (this._conversationType()) {
            case MeaChatType.ENDUSER:
                this.loadEndUserConversations();
                break;
            case MeaChatType.PHARMACY:
                this.loadPharmacyConversations();
                break;
        }
    }

    private loadEndUserConversations() {
        this._isConversationLoading.set(true);
        unsubscribe(this._endUserSubscription);
        this.meaChatSconnectService.openEnduserConversations$.subscribe(conversations => {
            if (!conversations) {
                return;
            }
            const mapped = this.mapConversations(conversations);
            this._endUserConversations.set(mapped);
            this._isConversationLoading.set(false);
        });
    }

    private loadPharmacyConversations() {
        this._isConversationLoading.set(true);
        unsubscribe(this._endUserSubscription);
        this.meaChatSconnectService.pharmacyConversations$.subscribe(conversations => {
            if (!conversations) {
                return;
            }
            const mapped = this.mapConversations(conversations);
            this._pharmacyConversations.set(mapped);
            this._isConversationLoading.set(false);
        });
    }

    private mapConversations(conversations: SconnectConversation[]): MeaChatConversationInterface[] {
        return  conversations.map(conversation => ({
            id: conversation.id,
            isOpen: conversation.isConversationOpen,
            time: dayjs(conversation.lastMessageTimestamp * 1000).format('DD.MM. HH:mm'),
            unreadMessagesCount: conversation.unreadMessagesCount,
            chatPartnerName: conversation.chatPartnerName,
            isDecryptingChatPartnerName: conversation.isDecryptingChatPartnerName,
            intent: conversation?.intent || null
        }));
    }
}
