<div class="sidebar" [class.standalone-content]="!hasHeader && !hasFooter">
    <div class="sidebar-header" *ngIf="hasHeader && data()?.hasHeader !== false">
        <div class="label" *ngIf="data()?.subTitle">{{data().subTitle}}</div>
        <div class="headline" *ngIf="data()?.title">{{data().title}}</div>
        <div class="action-popover-button">
            <ion-icon id="{{id}}-action" name="ellipsis-vertical" [class.clickable]="!bulkModeActive()" [class.disabled]="bulkModeActive()" size="large"/>
        </div>
        <ion-popover trigger="{{id}}-action" dismissOnSelect="true" *ngIf="!bulkModeActive()">
            <ng-template>
                <ng-container *ngTemplateOutlet="actionsPopoverRef"/>
                <ion-list lines="full" class="ion-no-padding">
                    <ion-item *ngFor="let actionItem of data().actionPopoverItems"
                              button="true"
                              [class.disabled]="actionItem.isDisabled"
                              (click)="!actionItem.isDisabled && actionItem?.onAction()"
                              appTooltip
                              ttk="{{actionItem?.tooltipKey}}"
                              ttp="left">
                        <ion-icon *ngIf="actionItem?.icon" slot="start" [name]="actionItem.icon"></ion-icon>
                        {{actionItem.label}}
                    </ion-item>
                    <ion-item button="true" (click)="onCloseSidebar()" i18n>Schließen</ion-item>
                </ion-list>
            </ng-template>
        </ion-popover>
    </div>
    <div class="sidebar-content">
        <div class="content-item"
             [class.clickable]="item?.onAction"
             [style.--col-width]="item?.colWidth"
             *ngFor="let item of data()?.sidebarItems | filterVisibleTableItems"
             (click)="item?.onAction && item?.onAction()">
                <div class="label" *ngIf="item?.label && item?.value">{{item.label}}
                    <ion-icon name="information-circle-outline" *ngIf="item.ttKey" appTooltip [ttk]="item.ttKey" ttp="top" class="widget-info"></ion-icon>
                </div>
                @if(!item.badgeType && !item.isPreview) {
                    <div class="value" *ngIf="item?.value">{{item.value}}</div>

                } @else if(item?.badgeType === BadgeTypeEnum.MEA_DOWNLOADS_MULTIPLE) {
                    <ion-text class="multiple-badges">
                    @for(downloadType of item.value; track downloadType.id) {
                        <app-badge [code]="downloadType.type" [badgeType]="BadgeTypeEnum.MEA_DOWNLOADS" [badgeTypeInfo]="downloadType.color"></app-badge>
                    }
                    </ion-text>

                } @else if(item?.badgeType) {
                    <app-badge [badgeType]="item.badgeType" [code]="item.value"></app-badge>
                } @else if(item.isPreview) {
                    <app-download-preview
                            [title]="item.label"
                            [filePreviewUrl]="item.value | filePreviewImage: 'jpg'"
                            [fileExtension]="(item.value | lastPartOfString : '.')"
                            [color]="item.previewColor"
                            (click)="item.previewAction()"
                    ></app-download-preview>
                }

        </div>
    </div>
    <div class="sidebar-footer" *ngIf="hasFooter && data()?.hasFooter !== false">
        <ion-icon class="clickable" name="arrow-back" (click)="data().previousRow()"></ion-icon>
        <ion-button *ngIf="hasEditButton  && data()?.hasEditButton !== false"
                    id="{{id}}-sidebar-details-modal"
                    class="with-shadow"
                    [disabled]="bulkModeActive()"
                    i18n>Bearbeiten</ion-button>
        <ion-icon class="clickable" name="arrow-forward" (click)="data().nextRow()"></ion-icon>
    </div>
</div>

<ion-modal *ngIf="hasFooter && data()?.hasFooter !== false" trigger="{{id}}-sidebar-details-modal" class="modal-large">
    <ng-template>
        <ng-container *ngTemplateOutlet="detailsModalRef"></ng-container>
    </ng-template>
</ion-modal>
