<app-modal i18n-title title="Ihre Frage">
    <ng-container headerActions></ng-container>
    <ng-container content>
        <div class="ion-padding form-container">
            <form class="form" [formGroup]="validationFormGroup">
                <ion-item class="background-input" appTooltip ttp="bottom" ttk="meamind_question_title">
                        <ion-input
                            i18n-label
                            type="text" 
                            formControlName="title" 
                            label="Frage"
                            class="required"
                            [ngClass]="{
                                'invalid': validationFormGroup.controls.title?.dirty | formInputIsInvalid : validationFormGroup.controls.title?.valid,
                            }"
                            [placeholder]="tooltips.meamind_question_title"
                        ></ion-input>
                        <ion-icon 
                            name="alert-circle"
                            *ngIf="validationFormGroup.controls.title?.dirty | formInputIsInvalid : validationFormGroup.controls.title?.valid"
                            color="primary"
                            appTooltip
                            [tt]="validationFormGroup.controls.title | formInputErrorMessage : validationFormGroup.controls.title.value"
                            ttp="right">
                        </ion-icon>
                </ion-item>
                <ion-item class="background-input wysiwyg" appTooltip ttp="bottom" ttk="{{!validationFormGroup.controls.content?.value ? 'meamind_question_description' : null}}">
                    <ion-label slot="start" i18n>Beschreibung <span class="required">*</span></ion-label>
                    <div class="ion-quill-editor-container large" [class.invalid]="validationFormGroup.controls.content?.dirty | formInputIsInvalid : validationFormGroup.controls.content?.valid">
                        <quill-editor aria-label="Beschreibung" formControlName="content" [placeholder]="tooltips.meamind_question_description"></quill-editor>
                        <ion-icon name="alert-circle"
                                  *ngIf="validationFormGroup.controls.content?.dirty | formInputIsInvalid : validationFormGroup.controls.content?.valid"
                                  appTooltip
                                  [tt]="validationFormGroup.controls.content | formInputErrorMessage : validationFormGroup.controls.content.value"
                                  ttp="right">
                        </ion-icon>
                    </div>
                </ion-item>
                <ion-item class="background-input item-input" appTooltip ttp="bottom" ttk="meamind_question_tags" *ngIf="!question">
                    <app-meamind-tags-field
                            [questionTitle]="questionTitle"
                            [questionDescription]="questionContent"
                            [prefilledTags]="question?.tags"
                            [editable]="question ? false : true"
                            formControlName="tags"
                    ></app-meamind-tags-field>
                </ion-item>
            </form>
            <div class="required-fields"><span class="required">*</span> Benötigte Felder</div>
        </div>
        <app-meamind-similar-questions [questionTitle]="questionTitle" [@suggestionsVisible]="areSuggestionsVisible ? 'visible' : 'hidden'"></app-meamind-similar-questions>
    </ng-container>
    <!-- Footer -->
    <ng-container footer>
        <div>
            <ion-button
                    id="{{matomoId}}"
                    [class.disabled]="!validationFormGroup.valid"
                    appTooltip
                    ttk="{{!validationFormGroup.valid ? 'meamind_question_invalid' : 'meamind_question_publish'}}"
                    ttp="left"
                    (click)="validationFormGroup.valid ? onSubmitMeamindQuestion(validationFormGroup.value) : null"
                    i18n
            >{{question ? 'Frage anpassen' : 'Frage veröffentlichen'}}</ion-button>
        </div>
    </ng-container>
</app-modal>
