import { gql } from '@apollo/client/core';
import { inject, Injectable, Signal } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { from, map } from 'rxjs';
import * as moment from 'moment';
import { ApiService } from '../../../services/api.service';
import { formatMomentDateToDefaultDateFormat } from '../../../formatting/date.formatting';
import { DataChangedKeys as DCK } from '../../../core.enums';
import { DataChangedForceStateVar } from '../../locals/dataChangeForceState.var';
import { ProfileSettingsVar } from '../../locals/profileSettings.var';
import { ProfileSettingInterface } from '../../../core.interfaces';


export const CancelOrderMutation = gql`
    mutation cancelOrderMutation($orderId: ID!, $reason: String!) {
        CancelOrder(orderId: $orderId, reason: $reason) {
            status
            message
        }
    }
`;

export const DispoReOrderMutation = gql`
    mutation dispoReOrderMutation($orderId: ID!, $qty: Int!, $latestExpected: Date!) {
        DispoReOrder(orderId: $orderId, qty: $qty, latestExpected: $latestExpected) {
            status
            message
        }
    }
`;

export const DispoEnquiryMutation = gql`
    mutation dispoEnquiryMutation($contents: String!, $productName: String, $contactPerson: String!) {
        DispoEnquiry(contents: $contents, productName: $productName, contactPerson: $contactPerson) {
            status
            message
        }
    }
`;

export const AdditionalExtendMutation = gql`
    mutation additionalExtendMutation($orderId: ID!, $extendByWeeks: Int!) {
        AdditionalExtend(orderId: $orderId, extendByWeeks: $extendByWeeks) {
            status
            message
        }
    }
`;

export const DispoAcknowledgeExtendMutation = gql`
    mutation dispoAcknowledgeMutation($orderId: ID!) {
        DispoAcknowledge(orderId: $orderId) {
            status
            message
        }
    }
`;

export const AllOrdersMutations = [
    CancelOrderMutation,
    DispoReOrderMutation,
    DispoEnquiryMutation,
    AdditionalExtendMutation,
    DispoAcknowledgeExtendMutation,
];

@Injectable()
export class OrdersMutations {
    private apollo = inject(Apollo);
    private apiService = inject(ApiService);
    private profileSettingsVar = inject(ProfileSettingsVar);
    private dataChangedForceState = inject(DataChangedForceStateVar);

    profileSettings: Signal<ProfileSettingInterface> = this.profileSettingsVar.profileSettings;

    /**
     * trim values
     * @param data - Data to trim (string or null)
     * @return string|null
     */
    formatInputs = (data) => {
        if (typeof data === 'string') {
            return data.trim().length > 0 ? data.trim() : null;
        }
        return data;
    }

    cancelOrder(orderId: number, reason: string) {
        void this.dataChangedForceState.setForceState({[DCK.ordersChanged]: null});
        this.apollo.mutate({
            mutation: CancelOrderMutation,
            variables: {orderId, reason: this.formatInputs(reason)}
        }).subscribe({
            next: result => {
                from([result])
                  .pipe(map(d => d?.data && d?.data['CancelOrder'] && d?.data['CancelOrder']['status']))
                  .subscribe((status: string) => {
                      if (!status || status === 'ERROR' || !!result['errors']) {
                          void this.apiService.presentErrorToast(result['errors'], 'Die Bestellung kann nicht storniert werden.');
                      } else {
                          void this.apiService.presentSuccessToast('Die Stornierung wurde erfolgreich beantragt.');
                      }
                  }).unsubscribe();
            },
            error: error => this.apiService.presentErrorToast(error, 'Die Bestellung kann nicht storniert werden.')
        });
    }

    dispoReOrder(orderId: number, qty: number) {
        void this.dataChangedForceState.setForceState({[DCK.ordersChanged]: null});
        this.apollo.mutate({
            mutation: DispoReOrderMutation,
            variables: {
                orderId,
                qty,
                latestExpected: formatMomentDateToDefaultDateFormat(moment().add(1, 'months'))
            }
        }).subscribe({
            next: result => {
                from([result])
                  .pipe(map(d => d?.data && d?.data['DispoReOrder'] && d?.data['DispoReOrder']['status']))
                  .subscribe((status: string) => {
                      if (!status || status === 'ERROR' || !!result['errors']) {
                          void this.apiService.presentErrorToast(result['errors'], 'Eine Nachbestellung konnte nicht beauftragt werden.');
                      } else {
                          void this.apiService.presentSuccessToast('Die Nachbestellung wurde beauftragt.');
                      }
                  }).unsubscribe();
            },
            error: error => this.apiService.presentErrorToast(error, 'Eine Nachbestellung konnte nicht beauftragt werden.')
        });
    }


    dispoEnquiry(contents: string, productName: string = null, contactPerson: string = null) {
        let email = this.profileSettings().user.email || '';
        const maxValidCharacter = 120;
        const remainingCharacter = maxValidCharacter - contactPerson.length - 3;
        if(email.length > remainingCharacter){
            email = email.substring(0, remainingCharacter);
        }
        void this.dataChangedForceState.setForceState({[DCK.ordersChanged]: null});
        this.apollo.mutate({
            mutation: DispoEnquiryMutation,
            variables: {
                contents: this.formatInputs(contents),
                productName: this.formatInputs(productName),
                contactPerson: this.formatInputs(contactPerson + ' - ' + email)
            }
        }).subscribe({
            next: result => {
                from([result])
                    .pipe(map(d => d?.data && d?.data['DispoEnquiry'] && d?.data['DispoEnquiry']['status']))
                    .subscribe((status: string) => {
                        if (!status || status === 'ERROR' || !!result['errors']) {
                            void this.apiService.presentErrorToast(result['errors'], 'Die Dispoanfrage konnte nicht gespeichert werden.');
                        } else {
                            void this.apiService.presentSuccessToast('Die Anfrage wurde erfolgreich an Ihr Kundenservice-Team versendet. Alle Anfragen sind im Bereich "Kommunikation" einsehbar.');
                        }
                    });
            },
            error: error => this.apiService.presentErrorToast(error, 'Die Dispoanfrage konnte nicht gespeichert werden.')
        });
    }

     additionalExtend(orderId: number, extendByWeeks: number) {
        void this.dataChangedForceState.setForceState({[DCK.ordersChanged]: null});
        this.apollo.mutate({
            mutation: AdditionalExtendMutation,
            variables: {orderId, extendByWeeks}
        }).subscribe({
            next: result => {
                from([result])
                  .pipe(map(d => d?.data && d?.data['AdditionalExtend'] && d?.data['AdditionalExtend']['status']))
                  .subscribe((status: string) => {
                      if (!status || status === 'ERROR' || !!result['errors']) {
                          void this.apiService.presentErrorToast(result['errors'], 'Die Verlängerung konnte nicht gespeichert werden.');
                      } else {
                          void this.apiService.presentSuccessToast('Die Verlängerung um ' + extendByWeeks +
                            ' Wochen wurde erfolgreich beantragt.');
                      }
                  });
            },
            error: error => this.apiService.presentErrorToast(error, 'Die Verlängerung konnte nicht gespeichert werden.')
        });
    }

    dispoAcknowledge(orderId) {
        void this.dataChangedForceState.setForceState({[DCK.ordersChanged]: null});
        this.apollo.mutate({
            mutation: DispoAcknowledgeExtendMutation,
            variables: {orderId}
        }).subscribe({
            next: result => {
                from([result])
                  .pipe(map(d => d?.data && d?.data['DispoAcknowledge'] && d?.data['DispoAcknowledge']['status']))
                  .subscribe((status: string) => {
                      if (!status || status === 'ERROR' || !!result['errors']) {
                          void this.apiService.presentErrorToast(result['errors'], 'Beim Markieren der Bestellung ist ein Fehler aufgetreten.');
                      } else {
                          void this.apiService.presentSuccessToast('Die Bestellung wurde als angekommen markiert.');
                      }
                  });
            },
            error: error => this.apiService.presentErrorToast(error, 'Beim Markieren der Bestellung ist ein Fehler aufgetreten.')
        });
    }
}
