import { NotificationsEnum, NotificationTypeEnum } from '../enums/notifications.enum';

export const NotificationTypeLabelConfig = {
    [NotificationsEnum.REMINDER_APPOINTMENT]: 'Termin-Erinnerung',
    [NotificationsEnum.REMINDER_SEMINAR_REGISTRATION]: 'Seminar-Registrierungs-Erinnerung',
    [NotificationsEnum.ORDER_ADDITIONAL_DEADLINE]: 'Auftrag läuft ab',
    [NotificationsEnum.ORDER_DISPO_DIRECT_OUT_OF_DATE]: 'Ausstehende Warenlieferung',
    [NotificationsEnum.NEW_MEACHATMESSAGE]: 'Neue mea Chat Nachricht',
    [NotificationsEnum.ORDER_STATUS_ENROUTE]: 'Auftrag an Lieferanten übergeben',
    [NotificationsEnum.ORDER_STATUS_CANCELLED]: 'Auftrag storniert',
    [NotificationsEnum.NEW_RELEASE_NOTE]: 'Neuer Versionshinweis',
    [NotificationsEnum.NEW_STATIC_PAGE]: 'Änderung an einer Seite',
    [NotificationsEnum.NEW_NEWS]: 'Neue News',
    [NotificationsEnum.NEW_IMPORTANT_NEWS]: 'Neue wichtige News',
    [NotificationsEnum.ORDER_QUANTITY_CHANGE]: 'Menge im Auftrag geändert',
    [NotificationsEnum.TOUR_NOTIFICATION]: 'Neue Tourbenachrichtigung',
    [NotificationsEnum.NEW_MAINTENANCE]: 'Wartungsarbeiten',
    [NotificationsEnum.NEW_USER_DATA_POLICY]: 'Nutzungsbedingungen',
    [NotificationsEnum.NEW_USER_WELCOME]: 'Willkommen in Sanacorp Connect',
    [NotificationsEnum.RETURNS_PRODUCT_MISSING]: 'Artikel fehlt in Retourenwanne',
    [NotificationsEnum.RETURNS_STOCK_ERROR]: 'Keine Lieferung, wegen Lagerfehler',
    [NotificationsEnum.NEW_OFFER]: 'Neue {{appArea}} Artikelliste mit Bestelloption',
    [NotificationsEnum.NEW_DOCUMENT]: 'Neue Rechnung',
    [NotificationsEnum.ORDER_CANCEL_ERROR]: 'Auftrag konnte nicht storniert werden',
    [NotificationsEnum.ORDER_EXTEND_ERROR]: 'Auftrag konnte nicht verlängert werden',
    [NotificationsEnum.COOPERATION_REVENUE_CURRENT_YEAR]: 'Information zu Kooperationsumsatz',
    [NotificationsEnum.SALES_VOLUME_WITHOUT_HIGH_PRICED]: 'Information zu Gesamtumsatz',
    [NotificationsEnum.MEAMIND_NEW_QUESTION]: 'Neue Frage in Meamind',
    [NotificationsEnum.MEAMIND_NEW_ANSWER]: 'Neue Antwort in Meamind',
    [NotificationsEnum.MEAMIND_NEW_COMMENT]: 'Neuer Kommentar in Meamind',
    [NotificationsEnum.MEAMIND_UPDATE_QUESTION]: 'Frage in Meamind aktualisiert',
    [NotificationsEnum.MEAMIND_UPDATE_ANSWER]: 'Antwort in Meamind aktualisiert',
    [NotificationsEnum.MEAMIND_UPDATE_COMMENT]: 'Kommentar in Meamind aktualisiert',
    [NotificationsEnum.MEASHOP_NEW_ORDER]: 'Neue Bestellung in Ihrem Mea-Shop',
    [NotificationsEnum.NEW_SURVEY]: 'Neue Umfrage',
    [NotificationsEnum.NEW_ASSORTMENT_STATISTIC]: 'Neue Bezugsstatistik',
    [NotificationsEnum.NEW_ARCHIVE]: 'Neue Archivdatei',
};

export const NotificationTypeConfig = (type) => {
    if(type === 'all') {
        return NotificationTypes.find(t => t.id === 'all');
    }

    const notificationType = NotificationTypes.find(nt => nt.group.indexOf(type) > -1);
    if(!notificationType) {
        return NotificationTypes.find(t => t.id === NotificationTypeEnum.INFO);
    }
    return notificationType;
};


export const NotificationTypes = [
    { id: 'all',                                color: 'notification-badge-all',            value: 'Alle',      group: [] },
    { id: NotificationTypeEnum.ORDERS,          color: 'notification-badge-order',          value: 'Auftrag',   group: [NotificationsEnum.ORDER_ADDITIONAL_DEADLINE, NotificationsEnum.ORDER_DISPO_DIRECT_OUT_OF_DATE, NotificationsEnum.ORDER_STATUS_ENROUTE, NotificationsEnum.ORDER_STATUS_CANCELLED, NotificationsEnum.ORDER_QUANTITY_CHANGE, NotificationsEnum.TOUR_NOTIFICATION, NotificationsEnum.RETURNS_PRODUCT_MISSING, NotificationsEnum.RETURNS_STOCK_ERROR, NotificationsEnum.ORDER_CANCEL_ERROR, NotificationsEnum.ORDER_EXTEND_ERROR] },
    { id: NotificationTypeEnum.OFFERS,          color: 'notification-badge-offer',          value: 'Angebot',   group: [NotificationsEnum.NEW_OFFER] },
    { id: NotificationTypeEnum.NEWS,            color: 'notification-badge-news',           value: 'News',      group: [NotificationsEnum.NEW_NEWS, NotificationsEnum.NEW_IMPORTANT_NEWS] },
    { id: NotificationTypeEnum.DOCUMENTS,       color: 'notification-badge-invoice',        value: 'Rechnung',  group: [NotificationsEnum.NEW_DOCUMENT] },
    { id: NotificationTypeEnum.STATISTICS,      color: 'notification-badge-statistics',     value: 'Statistik', group: [NotificationsEnum.NEW_ASSORTMENT_STATISTIC, NotificationsEnum.COOPERATION_REVENUE_CURRENT_YEAR, NotificationsEnum.SALES_VOLUME_WITHOUT_HIGH_PRICED] },
    { id: NotificationTypeEnum.MEASHOP,         color: 'notification-badge-meashop',        value: 'Mea-Shop',  group: [NotificationsEnum.MEASHOP_NEW_ORDER] },
  //  { id: NotificationTypeEnum.MEACHAT,         color: 'notification-badge-meadirect',      value: 'MeaDirect', group: [NotificationsEnum.NEW_MEACHATMESSAGE] },
    { id: NotificationTypeEnum.MEAMIND,         color: 'notification-badge-meamind',        value: 'Meamind',   group: [NotificationsEnum.MEAMIND_NEW_ANSWER, NotificationsEnum.MEAMIND_NEW_QUESTION, NotificationsEnum.MEAMIND_NEW_COMMENT, NotificationsEnum.MEAMIND_UPDATE_ANSWER, NotificationsEnum.MEAMIND_UPDATE_QUESTION, NotificationsEnum.MEAMIND_UPDATE_COMMENT] },
    { id: NotificationTypeEnum.SURVEY,          color: 'notification-badge-survey',         value: 'Umfrage',   group: [NotificationsEnum.NEW_SURVEY] },
    { id: NotificationTypeEnum.INFO,            color: 'notification-badge-common',         value: 'Info',      group: [NotificationsEnum.NEW_RELEASE_NOTE,NotificationsEnum.NEW_STATIC_PAGE,NotificationsEnum.NEW_MAINTENANCE,NotificationsEnum.NEW_USER_DATA_POLICY,NotificationsEnum.NEW_USER_WELCOME,NotificationsEnum.NEW_ARCHIVE] },
    { id: NotificationTypeEnum.APPOINTMENTS,    color: 'notification-badge-appointment',    value: 'Termin',    group: [NotificationsEnum.REMINDER_APPOINTMENT] },
];
