<app-modal [title]="filterConfig?.title" [contentPadding]="false">
    <ng-container headerActions></ng-container>
    <ng-container content>
        <div class="wrapper">
            <div class="content">
                <ng-container *ngIf="filterConfig?.items">
                    <ng-container *ngFor="let filter of filterConfig.items">
                        <ng-container [ngSwitch]="filter.type">
                            <ng-container *ngSwitchCase="filterTypes.search">
                                <ion-searchbar mode="md"
                                               [(ngModel)]="search"
                                               (keyup.enter)="applyFilterAndSearch()"
                                               searchIcon="search-outline"
                                               clearIcon="close-outline"
                                               i18n-cancelButtonText
                                               cancelButtonText="Abbrechen"
                                               i18n-placeholder placeholder="Suche (PZN, Hersteller, Name ...) "
                                               autocomplete="off"
                                               autocorrect="off"></ion-searchbar>
                            </ng-container>
                            <ng-container *ngSwitchCase="filterTypes.searchField">
                                <div>
                                    <ion-searchbar mode="md"
                                                   [(ngModel)]="search"
                                                   (keyup.enter)="applyFilterAndSearch()"
                                                   class="ion-no-padding form-search-field"
                                                   searchIcon="search-outline"
                                                   clearIcon="close-outline"
                                                   i18n-cancelButtonText
                                                   cancelButtonText="Abbrechen"
                                                   i18n-placeholder placeholder="Freitextsuche (PZN, Artikelname) "
                                                   autocomplete="off"
                                                   autocorrect="off"></ion-searchbar>
                                    <div class="searchbar-hint ion-padding-top-half">
                                        <ion-text i18n>Bei zusammengesetzten Namen Sucheingabe in Anführungszeichen setzen (z. B. "L-Thyroxin")</ion-text>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="filterTypes.date">
                                <app-date-selection-widget
                                        *ngIf="filter.type === filterTypes.date"
                                        [title]="filter?.label"
                                        [minDate]="filter.dataKey !== 'expiryDate' ? minDate : null"
                                        [dateRangeOptions]="filter.dateSignal().selectedValues.dateRangeOptions"
                                        [selectedDateRangeId]="filter.dateSignal().selectedValues.dateOption"
                                        [defaultFromDate]="filter.dateSignal().selectedValues.dateFrom | formatDateTimeToMoment"
                                        [defaultToDate]="filter.dateSignal().selectedValues.dateTo | formatDateTimeToMoment"
                                        [displayOnlyFutureDates]="false"
                                        [displayOnlyPastDates]="true"
                                        [displayIncludingToday]="true"
                                        (datesEventNew)="onDateChange($event, filter.dataKey)"
                                ></app-date-selection-widget>
                            </ng-container>
                            <ng-container *ngSwitchCase="filterTypes.trueFalse">
                                <div class="toggle-wrapper">
                                    <ion-label i18n class="filter-label">{{filter.label}}</ion-label>
                                    <ion-toggle mode="ios" [checked]="filters()[filter.dataKey]" (ionChange)="onBoolFilterChange($event, filter.dataKey)"></ion-toggle>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="filterTypes.select">
                                <div>
                                    <div class="filter" id="search-modal-filter-trigger-{{filter.dataKey}}">
                                        <div class="filter-label" i18n>{{filter.label}}</div>
                                        <div class="filter-text" i18n>
                                            @if(filter.selectableValues) {
                                                {{(filter.selectableValues | findAttr:filtersWritable()?.[filter.dataKey]:'id': 'title')}}
                                            } @else {
                                                {{(filter.selectableSignal() | findAttr:filtersWritable()?.[filter.dataKey]:'id': 'title')}}
                                            }
                                            <ion-icon name="caret-down-sharp"></ion-icon>
                                        </div>
                                    </div>
                                    <ion-popover trigger="search-modal-filter-trigger-{{filter.dataKey}}" dismissOnSelect="true">
                                        <ng-template>
                                            @if(filter.selectableValues) {
                                                <app-select-popover [options]="filter.selectableValues" (itemClicked)="onSelectFilterChange($event, filter.dataKey)"/>
                                            } @else {
                                                <app-select-popover [options]="filter.selectableSignal()" (itemClicked)="onSelectFilterChange($event, filter.dataKey)"/>
                                            }
                                        </ng-template>
                                    </ion-popover>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="filterTypes.multiSelect">
                                <div class="filter" id="search-modal-filter-trigger-{{filter.dataKey}}">
                                    <div class="filter-label" i18n>{{filter.label}}</div>
                                    <div class="filter-text" i18n>
                                        {{filter | filterResultValue:filtersWritable()?.[filter.dataKey] }}
                                        <ion-icon name="caret-down-sharp"></ion-icon>
                                    </div>
                                </div>
                                <ion-popover trigger="search-modal-filter-trigger-{{filter.dataKey}}" dismissOnSelect="false">
                                    <ng-template>
                                        <app-select-popover
                                                [useCheckboxes]="true"
                                                [options]="filter.selectableValues | filterExcluded"
                                                [selected]="filters()[filter.dataKey]"
                                                (itemClicked)="onSelectFilterChange($event, filter.dataKey)"/>
                                    </ng-template>
                                </ion-popover>
                            </ng-container>
                            <ng-container *ngSwitchCase="filterTypes.inputSelect">
                                <app-input-popover [data]="filter.selectableSignal"
                                                   [search]="filter.secondarySignal"
                                                   [dataTransform]="filter?.payload?.deliveryNumberTransform"
                                                   [placeholder]="'Suche nach ' + filter.label"
                                                   maxHeightDropdown="350px"
                                                   [label]="filter.label" i18n-label
                                                   (onItemSelected)="onSelectFilterChange($event, filter.dataKey)"
                                                   (searchChange)="filter.setSecondarySignal($event)"
                                />
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
            <div class="search-modal-sidebar">
                <ng-content select="search-modal-sidebar"></ng-content>
            </div>
        </div>
    </ng-container>
    <ng-container footer>
        <div class="display-flex ion-justify-content-between full-width">
            <ion-button (click)="clearFilterAndSearch()" class="button-secondary" i18n>Filter entfernen</ion-button>
            <ion-button (click)="applyFilterAndSearch()" i18n>Suche und Filter anwenden</ion-button>
        </div>
    </ng-container>
</app-modal>
