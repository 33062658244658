import { Injectable } from '@angular/core';
import { AuthStorageKeyEnum, MediaDownloadTypeEnum } from '../core.enums';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class MediaService {

    constructor() { }

    /**
     * Function to create an URL to download media files
     * @param type - see MediaDownloadTypeEnum
     * @param filename - The name of the file that should be downloaded
     * @returns A URL to download the media file
     */
    getMediaUrl(type: MediaDownloadTypeEnum, filename: string): string {
        const apiUser = localStorage.getItem(AuthStorageKeyEnum.activePharmacy);
        return environment.mediaS3Uri + type +  '/' + apiUser + '/' + filename;
    }
}
