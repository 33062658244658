import { Injectable } from '@angular/core';
import { gql } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';

import { InputValidationInterface } from '../../../core.interfaces';
import { QueryFetchPolicy } from '../../../enums/api.enum';

export const GetInputValidation = gql`
    query GetInputValidation($tableName: String, $limit: Int) {
        inputValidator(where: {tableName: {_eq: $tableName}}, limit: $limit) {
            tableName
            field
            isNullable
            minLength
            maxLength
        }
    }
`;

export const AllInputValidationQueries = [
    GetInputValidation
];


@Injectable()
export class InputValidationQueries {
    constructor(
        private apollo: Apollo
    ) {}

    /**
     * Return all input validators for a table
     */
    public getInputValidatorsForTable(tableName): Observable<InputValidationInterface[]> {
        const variables = {
            tableName,
            limit: 10,
        };
        return this.apollo.watchQuery({
            query: GetInputValidation,
            fetchPolicy: QueryFetchPolicy.CACHE_AND_NETWORK,
            variables
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['inputValidator'])) as Observable<InputValidationInterface[]>;
    }
}
