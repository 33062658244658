import { inject, Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { switchMap, tap } from 'rxjs';
import { DynamicPageQueries } from '../store/graphql/queries/dynamicPage.graphql';
import { DynamicPageInterface } from '../interfaces/dynamicPage.interface';
import { DynamicPageColorTheme } from '../enums/dynamicPage.enum';


@Injectable({
    providedIn: 'root',
})
export class DynamicPageService {
    // Injects
    private dynamicPageQueries = inject(DynamicPageQueries);
    private router = inject(Router);

    // Dynamic Page Signal
    private _dynamicPage = signal<DynamicPageInterface>(null);
    public dynamicPage = this._dynamicPage.asReadonly();

    // isLoading Signal
    private _isLoading = signal<boolean>(true);
    public isLoading = this._isLoading.asReadonly();

    // Filters Signal
    private _displayLocation = signal<DynamicPageColorTheme>(DynamicPageColorTheme.mea);
    public displayLocation = this._displayLocation.asReadonly();

    // Receive new dynamicPage and update the signals
    private dynamicPage$ = toObservable(this._displayLocation).pipe(
        switchMap(() => this.dynamicPageQueries.getDynamicPage(this.router.url)),
        tap((data) => {
            this._dynamicPage.set(data);
            this._isLoading.set(false);
        })
    );

    // toSignal automatically subscribes and unsubscribes to the observable
    dynamicPageReadOnly = toSignal(this.dynamicPage$, { initialValue: null as DynamicPageInterface });

    /**
     * Update of selected displayLocation
     *
     * @param displayLocation - the changed displayLocation
     */
    public setDisplayLocation(displayLocation: DynamicPageColorTheme): void {
        this._displayLocation.update(() => displayLocation);
    }
}
