import { Injectable } from '@angular/core';
import { gql } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import {
    SSOPermissionsInterface,
    UserAdministrationGraphqlInterface
} from '../../../interfaces/user-administration.interface';
import { UserAccessModulesConfig, UserAccessRightsConfig } from '../../../config/user-administration.config';
import { UserAccessRightsEnum } from '../../../enums/user-administration.enum';
import { QueryFetchPolicy } from '../../../enums/api.enum';

export const GetSSOUsers = gql`
    query GetSSOUsers {
        getSSOUsers {
            id
            email
            salutation
            title
            firstName
            lastName
            enabled
            sconnect {
                pharmacyId,
                permissions
            }
            chat {
                pharmacyId,
                permissions
            }
            shop {
                pharmacyId,
                permissions
            }
            sacademy {
                pharmacyId
                permissions
            }
            sanavendi {
                pharmacyId
                permissions
            }
        }
    }
`;

export const AllUserAdministrationQueries = [
    GetSSOUsers
];

@Injectable()
export class UserAdministrationQueries {
    constructor(
        private apollo: Apollo,
    ) {
    }

    public getAllUsers(
        fetchPolicy: QueryFetchPolicy = QueryFetchPolicy.CACHE_AND_NETWORK
    ): Observable<UserAdministrationGraphqlInterface> {
        return this.apollo.watchQuery({
            query: GetSSOUsers,
            fetchPolicy,
            errorPolicy: 'all'
        })
            .valueChanges
            .pipe(map((returnValues) => this.mapAccessRights(returnValues))) as Observable<UserAdministrationGraphqlInterface>;
    }

    /**
     * This function maps the access rights from keycloak to the pharmacies object used in the app.
     *
     * @private
     * @param returnValues
     */
    private mapAccessRights(returnValues): UserAdministrationGraphqlInterface {
        if (!returnValues || returnValues.errors || !returnValues.data || !returnValues.data.getSSOUsers) {
            return {error: 'Das Laden der Nutzerdaten ist derzeit nicht möglich, bitte versuchen Sie es in Kürze erneut.'};
        }
        const users = returnValues.data.getSSOUsers;

        let ownerPharmacies = [];
        UserAccessModulesConfig.forEach(module => {
            const pharmacy = (users.map(user => user[module]
                .map(m => m.permissions.includes(UserAccessRightsEnum.PHARMACY_OWNER) ? m.pharmacyId : null).filter(m => m)
            )).filter(m => m && m.length > 0);
            pharmacy.forEach(p => {
                p.forEach(apiUser => {
                    if (!ownerPharmacies.includes(apiUser)) {
                        ownerPharmacies.push(apiUser);
                    }
                });
            });
        });
        ownerPharmacies = ownerPharmacies.sort();
        return {data: users ? users.map(user => {
            const userFormatted = {
                id: user.id,
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                salutation: user.salutation,
                title: user.title,
                pharmacies: ownerPharmacies.map(pharmacy => ({apiUser: pharmacy}))
            };

            return {...userFormatted, pharmacies: userFormatted.pharmacies.map(pharmacyPermissions => {
                UserAccessModulesConfig.forEach(module => {
                    if (user[module]) {
                        const modulePermissions = user[module].find(userPharmacy => pharmacyPermissions.apiUser ===  userPharmacy.pharmacyId);
                        if (modulePermissions) {
                            pharmacyPermissions = {
                                ...pharmacyPermissions,
                                [module]: this.getPharmacyPermissions(module, modulePermissions)
                            };
                        }
                    }
                });
                return pharmacyPermissions;
            })};
        }) : []};
    }
    getPharmacyPermissions = (module, modulePermissions: SSOPermissionsInterface) => {
        let permissions = [];
        if (modulePermissions.permissions) {
            permissions = modulePermissions.permissions.includes(UserAccessRightsEnum.PHARMACY_OWNER) ?
                [...modulePermissions.permissions, ...UserAccessRightsConfig[module].ownerRights] :
                modulePermissions.permissions;
        }
        return permissions;
    }
}
