<app-modal *ngIf="order"  i18n-title title="Nachbestellen der Bestellung {{order ? order.orderIdOrg : ''}}" >
    <ng-container headerActions></ng-container>
    <ng-container content>
        <h2 *ngIf="order">{{order.productName}}</h2>
        <ion-grid *ngIf="order" class="ion-no-padding form">
            <ion-row class="ion-no-padding">
                <ion-col size="12" size-md="12" size-sm="24">
                    <ion-list>
                        <ion-item class="background-input-large">
                            <ion-input
                                i18n-label
                                label="PZN"
                                class="gray full-opacity"
                                disabled="true"
                                [value]="order.pzn !== 'null' ? order.pzn.padStart(8, '0'): 'unbekannt'"
                            ></ion-input>
                        </ion-item>
                        <ion-item class="background-input-large">
                            <ion-input
                                i18n-label
                                label="Darreichungsform"
                                class="gray full-opacity"
                                disabled="true"
                                [value]="order.dosageForm"
                            ></ion-input>
                        </ion-item>
                        <ion-item class="background-input-large">
                            <ion-input
                                i18n-label
                                label="Packungsgröße"
                                class="gray full-opacity"
                                disabled="true"
                                [value]="order.packageSize"
                            ></ion-input>
                        </ion-item>
                    </ion-list>
                </ion-col>
                <ion-col size="12" size-md="12" size-sm="24">
                    <ion-list>
                        <ion-item class="background-input-large">
                            <ion-input
                                i18n-label
                                label="Hersteller"
                                class="gray full-opacity"
                                disabled="true"
                                [value]="order.producer"
                            ></ion-input>
                        </ion-item>
                        <ion-item class="background-input-large">
                            <ion-input
                                i18n-label
                                label="Betäubungsmittel"
                                class="gray full-opacity"
                                disabled="true"
                                i18n
                                [value]="order.isNarcotic ? 'Ja' : 'Nein'"
                            ></ion-input>
                        </ion-item>
                    </ion-list>
                </ion-col>
            </ion-row>
        </ion-grid>
        <ion-text class="important-notice" i18n>
            <p>Bei Dispo-Lieferungen können Portokosten des Lieferanten anfallen, diese werden Ihnen in Rechnung gestellt. Außerdem können wir Bestellungen sobald sie dem Lieferanten übermittelt wurden <strong>nicht mehr stornieren</strong>,
                sowie disponierte Artikel <strong>nicht mehr zurücknehmen</strong> bzw. an den Hersteller zurückgeben.<br /><br />Bitte prüfen Sie Ihre Bestelldaten sorgfältig.</p>
        </ion-text>
    </ng-container>
    <!-- Footer -->
    <ng-container footer>
        <div class="order-copy-actions display-flex">
            <form [formGroup]="validationFormGroup" (ngSubmit)="sendDispositionRequest(validationFormGroup.value)" class="form display-flex">
                <ion-label i18n>Menge</ion-label>
                <ion-input aria-label="Menge" type="number" formControlName="qty" i18n-placeholder placeholder="Menge" value="1"></ion-input>
                <ion-button
                        id="{{matomoId}}"
                        [class.disabled]="!validationFormGroup.valid"
                        appTooltip
                        ttk="{{!validationFormGroup.valid ? 'invalid_amount' : null}}"
                        ttp="left"
                        (click)="validationFormGroup.valid ? sendDispositionRequest(validationFormGroup.value) : null"
                        title="Bitte geben Sie zunächst eine Menge ein."
                >Absenden</ion-button>
            </form>
        </div>
    </ng-container>
</app-modal>
