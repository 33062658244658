import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, ModuleWithProviders, NgModule } from '@angular/core';
import { IonicStorageModule } from '@ionic/storage-angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { KeycloakAngularModule } from 'keycloak-angular';
import { CommonStoreModule } from '../../../common/resources/src/common-store/common-store.module';
import { initSentry } from '../../../common/resources/src/sentry';
import { PharmacyChatPartnerMetadataService } from '../../../pharmacy-only/src/services/pharmacy-chat-partner-metadata.service';
import { PharmacyChatPartnerStoreModule } from '../../../pharmacy-only/src/store/pharmacy-chat-partner-store/pharmacy-chat-partner-store.module';
import { MeaChatSconnectService } from './mea-chat-sconnect.service';
import { WebpackTranslateLoader } from './webpack-translate-loader';
import { CONFIG } from '../../../essentials/types/src/mea-config';
import { CHAT_PARTNER_METADATA_SERVICE } from '../../../essentials/types/src/service-interfaces/chat-partner-metadata.service.interface';

dayjs.locale('de');

/** Version number of SconnectModule */
const version = '0.1';

/** import this module in Sconnect AppModule via MeaChatSconnectModule.forRoot(environment) */
@NgModule({
  declarations: [],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
    HttpClientModule,
    IonicStorageModule.forRoot(),
    KeycloakAngularModule,
    StoreModule.forRoot(),
    EffectsModule.forRoot([]),
    PharmacyChatPartnerStoreModule,
    CommonStoreModule,
  ],
  exports: [],
})
export class MeaChatSconnectModule {
  public static forRoot(config: any): ModuleWithProviders<MeaChatSconnectModule> {
    if (typeof version !== 'undefined' && config.name === 'prod') {
      initSentry({
        dsn: config.sentryDSN,
        release: version,
        environment: config.name,
      });
    }

    return {
      ngModule: MeaChatSconnectModule,
      providers: [
        { provide: CHAT_PARTNER_METADATA_SERVICE, useClass: PharmacyChatPartnerMetadataService },
        { provide: LOCALE_ID, useValue: 'de-de' },
        { provide: CONFIG, useValue: config },
        MeaChatSconnectService,
      ],
    };
  }
}
