import { Injectable } from '@angular/core';
import { PdfViewerPayloadInterface } from '../../interfaces/pdf.interface';
import { PdfViewerType } from '../../enums/pdf.enum';
import { PdfService } from '../../services/pdf.service';
import { DocumentService } from '../../services/document.service';

@Injectable({
    providedIn: 'root',
})
export class PdfViewerService {

    constructor(
        private documentService: DocumentService,
    ) { }
    /**
     * Prints the pfd as integer array
     *
     * @param pdf as integer array
     * @param newtab reference to the new tab window
     */
    static print(pdf: number[], newtab) {
        return PdfService.print(pdf, newtab);
    }

    /**
     * Gets the pfd as integer array
     *
     * @param type of the pdf
     * @param payload of the pdf
     */
    async getPdfAsByteArray(type: PdfViewerType, payload: PdfViewerPayloadInterface) : Promise<{result: number[], error: string}> {
        switch (type) {
            case PdfViewerType.delivery:
            case PdfViewerType.batch:
            case PdfViewerType.invoice:
                return this.documentService.getDeliveryDocumentPdf(payload.id);
            default:
                return new Promise<{result: number[], error: string}>((resolve) => {
                    resolve({result: [], error: null});
                });
        }
    }

    /**
     * Prints the pfd as integer array
     *
     * @param pdf as integer array
     * @param filename of the pdf
     */
    download(pdf: number[], filename: string) {
        return PdfService.download(pdf, filename);
    }
}
