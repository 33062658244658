import { ChangeDetectionStrategy, Component, effect, inject, Input, signal, Signal, untracked, } from '@angular/core';
import { ModalController, ToggleCustomEvent } from '@ionic/angular';
import { TableSearchModalConfigInterface } from '../../../interfaces/table.interface';
import { TableFilterTypeEnum } from '../../../enums/table.enum';
import { ReturnsFiltersInterface } from '../../../interfaces/returns.interface';
import { DateSelectionEventInterface } from '../../../interfaces/date-range.interface';
import { OrderFiltersInterface } from '../../../interfaces/order.interface';

@Component({
    selector: 'app-search-modal',
    templateUrl: './search-modal.component.html',
    styleUrls: ['./search-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchModalComponent {
    private modalController = inject(ModalController);
    /**
     * Filters Signal to be used to display the currently selected filters
     */
    @Input() filters: Signal<ReturnsFiltersInterface>;
    /**
     * Configuration, that determines the filters to be displayed in the modal
     */
    @Input() filterConfig: TableSearchModalConfigInterface;
    /**
     * Minimum date for date filters
     */
    @Input() minDate: Date | null;

    filterTypes = TableFilterTypeEnum;
    search: string = null;

    filtersWritable = signal<ReturnsFiltersInterface | OrderFiltersInterface>(null);

    constructor() {
        effect(() => {
            this.search = this.filters().search;
        });

        /**
         * Update writable filters as soon as the filters change
         */
        effect(() => {
            untracked(() => {
                this.filtersWritable.set(this.filters());
            });
        });
    }

    onDateChange(dateEvent: DateSelectionEventInterface, dataKey: string) {
        const filter = {
            [`${dataKey}Option`]: dateEvent.dateOption,
            [`${dataKey}From`]: dateEvent.dateFrom,
            [`${dataKey}To`]: dateEvent.dateTo,
        };
        this.filtersWritable.update(prev => {
            return {
                ...prev,
                ...filter
            };
        });
    }

    onSelectFilterChange(event: string, dataKey: string) {
        this.filtersWritable.update(prev => {
            return {
                ...prev,
                [dataKey]: event
            };
        });
    }

    onBoolFilterChange(event: ToggleCustomEvent, dataKey: string) {
        this.filtersWritable.update(prev => {
            return {
                ...prev,
                [dataKey]: event.detail.checked
            };
        });
    }

    clearFilterAndSearch() {
        this.filterConfig.onReset();
        void this.modalController.dismiss();
    }

    applyFilterAndSearch() {
        const filters: ReturnsFiltersInterface = {
            ...this.filtersWritable(),
            search: this.search,
        };
        this.filterConfig.onAction(filters);
        void this.modalController.dismiss();
    }
}
