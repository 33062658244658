<app-template-modal
        style="height: 100%;"
        title="Hinweis"
        closeButtonTitle="Verstanden"
        [infoModalTemplate]="infoModalTemplate"
></app-template-modal>

<ng-template #infoModalTemplate>
    <ion-text i18n>
        <h3 class="info-modal-heading">Hinweis zur Nutzung des Downloadservices</h3>
        <p>Wir bitten Sie für sämtliche im Downloadservice zur Verfügung gestellten Materialien folgende Grundsätze
            zu beachten und einzuhalten:</p>
        <div>
            <ul class="info-modal-list">
                <li>Bei den exemplarisch dargestellten Unterlagen handelt es sich um allgemeine Muster.
                    Bei den dort im Einzelnen enthaltenen Preisangaben handelt es sich um
                    exemplarische Preisvorschläge.</li>
                <li>Selbstverständlich sind Sie nicht verpflichtet, die Handzettel und Materialien zu verwenden und
                    etwaige Preisvorschläge zu übernehmen. Wir empfehlen Ihnen unbedingt, die jeweiligen
                    Abgabepreise selbst zu kalkulieren und festzulegen. Gerne integrieren wir Ihre individuell
                    kalkulierten Preise in Ihre apothekenindividuellen Handzettel.</li>
                <li>Bitte beachten Sie, dass die Bilddateien - ausschließlich wie hier im Downloadservice
                    angeboten - eingesetzt werden dürfen. D.h. das Herauslösen einzelner Bilder aus Werbemitteln,
                    wie z.B. Handzetteln oder Anzeigen, ist nicht gestattet, da Sie damit Lizenz- und Urheberrechte
                    verletzen können.</li>
                <li>Es ist nicht gestattet, bei der Verwendung der zum Download bereit gestellten Materialien die
                    Schriftgröße der darin enthaltenen Textangaben zu verkleinern. Um die Pflichtangaben für Ihre
                    Kunden lesbar darzustellen, müssen diese in der Mindestschriftgröße 6 Pt abgebildet werden.</li>
                <li>Sollten Sie die Bilddateien im Rahmen eines von Ihnen betriebenen Webshops/Vorbestellshops
                    einsetzen wollen, so sind Sie als Betreiber auch verpflichtet, alle Pflichtinformationen für
                    Verbraucher zu den angebotenen Produkten, insbesondere nach der Preisangabenverordnung sowie der
                    Lebensmittelinformationsverordnung (vgl. Art. 9 Abs. 1 LMIV) bereitzustellen.
                    Bei der Verwendung von Werbemitteln aus dem Downloadservice obliegt es Ihrer Verantwortung,
                    die entsprechenden Informationen zu ergänzen.</li>
            </ul>
        </div>
    </ion-text>
</ng-template>
