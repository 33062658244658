import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';
import uniq from 'lodash/uniq';

import { MeamindQueries } from '../../../../../../core/store/graphql/queries/meamind.graphql';
import { TOOLTIPS } from '../../../../../../core/core.config';
import { unsubscribe, unsubscribeAll } from '../../../../../../core/util/subscriptions.util';

/**
 * Field for Meamind Tags
 *
 * This field can be used as form field like an regular input. This means it is possible to use ngModel or formControls.
 *
 * Notice about tags: Prevent tags from be suggested after the user removed or added them by himself. The excludedTags should
 * always contain every by the user removed or added tag.
 */

@Component({
    selector: 'app-meamind-tags-field',
    templateUrl: 'meamind-tags-field.component.html',
    styleUrls: ['meamind-tags-field.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MeamindTagsFieldComponent),
            multi: true
        }
    ],
    animations: [
        trigger('tagsVisible', [
            state('visible', style({
                opacity: 1
            })),
            state('hidden', style({
                opacity: 0
            })),
            transition('visible=>hidden', animate('250ms')),
            transition('hidden=>visible', animate('250ms')),
        ])
    ]
})
export class MeamindTagsFieldComponent implements OnInit, OnChanges, ControlValueAccessor {
    @Input() questionTitle: string;
    @Input() questionDescription: string;
    @Input() prefilledTags: Array<string> = [];
    @Input() editable = true;
    tags: Array<string> = [];
    customTags: Array<string> = [];
    excludedTags: Array<string> = [];
    customTag = '';
    tooltips = TOOLTIPS;

    subscriptions: {
        tagSuggestionsByQuestion: Subscription
        tagSuggestionsByCustomTag: Subscription
    } = {
        tagSuggestionsByQuestion: null,
        tagSuggestionsByCustomTag: null
    };

    constructor(private meamindQueries: MeamindQueries) {}

    ngOnInit() {
        this.customTags = this.prefilledTags || [];
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.questionTitle || changes.questionDescription) {
            // Start searching for similar questions if provided title is longer than 4 letters
            if(
                (this.questionTitle && this.questionTitle.length > 4) ||
                (this.questionDescription && this.questionDescription.length > 4)
            ) {
                this.loadTagSuggestionsByQuestion();
            } else {
                this.tags = [].concat(...this.customTags);
                this.excludedTags = this.excludedTags.filter(tag => this.customTags.indexOf(tag) > -1);
                this.propagateChange(this.tags);
            }
        }
    }

    /**
     * onChange function. Just for the case if no other onChange method was declared.
     *
     * @param tags - Array of tags
     */
    propagateChange(tags: string[]) {}

    /**
     * Clear every interval or subscription
     */
    clear() {
        unsubscribeAll([
            this.subscriptions.tagSuggestionsByQuestion,
            this.subscriptions.tagSuggestionsByCustomTag
        ]);
    }

    /**
     * Load tag suggestions by the provided title and description
     */
    loadTagSuggestionsByQuestion() {
        unsubscribe( this.subscriptions.tagSuggestionsByQuestion);
        const sanitizedDescription = this.questionDescription ? this.questionDescription.replaceAll(/(<([^>]+)>)/ig, ' ') : '';
        this.subscriptions.tagSuggestionsByQuestion = this.meamindQueries
            .generateTags((this.questionTitle || '') + ' ' + sanitizedDescription)
            .subscribe(tags => {
                if (tags) {
                    this.tags = uniq([].concat(...tags).concat(...this.customTags));
                    this.propagateChange(this.tags);
                }
        });
    }

    /**
     * Load tag suggestions by the provided tag
     */
    loadTagSuggestionsByCustomTag() {
        unsubscribe(this.subscriptions.tagSuggestionsByCustomTag);
    }

    /**
     * Add a custom or a suggested tag
     *
     * @param newTag - Tag to add
     */
    addTag(newTag) {
        if(newTag === '')
            return;

        if(!this.tags.find(tag => tag === newTag)) {
            this.tags.push(newTag);
            this.customTags.push(newTag);
            this.propagateChange(this.tags);
        }
        if(!this.excludedTags.find(tag => tag === newTag)) {
            this.excludedTags.push(newTag);
        }

        this.customTag = '';
    }

    // ACTIONS
    // ------------------------------------------------------------------------------------------------------------------------------------

    /**
     *
     * Remove a single tag
     *
     * @param tagToRemove - Tag to remove
     */
    onRemoveTagClick(tagToRemove) {
        this.tags = this.tags.filter(tag => tag !== tagToRemove);
        this.customTags = this.customTags.filter(tag => tag !== tagToRemove);
        this.propagateChange(this.tags);
        this.excludedTags.push(tagToRemove);
    }

    /**
     * Add a new tag
     */
    onSubmitNewTag() {
        this.addTag(this.customTag);
    }

    /**
     * User selected a suggested tag
     *
     * @param tag - Tag to add
     */
    onSuggestedTagClick(tag) {
        this.addTag(tag);
    }

    /**
     * User enters a custom tag, provide some suggestions
     *
     * @param event - Input event
     */
    onCustomTagChange(event) {
        const customTag = event.target.value;
        if(customTag.length > 1) {
            this.loadTagSuggestionsByCustomTag();
        } 
    }


    // ControlValueAccessor Methods
    // ------------------------------------------------------------------------------------------------------------------------------------

    /**
     * Register on change method for parent component
     *
     * @param fn - onChange method
     */
    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    /**
     * Provided tags from parent component
     *
     * @param tags - Array of tags
     */
    writeValue(tags: string[]): void {
        this.tags = tags;
    }
}
