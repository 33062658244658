export enum FetchPolicyKeys {
    getOrders = 'getOrders',
    getOrders2 = 'getOrders2',
    getOrdersCount = 'getOrdersCount',
    getOrderById = 'getOrderById',
    getPZNCollection = 'getPZNCollection',
    getProducerCollection = 'getProducerCollection',
    getOrderNotesByOrderIds = 'getOrderNotesByOrderIds',

    getAppointmentsByDate = 'getAppointmentsByDate',
    getAppointmentById = 'getAppointmentById',
    getAppointmentsBySanacorpByDate = 'getAppointmentsBySanacorpByDate',
    getAppointmentsBySanacorpById = 'getAppointmentsBySanacorpById',
    getAppointmentsReminderById = 'getAppointmentsReminderById',

    getEducations = 'getEducations',
    getEducationById = 'getEducationById',

    getCommunicationZone = 'getCommunicationZone',
    getCommunicationZoneById = 'getCommunicationZoneById',

    getNewsPosts = 'getNewsPosts',
    getNewsPost = 'getNewsPost',
    getForm = 'getForm',
    getFormResult = 'getFormResult',

    getNotifications = 'getNotifications',
    getMeaShopNotifications = 'getMeaShopNotifications',
    getNotificationsCount = 'getNotificationsCount',
    getNotificationById = 'getNotificationsById',
    getNotificationFiltered2 = 'getNotificationFiltered2',

    getNoteBySourceId = 'getNoteBySourceId',

    getOffersWithDetails = 'getOffersWithDetails',
    getOffersBanner = 'getOffersBanner',
    getOffersBannerByOfferId = 'getOffersBannerByOfferId',
    getOffersInfo = 'getOffersInfo',
    getOffersFilter = 'getOffersFilter',
    getOfferQuote = 'getOfferQuote',

    getReturns = 'getReturns',
    getReturnById = 'getReturnById',
    getReturnsCount = 'getReturnsCount',
    getReturnsPZNCollection = 'getReturnsPZNCollection',
    getReturnsProducerCollection = 'getReturnsProducerCollection',
    getDeliveryNoteNumberCollection = 'getDeliveryNoteNumberCollection',
    getReturnsNotesByReturnsIds = 'getReturnsNotesByReturnsIds',

    getQuestions = 'GetQuestions',
    getMyQuestions = 'GetMyQuestions',
    getQuestionById = 'GetQuestionById',
    getQuestionsOfMyAnswers = 'GetQuestionsOfMyAnswers',
    searchQuestions = 'SearchQuestions',

    getDocumentById = 'getDocumentById',
    getDocumentsLS = 'getDocumentsLS',
    getDocumentsWithChildLS = 'getDocumentsWithChildLS',
    getDocumentsCountLS = 'getDocumentsCountLS',
    getAllDocumentsCountLS = 'getAllDocumentsCountLS',
    getDocumentFilterResultLS = 'getDocumentFilterResultLS',
    getDocumentFilterResultCountLS = 'getDocumentFilterResultCountLS',
    getDocumentFilterStatusLS = 'getDocumentFilterStatusLS',
    getDocumentByDocumentIdLS = 'getDocumentByDocumentIdLS',
    getDocumentNumberCollectionLS = 'getDocumentNumberCollectionLS',
    getDocumentNumberAndDateCollectionLS = 'getDocumentNumberAndDateCollectionLS',

    getDocumentsRE = 'getDocumentsRE',
    getDocumentsWithChildRE = 'getDocumentsWithChildRE',
    getDocumentsCountRE = 'getDocumentsCountRE',
    getAllDocumentsCountRE = 'getAllDocumentsCountRE',
    getDocumentByDocumentIdRE = 'getDocumentByDocumentIdRE',
    getDocumentNumberCollectionRE = 'getDocumentNumberCollectionRE',
    getDocumentNumberAndDateCollectionRE = 'getDocumentNumberAndDateCollectionRE',

    getProducer = 'getProducer',

    // These keys are handling multiple types of request (orders, documents, returns ...)
    getSorting = 'getSorting',

    getCalendarSettings = 'getCalendarSettings',
    getNotificationSettings = 'getNotificationSettings',
    getPharmacySettings = 'getPharmacySettings',
    getSecuritySettings = 'getSecuritySettings',
    getUserSettingsByKeys = 'getUserSettingsByKeys',
    getPharmacyWithSettings = 'getPharmacyWithSettings',

    getSafetyDataSheets = 'getSafetyDataSheets',

    getBanners = 'getBanners',

    getInfoModals = 'getInfoModals',

    getStatistics = 'getStatistics',
    getBusinessFigureLoadingStatus = 'getBusinessFigureLoadingStatus',
    getBusinessFiguresLoadingStatus = 'getBusinessFiguresLoadingStatus',
    getBusinessFigureSubscriptions = 'getBusinessFigureSubscriptions',
    getBusinessFigureDuett = 'getBusinessFigureDuett',

    getUserStorage = 'getUserStorage',

    getStatisticsFiltered = 'getStatisticsFiltered',

    getMeaDownloads = 'getMeaDownloads',
    getMeaDownloadTypes = 'getMeaDownloadTypes',

    getDynamicPage = 'getDynamicPage',

    getSurveys = 'getSurveys',
    getUserSurvey = 'getUserSurvey',

    getArchives = 'getArchives'
}
