import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
// Core Files
import {
    AuthStorageKeyEnum,
    ButtonTypes,
    CommunicationZoneFormEnum,
    DocumentSubType,
    DocumentType,
    matomoIdsEnum
} from '../../../../../core/core.enums';
import { DocumentQueries } from '../../../../../core/core.store';
import { ModalService, PdfService, PopoverService, ToastService } from '../../../../../core/core.services';
import { DocumentInterface } from '../../../../../core/interfaces/document.interface';
import { ButtonInputInterface } from '../../../../../core/interfaces/button-action.interface';
import { DocumentService } from '../../../../../core/services/document.service';
import {
    CommunicationZoneFormComponent
} from '../../../../communications/pages/communication-zone/widgets/communication-zone-form/communication-zone-form.component';
import { formatDateTimeToMoment } from '../../../../../core/formatting/date.formatting';
import { DocumentDownloadFilenamePipe } from '../../../../../core/pipes/document-download-filename.pipe';
import { DocumentNumberFormattedPipe } from '../../../../../core/pipes/document-type.pipe';
import { unsubscribeAll } from '../../../../../core/core.utils';
import {
    CommunicationZoneFormsSubstituteConfig,
    getDocumentReplacementArray
} from '../../../../../core/config/communication-zone-forms.config';

@Component({
    selector: 'app-document-actions',
    templateUrl: './document-actions.component.html',
    styleUrls: ['./document-actions.component.scss']
})
export class DocumentActionsComponent implements OnInit, OnChanges {
    // Popover Controller, provided by ionic
    popover;

    @Input() documentId: number;
    @Input() origDocumentId: number;
    @Input() isCloseButtonVisible = false;
    @Input() documentType = DocumentType.LS;

    defaultButtons: Array<ButtonInputInterface> = [
        {code: null, label: null},
        {code: null, label: null},
        {code: null, label: null}
    ];
    buttonArray: Array<ButtonInputInterface> = [];
    customerNumber: string;

    documentSubscription: Subscription;
    document: DocumentInterface;

    constructor(
        private documentQueries: DocumentQueries,
        private documentService: DocumentService,
        private modalService: ModalService,
        private popoverService: PopoverService,
        private toastService: ToastService
    ) {
        this.buttonArray = this.defaultButtons;
    }

    ngOnInit() {
        this.init();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.documentId ||changes.origDocumentId || changes.isCloseButtonVisible) {
            this.init();
        }
    }

    ionViewWillLeave(): void {
        this.unsubscribeAll();
    }

    init() {
        // add another row of default button, when close button is visible, to match number of visible buttons
        if (this.isCloseButtonVisible) {
            this.defaultButtons.push({code: null, label: null});
        }
        this.buttonArray = this.defaultButtons;
        this.unsubscribeAll();
        if (this.documentId) {
            this.documentSubscription = this.documentQueries.getDocumentById(this.documentId).subscribe((document) => {
                this.document = document;
                this.updateButtonArray();
            });
        } else if (this.origDocumentId) {
            // TODO DON'T USE THIS FUNCTION LIKE THIS  - invoices and deliveries may have the same number
            // this function is not yet available on production
            this.documentSubscription = this.documentQueries.getDocumentByDocumentId(this.origDocumentId).subscribe((documents) => {
                this.document = documents && documents.length > 0 ? documents[0] : null;
                this.updateButtonArray();
            });
        }
        this.customerNumber = localStorage.getItem(AuthStorageKeyEnum.activePharmacy);
    }

    updateButtonArray = () => {
        this.buttonArray = [];
        let tooltipKey = '';
        const tooltipKeySupport = this.documentType === DocumentType.RE ? 'invoice_support_request' : 'delivery_support_request';
        if (!this.document) {
            if (this.documentType === DocumentType.RE) {
                tooltipKey = 'invoice_not_found';
            } else {
                tooltipKey = 'delivery_not_found';
            }
        }

        let matomoClass = '';
        switch(this.documentType) {
            case DocumentType.RE:
                matomoClass = matomoIdsEnum.logisticsActionInvoice;
                break;
            case DocumentType.LS:
                matomoClass = matomoIdsEnum.logisticsActionDelivery;
                break;
            case DocumentType.CH:
                matomoClass = matomoIdsEnum.logisticsActionBatch;
                break;
        }

        this.buttonArray = [
            {code: ButtonTypes.DOWNLOAD, label: 'Download', isDisabled: !this.document, tooltipKey, cssClass: matomoClass},
            {code: ButtonTypes.PRINT, label: 'Drucken', isDisabled: !this.document, tooltipKey, cssClass: matomoClass},
            {code: ButtonTypes.SUPPORT, label: 'Kundenservice kontaktieren', tooltipKey: tooltipKeySupport}
        ];

        if (this.isCloseButtonVisible) {
            this.buttonArray.push({code: ButtonTypes.CLOSE, label: 'Schließen'});
        }
    }

    unsubscribeAll = () => {
        unsubscribeAll([
            this.documentSubscription
        ]);
    }

    /**
     * fires on action button click
     *
     * @param event - Click event
     */
    async onButtonClick(event) {
        let pdf;
        switch (event.code) {
            case ButtonTypes.DOWNLOAD:
                pdf = await this.documentService.getDeliveryDocumentPdf(this.documentId);
                if (pdf && pdf.result) {
                    const filename = new DocumentDownloadFilenamePipe().transform(this.document);
                    await PdfService.download(pdf.result, filename);
                }
                break;
            case ButtonTypes.PRINT:
                const newtab = window.open( '', '_blank' );
                pdf = await this.documentService.getDeliveryDocumentPdf(this.documentId);
                if (pdf && pdf.result) {
                    await PdfService.print(pdf.result, newtab);
                } else {
                    newtab.close();
                    setTimeout(() => {this.toastService.presentError(pdf.error);}, 500);
                }
                break;
            case ButtonTypes.SUPPORT:
                await this.popoverService.dismiss();
                await this.createCommunicationZoneRequest();
                break;
            case ButtonTypes.CLOSE:
                await this.close();
                break;
        }
    }

    /**
     * Create the CommunicationZoneFormComponent modal window for support requests.
     */
    async createCommunicationZoneRequest() {
        if (!this.document?.documentNumber) {
            return;
        }
        const modal = await this.modalService
            .create(CommunicationZoneFormComponent, {
                isReadonly: true,
                id: this.documentId,
                type: this.getPredefinedForm(this.documentType),
                replaceArray: getDocumentReplacementArray(this.document)
            });
        await this.modalService.present(modal);
    }

    /**
     * Determines documentType and return respective predefinedForm of CommunicationZoneFormComponent
     *
     * @param documentType - Type of document
     */
    getPredefinedForm(documentType: DocumentType) {
        let predefinedForm = CommunicationZoneFormEnum.documentLS;
        switch (documentType) {
            case DocumentType.CH:
                predefinedForm = CommunicationZoneFormEnum.documentCH;
                break;
            case DocumentType.LS:
                predefinedForm = CommunicationZoneFormEnum.documentLS;
                break;
            case DocumentType.RE:
                if (this.document.documentSubType === DocumentSubType.E_SR) {
                    predefinedForm = CommunicationZoneFormEnum.documentESR;
                } else {
                    predefinedForm = CommunicationZoneFormEnum.documentETR;
                }
                break;
        }
        return predefinedForm;
    }

    /**
     * closes action popover
     */
    async close() {
        await this.popoverService.dismiss({close: true});
    }
}
