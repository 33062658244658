<div class="tag-container">
    <ion-input
        i18n-label
        type="text"
        label="Schlagwörter"
        class="required"
        [disabled]="!editable"
        [placeholder]="tooltips.meamind_question_tags"
        (keyup.enter)="onSubmitNewTag()"
        [(ngModel)]="customTag"
        (ionChange)="onCustomTagChange($event)"
    ></ion-input>
    <ion-button *ngIf="editable" [class.disabled]="customTag.length === 0" (click)="onSubmitNewTag()" i18n>Hinzufügen</ion-button>
</div>
<div [@tagsVisible]="tags.length > 0 ? 'visible' : 'hidden'" class="tags-wrapper">
    <ion-text i18n>Ausgewählt:</ion-text>
    <div class="chips-container">
        <ion-chip *ngFor="let tag of tags">
            <app-meamind-tag [tag]="tag"></app-meamind-tag>
            <ion-icon name="close" *ngIf="editable" (click)="onRemoveTagClick(tag)"></ion-icon>
        </ion-chip>
    </div>
</div>
