import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-mea-downloads-info-modal',
    templateUrl: './mea-downloads-info-modal.component.html',
    styleUrls: ['./mea-downloads-info-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeaDownloadsInfoModalComponent {

}
