import DecryptionStatus from './decryptionStatus';

export interface ChatPartnerMetadata extends BackendChatPartnerMetadata {
  decryptedChatPartnerNickname?: string;
  decryptedChatPartnerChatname?: string;
  chatPartnerNicknameDecryptionStatus?: DecryptionStatus;
  chatPartnerChatnameDecryptionStatus?: DecryptionStatus;
}

export interface BackendChatPartnerMetadata {
  cognitoId: string;
  chatPartnerId: string;
  encryptedChatPartnerNickname?: string;
  chatPartnerChatnameHistory?: ChatPartnerChatnameEntry[];
  isSuspended?: boolean;
  isUnlockedForDemoPharmacy?: boolean;
}

export interface EncryptedChatnamesForPharmaciesInput {
  encryptedFirstName: string;
  encryptedLastName: string;
  pharmacyCognitoId: string;
}

export interface ChatPartnerMetadataInput {
  encryptedChatPartnerNickname?: string;
  isSuspended?: boolean;
  isUnlockedForDemoPharmacy?: boolean;
}

export interface DecryptedChatnameUpdate {
  timestamp: number;
  oldDecryptedChatname: string;
  newDecryptedChatname: string;
}

export interface LegacyChatPartnerChatnameEntry {
  timestamp: number;
  encryptedChatname: string | null | undefined;
}

export interface FullChatPartnerUsername {
  firstName: string;
  lastName: string;
}

export interface FullChatPartnerChatnameEntry {
  timestamp: number;
  encryptedFirstName: string;
  encryptedLastName: string;
}

export type ChatPartnerChatnameEntry = LegacyChatPartnerChatnameEntry | FullChatPartnerChatnameEntry;

export function isFullChatnameEntry(
  chatnameEntry: ChatPartnerChatnameEntry
): chatnameEntry is FullChatPartnerChatnameEntry {
  return 'encryptedFirstName' in chatnameEntry && !!chatnameEntry.encryptedFirstName;
}
