import { EventEmitter, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router, RouterEvent, Event } from '@angular/router';
import { createClient } from 'graphql-ws';

import { filter, Subscription } from 'rxjs';
import { IonicStorageModule, Storage } from '@ionic/storage-angular';

import { persistCache } from 'apollo3-cache-persist';
import { ApolloLink, split, InMemoryCache } from '@apollo/client/core';
import { RetryLink } from '@apollo/client/link/retry';

import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { Client } from 'graphql-ws/lib/client';

import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { Apollo, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { SentryLink } from 'apollo-link-sentry';
import { jwtDecode } from 'jwt-decode';
import * as Sentry from '@sentry/angular';


import { environment } from '../../../environments/environment';
import { MaxExecutionErrorMessage } from '../enums/api.enum';

// GraphQl Queries, Mutations
import {
    AppointmentQueries,
    AuthQueries,
    ArchiveQueries,
    BannerQueries,
    BusinessFigureQueries,
    CommunicationZoneQueries,
    DocumentQueries,
    DynamicPageQueries,
    InformationQueries,
    InputValidationQueries,
    ItemSearchQueries,
    MeaQueries,
    MeamindQueries,
    NewsPostQueries,
    FormQueries,
    NotesQueries,
    NotificationQueries,
    OffersQueries,
    OrderQueries,
    PdfQueries,
    EducationQueries,
    ExcelQueries,
    ReleaseNoteQueries,
    ReturnsQueries,
    SanavendiQueries,
    SafetyDatasheetQueries,
    SettingsQueries,
    SortingQueries,
    StaticPagesQueries,
    SurveyQueries,
    UserAdministrationQueries,
    UserStorageQueries,
    ProducerQueries,

    AuthMutations,
    AppointmentMutations,
    ArchiveMutations,
    BulkChangesMutations,
    CommunicationZoneMutations,
    DocumentMutations,
    InformationMutations,
    MeamindMutations,
    NotesMutations,
    OffersMutations,
    OrdersMutations,
    FormsMutations,
    StaticPagesMutations,
    ReleaseNoteMutations,
    SanavendiMutations,
    SafetyDatasheetMutations,
    StatisticsMutations,
    SurveyMutations,
    SettingsMutations,
    SortingMutations,
    UserAdministrationMutations,
    UserStorageMutations
} from '../core.store';


// GraphQl Subscriptions
import {
    GetDataChangedGlobalSubscription,
    GetDataChangedPharmacySubscription,
    GetDataChangedUserSubscription,
    GetDataChangedPharmacyUserSubscription
} from './graphql/subscriptions/information.subscriptions';
import { AppRoutesEnum } from '../enums/routes.enum';
import { ProfileSettingsVar } from './locals/profileSettings.var';
import { ToastService } from '../services/toast.service';
import { AuthStorageKeyEnum } from '../enums/authStorageKey.enum';
import { SconnectAuthService } from '../services/authentication/sconnect.auth.service';
import { unsubscribe } from '../util/subscriptions.util';
import {
    ConnectionStatusServiceOptions,
    ConnectionStatusServiceOptionsToken
} from '../services/connectionStatus.service';
import packageInfo from '../../../../../package.json';

const defaultMergeBehaviour = {
    // Merge function to prevent Apollo Cache warnings
    merge(existing, incoming) {
        return incoming;
    }
};
const pharmacyStoreBehaviour = {
    // Merge function to prevent Apollo Cache warnings
    merge(existing, incoming, {mergeObjects}) {
        const existingObject = existing || [];
        const newObject = incoming || [];
        const biggestArray = existingObject.length > newObject.length ? existingObject : newObject;
        const returnArray = [];
        biggestArray.forEach((value, key) => {
            returnArray.push(mergeObjects(existingObject[key] || {}, newObject[key] || {}));
        });
        return returnArray;
    }
};

const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                notification: defaultMergeBehaviour,
                pharmacyStoreOrders: pharmacyStoreBehaviour,
                pharmacyStoreDocuments: pharmacyStoreBehaviour,
                pharmacyStoreReturns: pharmacyStoreBehaviour,
                pharmacyStoreBusinessFigures: pharmacyStoreBehaviour

                /* TODO - Caching with Strapi requests
                offer:  {
                    // Merge function to prevent Apollo Cache warnings
                    merge(existing, incoming, {mergeObjects}) {
                        return mergeObjects(existing, incoming);
                    }
                },
                banners: {
                    merge(existing, incoming) {
                        const existingObject = existing?.data || existing || [];
                        const newObject = incoming?.data || incoming || [];
                        const biggestArray = existingObject.length > newObject.length ? existingObject : newObject;
                        const returnArray = [];
                        biggestArray.forEach((value, key) => {
                            returnArray.push({...(existingObject[key] || {}), ...(newObject[key] || {})});
                        });
                        return returnArray;
                    }
                },
                offers: {
                    // Merge function to prevent Apollo Cache warnings
                    merge(existing, incoming) {
                        const existingObject = existing?.data || existing || [];
                        const newObject = incoming?.data || incoming || [];
                        const biggestArray = existingObject.length > newObject.length ? existingObject : newObject;
                        const returnArray = [];
                        biggestArray.forEach((value, key) => {
                            returnArray.push({...(existingObject[key] || {}), ...(newObject[key] || {})});
                        });
                        return returnArray;
                    }
                },*/
            }
        },
        Subscription: {
            fields: {
                notification: {
                    // Merge function to prevent Apollo Cache warnings
                    merge(existing, incoming) {
                        return incoming;
                    }
                },
                documentFilterStatus: {
                    // Merge function to prevent Apollo Cache warnings
                    merge(existing, incoming) {
                        return incoming;
                    }
                },
                documentFilterResult: {
                    // Merge function to prevent Apollo Cache warnings
                    merge(existing, incoming) {
                        return incoming;
                    }
                },
                moduleActivation: {
                    // Merge function to prevent Apollo Cache warnings
                    merge(existing, incoming) {
                        return incoming;
                    }
                },
                activeModules: {
                    // Merge function to prevent Apollo Cache warnings
                    merge(existing, incoming) {
                        return incoming;
                    }
                },
                offerQuote: {
                    // Merge function to prevent Apollo Cache warnings
                    merge(existing, incoming) {
                        return incoming;
                    }
                }
            }
        }
    }
});

const sentry = new SentryLink();

export let connectionTimeout = 10000;
export let lastConnectionMessage = 0;
export let isDisconnected = false;
export let hasWSError = false;
const ERROR_ABORTED = 'Aborted';
export let connectionEventEmitter = new EventEmitter<{isDisconnected: boolean, hasWSError: boolean}>();

@NgModule({
    providers: [
        {
            provide: ConnectionStatusServiceOptionsToken,
            useValue: {
                connectionEventEmitter
            } as ConnectionStatusServiceOptions
        },
        AppointmentQueries,
        AuthQueries,
        ArchiveQueries,
        BannerQueries,
        BusinessFigureQueries,
        CommunicationZoneQueries,
        DocumentQueries,
        DynamicPageQueries,
        InformationQueries,
        InputValidationQueries,
        ItemSearchQueries,
        MeaQueries,
        MeamindQueries,
        NewsPostQueries,
        FormQueries,
        NotesQueries,
        NotificationQueries,
        OffersQueries,
        OrderQueries,
        PdfQueries,
        EducationQueries,
        ExcelQueries,
        ReturnsQueries,
        ReleaseNoteQueries,
        SanavendiQueries,
        SafetyDatasheetQueries,
        SettingsQueries,
        SortingQueries,
        StaticPagesQueries,
        SurveyQueries,
        UserAdministrationQueries,
        UserStorageQueries,
        ProducerQueries,

        AuthMutations,
        AppointmentMutations,
        ArchiveMutations,
        BulkChangesMutations,
        CommunicationZoneMutations,
        DocumentMutations,
        InformationMutations,
        MeamindMutations,
        NotesMutations,
        OffersMutations,
        OrdersMutations,
        FormsMutations,
        StaticPagesMutations,
        ReleaseNoteMutations,
        SanavendiMutations,
        SafetyDatasheetMutations,
        StatisticsMutations,
        SurveyMutations,
        SettingsMutations,
        SortingMutations,
        UserAdministrationMutations,
        UserStorageMutations,

        GetDataChangedGlobalSubscription,
        GetDataChangedPharmacySubscription,
        GetDataChangedUserSubscription,
        GetDataChangedPharmacyUserSubscription,

        ProfileSettingsVar
    ],
    imports: [
        CommonModule,
        IonicStorageModule.forRoot(),
        ApolloModule
    ],
    exports: []
})
export class StoreModule {
    routerSubscription: Subscription;
    forbiddenErrorCount: 0;

    retryInterval;
    constructor(
        apollo: Apollo,
        storage: Storage,
        httpLink: HttpLink,
        router: Router,
        sconnectAuthService: SconnectAuthService,
        toastService: ToastService
    ) {
        let webSocketClient: Client;
        void storage.create();

        const getExtendedHeaders = () => {
            return environment.featureFlags.additionalHeaders ? {
                'x-app-version': packageInfo.version,
                'x-app-build': packageInfo.buildNumber,
                'x-app-url': window.location.href
            } : {};
        };
        const getAccessToken = ():Promise<Record<string, unknown>> => new Promise((resolve, reject) =>{
            const localStorageToken = localStorage.getItem(AuthStorageKeyEnum.accessToken);
            const localActivePharmacy = localStorage.getItem(AuthStorageKeyEnum.activePharmacy);
            if (!localStorageToken) {
                if (webSocketClient) {
                    webSocketClient.terminate();
                }
                reject('NO ACCESS TOKEN!');
            } else {
                resolve( {
                    accept: 'application/json',
                    authorization: 'Bearer ' + localStorageToken,
                    'Active-Pharmacy': localActivePharmacy || '',
                    ...getExtendedHeaders()
                });
            }
        });

        const checkLoginStatus = async (forceLogout = false) => {
            const valid = await sconnectAuthService.checkAccessToken();
            const activePharmacy = localStorage.getItem(AuthStorageKeyEnum.activePharmacy);
            if ( (!valid && forceLogout) || (!activePharmacy && forceLogout)) {
                const accessToken = localStorage.getItem(AuthStorageKeyEnum.accessToken);
                const refreshToken = localStorage.getItem(AuthStorageKeyEnum.refreshToken);

                // User session has expired - redirecting to login ...
                await sconnectAuthService.logout(false);
                if (accessToken && refreshToken) {
                    await toastService.presentNotice('Ihre Sitzung ist abgelaufen. Sie werden ausgeloggt.');
                }
                return false;
            }
            return valid;
        };

        const uri = environment.apiUri;
        const uriWS = environment.apiWSUri;

        // Add Basic charset and authentication header
        const basic = setContext(() => ({headers: {Accept: 'charset=utf-8'}}));
        const auth = setContext(async (request, prevContext) => {
            const headers = await getHeaders(!request.variables.requiresNoToken && request.operationName !== 'IntrospectionQuery');
            if (headers) {
                return {
                    ...request,
                    headers: {
                        ...prevContext.headers,
                        ...headers
                    }
                };
            } else if (!request || !request.variables || !request.variables.requiresNoToken) {
                throw new Error(ERROR_ABORTED);  // Make sure the request is not sent out
            }
            return {
                ...request,
                headers: {
                    accept: 'application/json',
                    ...getExtendedHeaders()
                }
            };
        });

        // Initialize persistent Cache
        void persistCache({
            cache,
            storage: {
                getItem: key => storage.get(key),
                setItem: (key, data) => storage.set(key, data),
                removeItem: key => storage.remove(key)
            }
        });

        /**
         * Return the API auth token which was saved in the local storage before
         */
        const getHeaders = async (forceLogout) => {
            const loggedIn = await checkLoginStatus(forceLogout);
            if (loggedIn) {
                const token = localStorage.getItem(AuthStorageKeyEnum.accessToken);
                const pharmacy = localStorage.getItem(AuthStorageKeyEnum.activePharmacy);
                if (token) {
                    return {
                        accept: 'application/json',
                        'Active-Pharmacy': pharmacy || '',
                        authorization: 'Bearer ' + token,
                        ...getExtendedHeaders()
                    };
                }
            }
            return null;
        };

        // Handle Network and GraphQL Errors
        const errors = onError(({ graphQLErrors, networkError, operation }) => {
            let isMaxExecutionError = false;
            let hideErrorMessage = false;
            if (graphQLErrors) {
                graphQLErrors.map(({message, locations, path, extensions}) => {

                    // Show errors on local and dev environment
                    if(new RegExp(['local', 'development'].join('|')).test(environment.name)) {
                        console.error(message, operation);
                    }

                    // User has been logged out
                    if (message && message.startsWith('Cannot return null for non-nullable field subscription_root')) {
                        hideErrorMessage = true;
                    } else if ((extensions && extensions.code === 'access-denied') ||
                        message === 'Forbidden' ||
                        (message && message.includes('" not found in type: \'query_root\''))
                    ) {
                        // Logout after continues forbidden messages
                        if(++this.forbiddenErrorCount > 5) {
                            void checkLoginStatus(true);
                            this.forbiddenErrorCount = 0;
                        }
                        const accessToken = localStorage.getItem(AuthStorageKeyEnum.accessToken);
                        const refreshToken = localStorage.getItem(AuthStorageKeyEnum.refreshToken);
                        const expiresAt = localStorage.getItem(AuthStorageKeyEnum.expiresAt);
                        const activePharmacy = localStorage.getItem(AuthStorageKeyEnum.activePharmacy);
                        {
                            const scope = Sentry.getCurrentScope();
                            scope.setExtra('tokenInformation', {
                                accessToken: !!accessToken,
                                refreshToken: !!refreshToken,
                                expiresAt,
                                activePharmacy,
                                decoded: jwtDecode(accessToken)
                            });
                            scope.setExtra('appVersion', packageInfo.version);
                            scope.setExtra('appBuild', packageInfo.buildNumber);
                        };
                        hideErrorMessage = true;
                        throw Error(message);
                    } else if (
                        message === 'invalid_token' ||
                        message === 'Access Denied!' ||
                        message === 'invalid_request'
                    ) {
                        hideErrorMessage = true;
                        void checkLoginStatus(true);
                    } else {
                        if (
                            message &&
                            message.toString().includes(MaxExecutionErrorMessage.startsWith) &&
                            message.toString().includes(MaxExecutionErrorMessage.endsWith)
                        ) {
                            isMaxExecutionError = true;
                        } else {
                            if (!window['TEST_MODE']) {
                                checkLoginStatus().then(access => {
                                    if (access) {
                                        throw new Error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
                                    }
                                });
                            }
                        }

                    }
                });

                if (isMaxExecutionError) {
                    // do not move toast to api service cause this will be displayed with queries as well as mutations
                    void toastService.presentError(
                        'Sie haben die zulässige Anzahl von Anfragen überschritten. Bitte versuchen Sie es in 30 Sekunden erneut.'
                    );
                } else if (!hideErrorMessage) {

                    checkLoginStatus().then(access => {
                        if (access) {
                            /* TODO
                              sentryStateVar.get()
                                  .subscribe((sentryState: SentryStateInterface) => {
                                      toastService.presentError(
                                          'Ein unbekannter Fehler ist aufgetreten, ' +
                                          'bitte versuchen Sie den Vorgang erneut oder wenden Sie sich an unser Support-Team. ' +
                                          'Fehlernummer "{0}"', 8000, [sentryState.eventId]
                                      );
                                      sentryStateVar.set('');
                                  }).unsubscribe();
                              */
                          }
                      });
                  }
            }
            if (networkError) {
                const statusKey = (networkError['status'] !== undefined ? 'status' : 'statusCode');
                const messageKey = (networkError['response'] ? 'response' : (networkError['message'] ? 'message' : 'bodyText'));
                let messages = 'unknown error';

                // Ignore 'Aborted' error
                if(networkError[messageKey] === ERROR_ABORTED) {
                    return;
                }

                if (networkError['error']) {
                    messages = !networkError['error']['errors'] ? '' : (
                        (networkError['error']['errors'].length > 1) ?
                            networkError['error']['errors'].map((e) => e.message).join(' | ') :
                            networkError['error']['errors'][0]['message']
                    );

                    // Show errors on local and dev environment
                    if(new RegExp(['local', 'development'].join('|')).test(environment.name)) {
                        console.error(messages);
                    }
                }

                switch (networkError[statusKey]) {
                    case 0:
                        if (isDisconnected && hasWSError) {
                            // error if apollo is not available
                        /* TODO
                        toastService.presentError(
                                `Der Server ist aktuell nicht erreichbar, Anfragen können damit nicht übermittelt werden.`
                            );*/
                        } else {
                            // error if no internet connection
                           /* TODO
                           toastService.presentError(
                                `Die Verbindung zum Server ist fehlgeschlagen. Bitte prüfen Sie Ihre Internetverbindung.`
                            );*/
                        }
                        break;
                    default:
                        if(!window['TEST_MODE']) {
                            console.error(`[Network error]: ${networkError[statusKey]} ${networkError[messageKey]} [Messages]: ${messages}`);
                            // network error is not thrown in module with the following code: Todo check if everything is working ok without it
                            // throw new Error(`[Network error]: ${networkError[statusKey]} ${networkError[messageKey]} [Messages]: ${messages}`);
                        }

                         checkLoginStatus().then(access => {
                            if (access) {
                                /* TODO        sentryStateVar.get()
                                            .subscribe((sentryState: SentryStateInterface) => {
                                                toastService.presentError(
                                                    'Es ist ein unbekannter Fehler aufgetreten.' +
                                                    'Bitte prüfen Sie Ihre Internetverbindung. ({0}) ' +
                                                    'Fehlernummer "{1}"',
                                                    8000,
                                                    [
                                                        networkError[statusKey],
                                                        sentryState.eventId
                                                    ]
                                                );

                                                sentryStateVar.set('');
                                            }).unsubscribe();
                                 */
                                    }
                                });
                        break;
                }
            }
        });

        const http = httpLink.create({uri});
        const getWebsocket = () => {
            webSocketClient = createClient({
                url: uriWS,
                connectionParams: () => {
                    return getAccessToken();
                },
                lazy: false // Leave this at false to prevent iterator errors!
            });

            webSocketClient.on('connected', _ => {
                isDisconnected = false;
                hasWSError = false;
                connectionEventEmitter.emit({isDisconnected, hasWSError});
            });
            webSocketClient.on('closed', _ => {
                isDisconnected = true;
                hasWSError = false;
                connectionEventEmitter.emit({isDisconnected, hasWSError});
            });
            webSocketClient.on('error', _ => {
                hasWSError = true;
                connectionEventEmitter.emit({isDisconnected, hasWSError});
            });

            unsubscribe(this.routerSubscription);
            this.routerSubscription = router.events
                .pipe(filter( event => event instanceof NavigationEnd))
                .subscribe((event: Event|RouterEvent) => {
                    if (event instanceof RouterEvent && event.url && event.url.includes(AppRoutesEnum.login) && webSocketClient !== null) {
                        // Close the websocket connection on logout to clear server resources
                        webSocketClient.terminate();
                        // webSocketClient.dispose
                        void apollo.client.cache.reset();
                    }
                });

            return new GraphQLWsLink(webSocketClient);
        };

        const getIsLogin = () => window.location.pathname === '/login';
        const getHasToken = () => !!localStorage.getItem(AuthStorageKeyEnum.accessToken);
        const getHasActivePharmacy = () => !!localStorage.getItem(AuthStorageKeyEnum.activePharmacy);
        let isLogin = getIsLogin();
        let hasToken = getHasToken();
        let hasActivePharmacy = getHasActivePharmacy();

        let interval: any;
        connectionEventEmitter.subscribe(({isDisconnected, hasWSError}) => {
            // if the websocket cannot connect for one minute, refresh the page
            // the websocket will try to reconnect only for one minute
            if (!interval && (isDisconnected || hasWSError)) {
                clearInterval(interval);
                interval = setInterval(() => {
                    if (isDisconnected || hasWSError) {
                        window.location.reload();
                    }
                }, 60000);
            } else if (!isDisconnected && !hasWSError) {
                clearInterval(interval);
            }
        });
        const createApollo = () => {
            const link = isLogin || !hasToken || !hasActivePharmacy ? http : split(({query}) => {
                const {kind, operation}: any = getMainDefinition(query);
                return kind === 'OperationDefinition' && operation === 'subscription';
            }, getWebsocket(), http);

            // For more information see - https://www.apollographql.com/docs/react/api/link/apollo-link-retry/#custom-strategies
            const retryLink = new RetryLink({
                delay: (count/*, operation, error*/) => 500 + (count * 250),
                attempts: {
                    max: 240, // a re-deploy of apollo needs about 30 seconds - 240 retries result in a retry rate of 60.5 seconds
                    retryIf: (error/*, operation*/) => !!error
                }
            });
            apollo.create({
                link: ApolloLink.from([sentry, errors, basic, auth, retryLink, link]),
                cache,
                // Network_only fix if user is offline for a short time.
                // See https://github.com/apollographql/apollo-client/issues/1413
                queryDeduplication: false,
                defaultOptions: {
                    watchQuery: {
                        fetchPolicy: 'cache-and-network',
                        errorPolicy: 'all'
                    }
                }
            });
        };
        createApollo();

        if (!isLogin && (!hasToken || !hasActivePharmacy)) {
            clearInterval(this.retryInterval);
            this.retryInterval = setInterval(() => {
                isLogin = getIsLogin();
                hasToken = getHasToken();
                hasActivePharmacy = getHasActivePharmacy();
                if (!isLogin && hasToken && hasActivePharmacy) {
                    apollo.removeClient();
                    createApollo();
                    clearInterval(this.retryInterval);
                } else if (isLogin) {
                    clearInterval(this.retryInterval);
                }
            }, 200);
        }
    }
}
