export enum TableFilterTypeEnum {
    date = 'date',
    select = 'select',
    trueFalse = 'trueFalse',
    multiSelect = 'multiselect',
    inputSelect = 'inputSelect',
    inputText = 'inputText',
    inputPopover = 'inputPopover',
    search = 'search', // Suche
    searchField = 'searchField', // Freitext
    print = 'print',
    download = 'download',
    info = 'info',
}

export enum TableSortDirectionEnum {
    asc = 'asc',
    desc = 'desc',
}

export enum TableFieldsEnum {
    apiChangedAt = 'api_changed_at',
    productName = 'productName',
    recDate = 'recDate',
    recTime = 'recTime',
    type = 'type',
    price = 'price',
    quantity = 'quantity',
    status = 'status',
    notes = 'notes',
    documentNumber = 'documentNumber',
    deliveryDate = 'estimatedDeliveryDate',
    expectedDelivery = 'expectedDelivery',
    orderDate = 'orderDate',
    orderId = 'orderId',
    label = 'label',
    createdAt = 'createdAt',
    file = 'file',
    downloadTypes = 'downloadTypes',
}
