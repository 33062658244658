import { gql } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import { QueryFetchPolicy } from '../../../enums/api.enum';
import { ExportTableTypeEnum } from '../../../enums/export-table-type.enum';
import { ExcelExportTableInterface } from '../../../interfaces/excel.interface';
import { ReturnsFiltersInterface } from '../../../interfaces/returns.interface';
import { OrderFiltersInterface } from '../../../interfaces/order.interface';


export const ExcelExportReturns = gql`
    query ExcelExportReturns($filters: ReturnsFilter!) {
        ExcelExportReturns(filters: $filters) {
            status
            message
            errorCode
            data
            filename
        }
    }
`;

export const ExcelExportOrders = gql`
    query ExcelExportOrders($filters: OrdersFilter!) {
        ExcelExportOrders(filters: $filters) {
            status
            message
            errorCode
            data
            filename
        }
    }
`;

export const AllExcelQueries = [
    ExcelExportReturns,
    ExcelExportOrders
];

@Injectable()
export class ExcelQueries {
    constructor(
        private apollo: Apollo
    ) {}

    public exportExcelReturns(filters: ReturnsFiltersInterface): Observable<ExcelExportTableInterface> {
        const filtersFormatted: ReturnsFiltersInterface = {};
        for (const filter in filters) {
            if (filters[filter]?.length && filters[filter] !== 'Alle' && filters[filter] !== 'all') {
                filtersFormatted[filter] = filters[filter];
            }
        }
        return this.apollo.watchQuery({
            query: ExcelExportReturns,
            variables: {filters: filtersFormatted},
            fetchPolicy: QueryFetchPolicy.NETWORK_ONLY
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['ExcelExportReturns'])) as Observable<ExcelExportTableInterface>;
    }

    public exportExcelOrders(filters: OrderFiltersInterface): Observable<ExcelExportTableInterface> {
        const filtersFormatted: OrderFiltersInterface = {};
        for (const filter in filters) {
            if (filters[filter]?.length && filters[filter] !== 'Alle' && filters[filter] !== 'all') {
                filtersFormatted[filter] = filters[filter];
            }
        }
        return this.apollo.watchQuery({
            query: ExcelExportOrders,
            variables: {filters: filtersFormatted},
            fetchPolicy: QueryFetchPolicy.NETWORK_ONLY
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['ExcelExportOrders'])) as Observable<ExcelExportTableInterface>;
    }
}
