<app-modal  i18n-title [title]="!isLoading ? form?.title : ''">
    <ng-container content *ngIf="!isLoading">
        <form [formGroup]="formModalGroup" (ngSubmit)="onFormSubmit(formModalGroup.value)" class="form"
              *ngIf="form && form.formFields && form.formFields.length > 0"
        >
                <ng-container
                        class="form-field-container"
                        *ngFor="let field of form.formFields"
                >
                    <ion-item class="background-input">
                        <ion-label slot="start" i18n >
                            {{ field.label }}
                            <span *ngIf="field.isRequired" class="required">*</span>
                        </ion-label>
                        <div class="ion-input-container" [class.invalid]="formModalGroup.get(field.id)?.dirty | formInputIsInvalid : formModalGroup.get(field.id)?.valid">
                            <ion-input type="text" fill="outline"
                                       [formControlName]="field.id"
                                       [required]="field.isRequired"
                                       aria-label="Form field"/>
                            <ion-icon name="alert-circle"
                                      *ngIf="formModalGroup.get(field.id)?.dirty | formInputIsInvalid : formModalGroup.get(field.id)?.valid"
                                      appTooltip
                                      [tt]="formModalGroup.get(field.id) | formInputErrorMessage : formModalGroup.get(field.id)?.valid"
                                      ttp="right">
                            </ion-icon>
                        </div>
                    </ion-item>
                </ng-container>
                <div class="required-bottom-text required-fields"><span class="required">*</span> Benötigte Felder</div>
        </form>

    </ng-container>
    <!-- Footer -->
    <ng-container footer *ngIf="!isLoading">
        <div
            appTooltip
            class="clickable"
            [ttk]="!formModalGroup.valid ? 'required_field' : null"
        >
            <ion-button
                    type="submit"
                    [class.disabled]="!formModalGroup.valid"
                    i18n
                    [disabled]="!formModalGroup.valid"
                    (click)="formModalGroup.valid ? onFormSubmit(formModalGroup.value) : null"
            >{{ buttonText }}</ion-button>
        </div>
    </ng-container>



    <!-- Loading -->
    <ng-container titleExtension *ngIf="isLoading">
        <div class="ion-padding-vertical form-modal-skeleton-title">
            <ion-skeleton-text animated></ion-skeleton-text>
        </div>
    </ng-container>
    <ng-container content *ngIf="isLoading">
        <div *ngFor="let item of [1,2,3,4]">
            <div class="display-flex">
                <div class="form-modal-skeleton-left">
                    <div class="ion-padding-vertical">
                        <ion-skeleton-text animated></ion-skeleton-text>
                    </div>
                </div>
                <div class="form-modal-skeleton-right">
                    <span i18n><ion-skeleton-text animated class="ion-padding-vertical"></ion-skeleton-text></span>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container footer *ngIf="isLoading">
        <div class="ion-padding-vertical form-modal-skeleton-footer-button">
            <ion-skeleton-text animated></ion-skeleton-text>
        </div>
    </ng-container>

</app-modal>