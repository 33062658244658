import { Pipe, PipeTransform } from '@angular/core';
import { PharmaciesEditModalInterface, PharmacyRights, UserAdministrationInterface } from '../interfaces/user-administration.interface';
import { PharmacyStoreNamesInterface } from '../interfaces/customer.interface';
import { UserAccessModulesConfig } from '../config/user-administration.config';
import { UserAccessModulesEnum, UserAccessRightsEnum } from '../enums/user-administration.enum';
import { TOOLTIPS } from '../config/tooltip-texts.config';

@Pipe({
    name: 'userAdminPharmacyOwnerAndCount'
})
export class UserAdminPharmacyOwnerAndCountPipe implements PipeTransform {
    transform(ownerEmail: string, user: UserAdministrationInterface): number {
        if (user && ownerEmail === user.email) {
            return -1;
        }
        const pharmacies = user.pharmacies.filter(pharmacy => {
            const modules = UserAccessModulesConfig.filter(module => (
                pharmacy[module] && pharmacy[module].length > 0
            ));
            return modules && modules.length > 0;
        });

        return pharmacies ? pharmacies.length : 0;
    }
}

@Pipe({
    name: 'userAdminGetPharmacyStoreName'
})
export class UserAdminGetPharmacyStoreNamePipe implements PipeTransform {
    transform(apiUser: string, pharmacyStoreNames: PharmacyStoreNamesInterface[]): string {
        return pharmacyStoreNames.find(item => item.apiUser === apiUser).name;
    }
}


@Pipe({
    name: 'userAdminIsCurrentUser'
})
export class UserAdminIsCurrentUserPipe implements PipeTransform {
    transform(email: string, currentUserMail: string): boolean {
        return currentUserMail && email && currentUserMail.toLowerCase() === email.toLowerCase();
    }
}

@Pipe({
    name: 'userAdminCanActivate'
})
export class UserAdminCanActivatePipe implements PipeTransform {
    transform(currentModule: PharmacyRights, pharmacies: PharmaciesEditModalInterface[], pipeRefresh: number): boolean {
        if (currentModule.isDisabled) {
            return false;
        }
        if (currentModule.accessModule === UserAccessModulesEnum.SACADEMY) {
            const activatedPharmacy = pharmacies.find(pharmacy => pharmacy.rights.find(right => (
                right.accessModule === currentModule.accessModule && right.isActivated
            )));
            if (activatedPharmacy) {
                // only true, if activatedPharmacy.rights equals currentModule
                return currentModule.isActivated;
            }
        }
        return true;
    }
}

// Function to convert kebab-case to camelCase
function kebabToCamelCase(str: string) : string {
    return str.replace(/-./g, match => match.charAt(1).toUpperCase());
}

@Pipe({
    name: 'userAccessRightTooltip'
})
export class UserAccessRightTooltipPipe implements PipeTransform {
    transform(accessRight: UserAccessRightsEnum, isChecked: boolean): string {
        const formattedAccessRight = kebabToCamelCase(accessRight);
        let tooltipKeyPrefix = 'ua_access_rights_unchecked_';
        if (isChecked) {
            tooltipKeyPrefix = 'ua_access_rights_checked_';
        }
        const tooltipKey = tooltipKeyPrefix + formattedAccessRight;
        return TOOLTIPS[tooltipKey] ? tooltipKey : 'ua_access_rights_unchecked';
    }
}
