export enum NavigationEnum {
    calendar,
    communications,
    dashboard,
    information,
    knowledge,
    logistics,
    mea,
    meaChat, // TODO remove with feature flag
    news,
    offers,
    releaseHistory,
    releaseNotes,
    representatives,
    sanavendi,
    seminars,
    settings,
    statistics,
}
