<app-modal  i18n-title title="{{title ? title + ' - ' : ''}}Datumsauswahl:" >
    <ng-container headerActions></ng-container>
    <ng-container content>

        <div class="individual-calendar-selection" *ngIf="displayIndividualCalendar">
            <p-calendar [(ngModel)]="dateRange"
                        [inline]="true"
                        [showWeek]="true"
                        [minDate]="minDate"
                        [maxDate]="maxDate"
                        firstDayOfWeek="1"
                        selectionMode="range"
                        dateFormat="dd.mm.yy"
                        (ngModelChange)="onCalendarRangeChange()"
                        #inlineDateRangePicker>
                <ng-template pTemplate="header">
                    <ion-select *ngIf="dateRangeOptions && dateRangeOptions.length > 1"
                                (ionChange)="onDateRangeChange($event.detail.value)"
                                aria-label="Zeitraum wählen"
                                value="{{selectedDateRangeId}}"
                                [selectedText]="selectedDateRangeId === individualOption ? selectText : null"
                                justify="center"
                                labelPlacement="floating"
                                i18n-okText okText="Ok"
                                i18n-cancelText cancelText="Abbruch"
                                class="select-filter">
                        <ion-select-option *ngFor="let dateRange of dateRangeOptions; trackBy: trackBy"
                                           value="{{dateRange.id}}">{{dateRange.title}}</ion-select-option>
                    </ion-select>
                </ng-template>
                <ng-template pTemplate="date" let-date>
                    <span [class.first-selected-date]="dateRange && dateRange[0] && (date | datePickerToDate: dateRange[0])"
                          [class.last-selected-date]="dateRange && dateRange[1] && (date | datePickerToDate: dateRange[1])">{{ date.day }}</span>
                </ng-template>
            </p-calendar>
        </div>

        <form [formGroup]="dateFormGroup" class="form form-display-date" [class.all-selection]="selectedDateRangeId == allOption">
            <ion-item>
                <ion-input
                    i18n-label
                    label="Von:"
                    type="date"
                    class="small-label is-date"
                    formControlName="fromDate"
                    (change)="onInputFieldChange(true)"
                ></ion-input>
            </ion-item>

            <ion-item>
                <ion-input
                    i18n-label
                    label="Bis:"
                    type="date"
                    class="small-label is-date"
                    formControlName="toDate"
                    (change)="onInputFieldChange(true, true)"
                ></ion-input>
            </ion-item>
        </form>

        <div *ngIf="displayTime">
            <form [formGroup]="timeFormGroup" (ngSubmit)="close()" class="form form-display-time">
                <ion-item class="ion-no-padding">
                    <ion-input
                        i18n-label
                        label="Von:"
                        type="time"
                        formControlName="fromTime"
                        (click)="selectContent($event)"
                        (change)="checkDates()"
                        class="small-label is-date"
                    ></ion-input>
                </ion-item>
                <ion-item>
                    <ion-input
                        i18n-label
                        label="Bis:"
                        type="time"
                        formControlName="toTime"
                        (click)="selectContent($event)"
                        (change)="checkDates()"
                        class="small-label is-date"
                    ></ion-input>
                </ion-item>
            </form>
        </div>
    </ng-container>
    <!-- Footer -->
    <ng-container footer>
        <ion-button
                [class.disabled]="displayTime && !timeFormGroup.valid"
                (click)="!displayTime || timeFormGroup.valid ? close() : null"
                appTooltip
                ttk="{{displayTime && !timeFormGroup.valid ? 'invalid_date_range' : null}}"
                ttp="left"
                i18n
        >OK</ion-button>
    </ng-container>
</app-modal>
