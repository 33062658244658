import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { AccessRightEnum } from '../core/enums/access-right.enum';
import { AuthStorageKeyEnum } from '../core/enums/authStorageKey.enum';
import { UserAccessRightsEnum } from '../core/enums/user-administration.enum';
import { PharmacyStoreStateVar } from '../core/store/locals/pharmacyStoreState.var';
import { getChatCustomerId } from '../core/services/mea-chat.service';
import { AppRoutesEnum } from '../core/enums/routes.enum';


/**
 * This guard checks the access rights of the current user and redirects them in case the required right isn't set.
 *
 * Requirements:
 *      Every subnavigation requires it's own no-access page route. This is necessary so the user won't be
 *      redirected to a other submodule or the root module.
 *
 *      For example see navigation.routes.ts
 *
 *      ```
 *          path: 'no-access',
 *          loadChildren: () => import('../pages/common/no-access/no-access.module').then(m => m.NoAccessPageModule)
 *      ```
 *
 *
 * Usage:
 *      - Check access-right.enum.ts and add an additional value
 *      - Add AccessGuard and access right to the route which should be protected.
 *
 *          in *.routes.ts add
 *          ```
 *              canActivate: [AccessGuard],
 *              data: {accessRight: AccessRightEnum.invoices}
 *          ```
 */
@Injectable({
    providedIn: 'root'
})
export class AccessGuard  {
    private pharmacyStoreStateVar = inject(PharmacyStoreStateVar);
    routeEnabled: {path: string, enabled: boolean};

    constructor(private router: Router) { }

    /**
     * Activates the route only if the user has access rights to this module
     */
    canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot): boolean {
        if(!this.isRouteEnabled(route)) {
            const redirectUrl = state.url
                .split('/')
                .slice(0,-1)
                .join('/') + '/' + AppRoutesEnum.noAccess + '/' + state.url.split('/').pop();
            void this.router.navigateByUrl(redirectUrl);
        }

        return true;
    }

    /**
     * gets angular route by url
     *
     * @param urlPath : string
     * @param routerConfig : array
     */
    getRouteByUrl(urlPath, routerConfig = this.router.config) {
        if (!urlPath || !routerConfig) {
            return;
        }
        const urlParts = urlPath.split('/');
        if (urlParts[0] === '') {
            urlParts.shift();
        }
        if (routerConfig !== this.router.config) {
            routerConfig = routerConfig[0]?.children;
        }
        let returnValue = null;
        routerConfig.forEach(route => {
            if (route.path === urlParts[0]) {
                urlParts.shift();
                returnValue = urlParts[0] ? this.getRouteByUrl(urlParts.join('/'), route['_loadedConfig']?.routes) : route;
            }
        });
        return returnValue;
    }

    /**
     * gets if route is enabled by url path
     *
     * @param urlPath : string
     */
    isRouteEnabledByUrl(urlPath): boolean {
        if (this.routeEnabled && this.routeEnabled.path === urlPath) {
            return this.routeEnabled.enabled;
        }
        const route = this.getRouteByUrl(urlPath);
        if (route) {
            this.routeEnabled = {path: urlPath, enabled: this.isRouteEnabled(route)};
        }
        return route ? this.routeEnabled.enabled : null;
    }

    /**
     * checks if route is enabled by angular route
     *
     * @param route : object
     */
    isRouteEnabled(route): boolean {
        const requiredAccessRight = route.data && route.data.accessRight >= 0 ? route.data.accessRight : null;
        const userAccessRights: Array<AccessRightEnum> = this.getUserAccessRights();
        return !(requiredAccessRight === null || userAccessRights.indexOf(requiredAccessRight) < 0);
    }

    /**
     * get user access rights
     *
     * @private
     */
    private getUserAccessRights() {
        const token = localStorage.getItem(AuthStorageKeyEnum.accessToken);
        const activePharmacy = localStorage.getItem(AuthStorageKeyEnum.activePharmacy);
        let userAccessRights:Array<AccessRightEnum> = [];
        if(!token) {
            return userAccessRights;
        }
        const decodedToken: {
            sub: string,
            email: string,
            resource_access: Array<string>
        } = jwtDecode(token);

        if(decodedToken
            && decodedToken.resource_access
            && decodedToken.resource_access['sanacorp-connect']
            && Array.isArray(decodedToken.resource_access['sanacorp-connect']?.roles)) {
            if (decodedToken['sc']?.isVertreter) {
                userAccessRights.push(AccessRightEnum.representative);
            }
            const chatRoles = decodedToken.resource_access['meadirekt-chat']?.roles || [];
            if (!this.pharmacyStoreStateVar.hasChat() || chatRoles.find(role =>
                role === getChatCustomerId(activePharmacy) + '_chat_' + UserAccessRightsEnum.PHARMACY_OWNER ||
                role === getChatCustomerId(activePharmacy) + '_chat_' +UserAccessRightsEnum.PHARMACY_MEMBER)
            ) {
                userAccessRights.push(AccessRightEnum.meaChat);
            }

            const allGroupsParsed = decodedToken.resource_access['sanacorp-connect'].roles
                .map((g) => g.match(new RegExp('(.*)_(.*)_(.*)')));

            for (const group of allGroupsParsed) {
                if (Array.isArray(group) && group.length === 4) {
                    if (group[2] === 's-connect' && group[1] === activePharmacy) {
                        switch (group[3]) {
                            case UserAccessRightsEnum.PHARMACY_OWNER:
                                userAccessRights = [
                                    ...userAccessRights,
                                    ...[
                                        AccessRightEnum.invoices,
                                        AccessRightEnum.statistics,
                                        AccessRightEnum.archive,
                                        AccessRightEnum.userAdministration,
                                        AccessRightEnum.sanavendi
                                    ]
                                ];
                                break;
                            case UserAccessRightsEnum.STATISTICS:
                                userAccessRights.push(AccessRightEnum.statistics);
                                break;
                            case UserAccessRightsEnum.INVOICES:
                                userAccessRights.push(AccessRightEnum.invoices);
                                break;
                            case UserAccessRightsEnum.ARCHIVE:
                                userAccessRights.push(AccessRightEnum.archive);
                                break;
                            case UserAccessRightsEnum.SANAVENDI:
                                userAccessRights.push(AccessRightEnum.sanavendi);
                                break;
                        }
                    }
                }
            }
        }
        return userAccessRights;
    }
}
