export enum PdfViewerType {
    delivery,
    batch,
    invoice,
    meaDownload,
    statistics,
    terms,
    safetyDataSheet,
    file,
}
