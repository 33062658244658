import { Injectable, inject } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { PharmacyStateInterface } from '../../interfaces/pharmacy-state.interface';
import { AppStateVar } from './appState.var';
import { AuthStorageKeyEnum } from '../../enums/authStorageKey.enum';
import { ConnectionStatusService } from '../../services/connectionStatus.service';
import { OffersMutations } from '../graphql/mutations/offers.graphql';
import { unsubscribe } from '../../core.utils';
import { Router } from '@angular/router';
import { AppRoutesEnum } from '../../enums/routes.enum';

export const GetPharmacyState = gql`
    query getPharmacyState {
        pharmacyState @client {
            activePharmacyId
            showChatUserChangedMessage
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class PharmacyStateVar {
    private appStateVar = inject(AppStateVar);
    private router = inject(Router);

    constructor(
        private connectionStatusService: ConnectionStatusService,
        apollo: Apollo,
        private offersMutations: OffersMutations
    ) {
        this.apollo = apollo;
    }
    private defaultValue = {
        activePharmacyId: localStorage.getItem(AuthStorageKeyEnum.activePharmacy),
        showChatUserChangedMessage: true
    };

    private pharmacyStateObservable: Observable<PharmacyStateInterface>;
    private apollo: Apollo;
    private cache;

    /**
     * Show message to user, when entering mea chat after user changed stores
     * @param setActive - true to show message, false to hide
     */
    static setShowMeaChatMessage(setActive: boolean) {
        const setMessage = setActive ? '1' : '0';
        localStorage.setItem('showChatUserChangedMessage', setMessage);
    }

    init() {
        if(this.apollo.client) {
            this.cache = this.apollo.client.cache;
            this.pharmacyStateObservable = new Observable<PharmacyStateInterface>(subscriber => {
                this.cache.watch({
                    query: GetPharmacyState,
                    callback: res => {
                        subscriber.next(res.result && res.result.pharmacyState || this.defaultValue);
                    },
                    immediate: true,
                    optimistic: true,
                });
            });
        }
    }

    get(): Observable<PharmacyStateInterface> {
        this.init();
        return this.pharmacyStateObservable;
    }

    set(pharmacyState: PharmacyStateInterface) {
        this.init();
        if (this.cache) {
            this.cache.writeQuery({
                query: GetPharmacyState,
                data: {
                    pharmacyState
                }
            });
        }
    }

    getActivePharmacy(): Observable<PharmacyStateInterface> {
        return new Observable<PharmacyStateInterface>(subscriber => {
            subscriber.next( {
                activePharmacyId: localStorage.getItem(AuthStorageKeyEnum.activePharmacy),
                showChatUserChangedMessage: localStorage.getItem('showChatUserChangedMessage') === '1'
            });
        });
    }

    setActivePharmacy(activePharmacyId, reload = false) {
        localStorage.setItem(AuthStorageKeyEnum.activePharmacy, activePharmacyId);
        try {
            this.set({activePharmacyId, showChatUserChangedMessage: true});
        } catch (e) {
            // an error "already computing" is thrown, if it is called in the waiting page (when the first pharmacy is not yet initialized in hasura)
        }
        if (reload) {
            // GetPharmacyState resets showChatUserChangedMessage always to false -> localStorage is quick fix
            PharmacyStateVar.setShowMeaChatMessage(true);
            this.apollo.client.cache.reset().then(() => {
                // Quick fix to prevent full page loader disappear after cache reset.
                this.appStateVar.setFullPageLoader(true, 'Lade Apothekendaten ... ');
                const connectionSubscriber = this.connectionStatusService.monitor(false).subscribe(async currentState => {
                    if(currentState.hasInternetAccess && currentState.hasNetworkConnection) {
                        this.offersMutations.deleteAllOfferQuotes();
                        unsubscribe(connectionSubscriber);
                        const currentUrl = window.location.pathname;
                        const newUrl = currentUrl.replace('/' + AppRoutesEnum.noAccess, '');
                        if (newUrl !== currentUrl) {
                            await this.router.navigateByUrl(newUrl);
                        }
                        window.location.reload();
                    }
                });
            });
        }
    }
}
